const initState = {
  redirectUrl: null,
};

const RedirectReducer = function (state = initState, action) {
  switch (action.type) {
    case 'DO_REDIRECT': {
      return {
        ...state,
        redirectUrl: action.redirectUrl,
      };
    }

    case 'REDIRECT_DONE': {
      return {
        ...state,
        redirectUrl: undefined,
      };
    }

    default:
      return state;
  }
};

export default RedirectReducer;
