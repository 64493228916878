import { isEmpty } from 'lodash';

export const CHAT_ADMIN_CLIENT_OWNER_NOT_SELECTED = 0;
export const CHAT_GROUP_ID_NOT_SELECTED = '';
export const CHAT_SYNTHETIC_OVERSEEN_GROUP_ID = 'synthetic overseen';

const initState = {
  loading: false,
  chatWindowOpen: false,
  createdUserChat: false,
  chatRooms: [],
  chatGroups: [],
  activeRoom: undefined,
  updateCount: 0,
  scrollDownChat: 0,
  newMessagesCount: 0,
  newMessageId: null,
  lastMessageReceivedFrom: null,
  adminClientOwnersUsers: [],
  selectedAdminClientOwnerId: CHAT_ADMIN_CLIENT_OWNER_NOT_SELECTED,
  roomIdToGroupMap: {},
  roomIdToGroupMapOverseen: {},
};

const ChatReducer = function (state = initState, action) {
  switch (action.type) {
    case 'TOGGLE_CHAT_WINDOW': {
      return {
        ...state,
        chatWindowOpen: !state.chatWindowOpen,
      };
    }

    case 'CREATED_USER_CHAT': {
      return {
        ...state,
        createdUserChat: !state.createdUserChat,
      };
    }

    case 'SET_ACTIVE_CHAT_ROOM': {
      return {
        ...state,
        activeRoom: action.chatRoomId,
      };
    }

    case 'CHAT_GET_ADMIN_CLIENT_OWNERS_USERS_FULFILLED': {
      return {
        ...state,
        adminClientOwnersUsers: action.payload,
      };
    }

    case 'CHAT_SET_ADMIN_CLIENT_OWNER_ID': {
      return {
        ...state,
        selectedAdminClientOwnerId: action.userId,
      };
    }

    case 'UPDATE_CHAT_NOTIFICATION_SETTING': {
      const { updateCount } = state;
      return {
        ...state,
        updateCount: updateCount + 1,
      };
    }

    case 'GET_CHAT_ROOMS_PENDING': {
      return { ...state, loading: true };
    }

    case 'GET_CHAT_ROOMS_REJECTED': {
      return { ...state, loading: false };
    }

    case 'GET_CHAT_ROOMS_FULFILLED': {
      const { chatGroups: responseChatGroups, loggedUser } = action.payload;
      let tempChatRoomGroups = mapChatGroups(state, responseChatGroups);

      if (
        loggedUser?.isAdminClient ||
        (loggedUser?.isAdmin &&
          state.selectedAdminClientOwnerId !=
            CHAT_ADMIN_CLIENT_OWNER_NOT_SELECTED)
      ) {
        tempChatRoomGroups =
          createAndAddOverseenContainerGroup(tempChatRoomGroups);
      }

      return {
        ...state,
        loading: false,
        chatGroups: tempChatRoomGroups,
      };
    }

    case 'SAVE_CHAT_MESSAGE_TO_ROOM': {
      const { activeRoom, chatGroups, roomIdToGroupMap } = state;
      let { scrollDownChat, newMessagesCount } = state;

      let chatRooms = findChatRoomsByRoomId(
        chatGroups,
        roomIdToGroupMap,
        action.roomId
      );

      let indexToUpdate;
      if (chatRooms && action.roomId) {
        indexToUpdate = chatRooms
          .map(function (r) {
            return r.roomId;
          })
          .indexOf(Number(action.roomId));
      }

      const found = chatRooms[indexToUpdate].messages.findIndex(
        (message) => message.messageId === action.message.messageId
      );

      if (found < 0) {
        chatRooms[indexToUpdate].messages.push(action.message);
      } else {
        chatRooms[indexToUpdate].messages[found] = action.message;
      }

      if (
        chatRooms[indexToUpdate].newMessageId == null &&
        !action.recipientIsSender
      ) {
        chatRooms[indexToUpdate].newMessageId = action.message.messageId;
      }

      if (action.recipientIsSender) {
        chatRooms[indexToUpdate].lastSeenMessageId = action.message.messageId;
        chatRooms[indexToUpdate].newMessageId = null;
        chatRooms[indexToUpdate].roomSaw = true;
        chatRooms[indexToUpdate].messagesCount = 0;
      } else {
        newMessagesCount += 1;
        chatRooms[indexToUpdate].roomSaw = false;
        if (action.updateCounter) {
          chatRooms[indexToUpdate].messagesCount += 1;
        }
      }

      if (activeRoom == action.roomId) {
        scrollDownChat += 1;
      }

      return {
        ...state,
        loading: false,
        chatGroups,
        scrollDownChat,
        newMessagesCount,
        lastMessageReceivedFrom: action.message.userId,
      };
    }

    case 'GET_CHAT_ROOM_MESSAGES_PENDING': {
      return { ...state, loading: true };
    }

    case 'GET_CHAT_ROOM_MESSAGES_REJECTED': {
      return { ...state, loading: false };
    }

    case 'GET_CHAT_ROOM_MESSAGES_FULFILLED': {
      const { chatGroups, activeRoom, roomIdToGroupMap } = state;

      const chatRooms = findChatRoomsByRoomId(
        chatGroups,
        roomIdToGroupMap,
        activeRoom
      );

      let indexToUpdate;
      if (chatRooms && activeRoom) {
        indexToUpdate = chatRooms
          .map(function (r) {
            return r.roomId;
          })
          .indexOf(activeRoom);
      }

      const newMessages = action.payload;

      chatRooms[indexToUpdate].messages.map((mes) => {
        newMessages.push(mes);
      });

      chatRooms[indexToUpdate].messages = newMessages;

      return {
        ...state,
        loading: false,
        /*chatRooms,*/
        chatGroups,
      };
    }
    case 'UPDATE_CHAT_NOTIFICATION_DATA_FULFILLED': {
      const { chatGroups, roomIdToGroupMap } = state;

      const roomsForUpdate = action.payload;

      roomsForUpdate.map((roomForUpdate) => {
        const chatRooms = findChatRoomsByRoomId(
          chatGroups,
          roomIdToGroupMap,
          roomForUpdate.ChatRoomID
        );

        let indexToUpdate;
        if (chatRooms && roomForUpdate) {
          indexToUpdate = chatRooms
            .map(function (r) {
              return r.roomId;
            })
            .indexOf(roomForUpdate.ChatRoomID);
        }

        chatRooms[indexToUpdate].messagesCount = roomForUpdate.UnreadMessages;
      });

      return { ...state, chatGroups };
    }

    case 'SET_MESSAGE_SEEN': {
      const { chatGroups, activeRoom, roomIdToGroupMap } = state;
      const chatRooms = findChatRoomsByRoomId(
        chatGroups,
        roomIdToGroupMap,
        activeRoom
      );

      const chatGroupId = roomIdToGroupMap[activeRoom];
      if (!chatGroupId || !chatGroupId.length) {
        return;
      }

      const chatGroup = chatGroups.find((x) => x.groupId === chatGroupId[0]);
      if (!chatGroup) {
        return;
      }

      if (!chatRooms || !activeRoom) {
        return {
          ...state,
        };
      }

      const indexToUpdate = chatRooms.findIndex((x) => x.roomId === activeRoom);
      if (indexToUpdate < 0) {
        return {
          ...state,
        };
      }

      for (let i = 0; i < chatRooms[indexToUpdate].messages.length; i++) {
        const x = chatRooms[indexToUpdate].messages[i];

        if (x.messageId === action.messageId) {
          x.seen = true;
          chatRooms[indexToUpdate].messages[i] = x;
          break;
        }
      }

      let newMessagesCount = chatRooms[indexToUpdate].messagesCount - 1;
      if (newMessagesCount < 0) {
        newMessagesCount = 0;
      }

      chatRooms[indexToUpdate] = {
        ...chatRooms[indexToUpdate],
        lastSeenMessageId: action.messageId,
        newMessageId: null,
        messagesCount: newMessagesCount,
        roomSaw: newMessagesCount === 0,
      };
      // Update unread temp chat group as well
      const unreadChatGroup = chatGroups.find((x) => x.groupId === 'Unread');
      if (!unreadChatGroup) {
        return {
          ...state,
        };
      }

      const unreadIndexToUpdate = unreadChatGroup.chatRooms.findIndex(
        (x) => x.roomId === activeRoom
      );
      if (unreadIndexToUpdate < 0) {
        return {
          ...state,
        };
      }

      let unreadGroupChatroom = unreadChatGroup.chatRooms[unreadIndexToUpdate];

      for (let i = 0; i < unreadGroupChatroom.messages.length; i++) {
        const x = unreadGroupChatroom.messages[i];

        if (x.messageId === action.messageId) {
          x.seen = true;
          unreadGroupChatroom.messages[i] = x;
          break;
        }
      }

      let newUnreadGroupMessagesCount = unreadGroupChatroom.messagesCount - 1;
      if (newUnreadGroupMessagesCount < 0) {
        newUnreadGroupMessagesCount = 0;
      }

      unreadChatGroup.chatRooms[unreadIndexToUpdate] = {
        ...unreadGroupChatroom,
        lastSeenMessageId: action.messageId,
        newMessageId: null,
        messagesCount: newMessagesCount,
        roomSaw: newMessagesCount === 0,
      };

      chatGroup.chatRooms = chatRooms;

      return { ...state, chatGroups };
    }
    case 'RESET_NEW_MESSAGES_COUNT': {
      return { ...state, newMessagesCount: 0 };
    }
    case 'GET_OVERSEEN_CHAT_GROUPS_PENDING': {
      return loadingOverseenGroupsReduxHandler(state, true);
    }
    case 'GET_OVERSEEN_CHAT_GROUPS_REJECTED': {
      return loadingOverseenGroupsReduxHandler(state, false);
    }
    case 'GET_OVERSEEN_CHAT_GROUPS_FULFILLED': {
      const { chatGroups } = state;

      const overseenChatGroupIndex = chatGroups.findIndex(
        (cg) => cg.groupId === CHAT_SYNTHETIC_OVERSEEN_GROUP_ID
      );

      const overseenChatGroup = chatGroups[overseenChatGroupIndex];
      overseenChatGroup.chatGroups = mapChatGroups(state, action.payload, true);
      overseenChatGroup.nestedChatGroupsLoaded = true;

      return {
        ...state,
        chatGroups,
      };
    }

    case 'GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES_PENDING':
    case 'GET_OVERSEEN_CHAT_ROOM_MESSAGES_PENDING': {
      return loadingOverseenRoomMessagesReduxHandler(
        state,
        action.meta.roomId,
        true
      );
    }

    case 'GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES_REJECTED':
    case 'GET_OVERSEEN_CHAT_ROOM_MESSAGES_REJECTED': {
      return loadingOverseenRoomMessagesReduxHandler(
        state,
        action.meta.roomId,
        false
      );
    }

    case 'GET_OVERSEEN_CHAT_ROOM_MESSAGES_FULFILLED': {
      const { chatGroups, roomIdToGroupMapOverseen } = state;

      const chatRooms = findChatRoomsByRoomId(
        chatGroups,
        roomIdToGroupMapOverseen,
        action.meta.roomId
      );

      const foundChatRoom = chatRooms.find(
        (cr) => cr.roomId === action.meta.roomId
      );
      foundChatRoom.messages = action.payload;
      foundChatRoom.messagesLoaded = true;

      return {
        ...state,
        chatGroups,
      };
    }

    case 'GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES_FULFILLED': {
      if (action.payload.length == 0) {
        return state;
      }

      const { chatGroups, roomIdToGroupMapOverseen } = state;

      const chatRooms = findChatRoomsByRoomId(
        chatGroups,
        roomIdToGroupMapOverseen,
        action.meta.roomId
      );

      const foundChatRoom = chatRooms.find(
        (cr) => cr.roomId === action.meta.roomId
      );
      foundChatRoom.messages = [...foundChatRoom.messages, ...action.payload];
      foundChatRoom.messagesLoaded = true;

      return {
        ...state,
        chatGroups,
      };
    }

    case 'CREATE_UNREAD_GROUP': {
      const chatGroups = state.chatGroups;
      let unreadGroup = chatGroups.find((x) => x.groupId === 'Unread');
      const { chatRooms } = action.payload;

      const rooms = chatRooms.filter((r) => r.messagesCount > 0);

      if (rooms === undefined || rooms.length === 0) {
        if (unreadGroup) {
          const newGroups = chatGroups.filter((x) => x.groupId !== 'Unread');
          return {
            ...state,
            chatGroups: newGroups,
          };
        } else {
          return state;
        }
      }

      if (unreadGroup !== undefined) {
        return state;
      }

      unreadGroup = {
        chatGroups: [],
        chatRooms: rooms,
        groupId: 'Unread',
        groupName: 'Unread',
        groupType: 5,
        overseen: false,
      };

      chatGroups.unshift(unreadGroup);

      return {
        ...state,
        chatGroups,
      };
    }

    default:
      return state;
  }
};

function mapChatGroups(
  state,
  chatRoomGroups,
  insideOverseenGroupContainer = false
) {
  const { roomIdToGroupMap, roomIdToGroupMapOverseen } = state;

  let tempChatRoomGroups = chatRoomGroups.map((crg) => {
    if (!insideOverseenGroupContainer) {
      putIntoRoomIdToGroupMap(roomIdToGroupMap, crg.chatRooms, crg.groupId);
    } else {
      putIntoRoomIdToGroupMap(
        roomIdToGroupMapOverseen,
        crg.chatRooms,
        CHAT_SYNTHETIC_OVERSEEN_GROUP_ID,
        crg.groupId
      );
    }

    return {
      ...crg,
      loading: false,
      chatRooms: crg.chatRooms.map((cr) => ({
        ...cr,
        loading: false,
        messagesLoaded: !insideOverseenGroupContainer,
        overseen: insideOverseenGroupContainer,
      })),
      hasNestedGroups: false,
      nestedChatGroupsLoaded: false,
      chatGroups: [],
    };
  });

  return tempChatRoomGroups;
}

function createAndAddOverseenContainerGroup(chatRoomGroups) {
  chatRoomGroups.push({
    groupId: CHAT_SYNTHETIC_OVERSEEN_GROUP_ID,
    groupName: 'Overseen',
    groupType: -2,
    loading: false,
    overseen: true,
    hasNestedGroups: true,
    nestedChatGroupsLoaded: false,
    chatGroups: [],
    chatRooms: [],
  });

  return chatRoomGroups;
}

function putIntoRoomIdToGroupMap(roomIdToGroupMap, chatRooms, ...groupsIds) {
  chatRooms.forEach((cr) => (roomIdToGroupMap[cr.roomId] = groupsIds));
}
function removeFromRoomIdToGroupMap(roomIdToGroupMap, ...roomsIds) {
  roomsIds.forEach((roomId) => delete roomIdToGroupMap[roomId]);
}

export function findChatRoomsByRoomId(chatGroups, roomIdToGroupMap, roomId) {
  const chatGroupsIds = roomIdToGroupMap[roomId];

  let chatRooms;
  if (chatGroups && chatGroupsIds) {
    chatRooms = findChatRoomsByGroupsIds(chatGroups, chatGroupsIds);
  } else {
    throw new NotFoundChatGroupError('chatGroups or chatGroupsIds unknown');
  }

  return chatRooms;
}

export function findChatRoomsByRoomIdInRegularAndOverseen(state) {
  const { chatGroups, roomIdToGroupMap, roomIdToGroupMapOverseen, activeRoom } =
    state;

  let chatRooms;
  try {
    chatRooms = findChatRoomsByRoomId(chatGroups, roomIdToGroupMap, activeRoom);
  } catch (error) {
    if (error instanceof NotFoundChatGroupError) {
      chatRooms = findChatRoomsByRoomId(
        chatGroups,
        roomIdToGroupMapOverseen,
        activeRoom
      );
    } else {
      throw error;
    }
  }

  return chatRooms;
}

function findChatGroupByGroupsIds(chatGroups, chatGroupsIds) {
  let tempChatGroupsIds = [...chatGroupsIds];
  let currentChatGroup = chatGroups;
  while (tempChatGroupsIds.length > 0) {
    let currentGroupId = tempChatGroupsIds.shift();
    currentChatGroup = currentChatGroup.find(
      (cg) => cg.groupId === currentGroupId
    );
    if (tempChatGroupsIds.length > 0) {
      currentChatGroup = currentChatGroup.chatGroups;
    }
  }

  return currentChatGroup;
}

function findChatRoomsByGroupsIds(chatGroups, chatGroupsIds) {
  return findChatGroupByGroupsIds(chatGroups, chatGroupsIds).chatRooms;
}

function loadingOverseenGroupsReduxHandler(state, loading) {
  const { chatGroups } = state;

  const overseenChatGroupIndex = chatGroups.findIndex(
    (cg) => cg.groupId === CHAT_SYNTHETIC_OVERSEEN_GROUP_ID
  );

  const overseenChatGroup = chatGroups[overseenChatGroupIndex];
  overseenChatGroup.loading = loading;

  return {
    ...state,
    chatGroups,
  };
}

function loadingOverseenRoomMessagesReduxHandler(state, roomId, loading) {
  const { chatGroups, roomIdToGroupMapOverseen } = state;

  const chatRooms = findChatRoomsByRoomId(
    chatGroups,
    roomIdToGroupMapOverseen,
    roomId
  );

  chatRooms.find((cr) => cr.roomId === roomId).loading = loading;

  return {
    ...state,
    chatGroups,
  };
}

class NotFoundChatGroupError extends Error {
  constructor(message) {
    super(message);
  }
}

export default ChatReducer;
