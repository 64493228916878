const initState = {
  loading: false,
  contacts: [],
  contactDetailTypes: [],
};

const PhonebookReducer = function (state = initState, action) {
  switch (action.type) {
    case 'GET_AC_COMPANY_CONTACTS_FULFILLED':
    case 'GET_CONTACTS_FULFILLED': {
      return {
        ...state,
        loading: false,
        contacts: [...action.payload],
      };
    }

    case 'GET_AC_COMPANY_CONTACTS_REJECTED':
    case 'GET_CONTACTS_REJECTED': {
      return {
        ...state,
        loading: false,
        contacts: [],
      };
    }

    case 'GET_CONTACT_DETAIL_TYPES_FULFILLED': {
      return {
        ...state,
        loading: false,
        contactDetailTypes: action.payload,
      };
    }

    case 'IMPORT_CONTACTS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'IMPORT_CONTACTS_FULFILLED': {
      return {
        ...state,
        loading: false,
        contacts: [...action.payload],
      };
    }

    case 'DELETE_CONTACTS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    default:
      return state;
  }
};

export default PhonebookReducer;
