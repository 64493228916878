const initState = {
  loading: false,
  dayEntries: [],
  calendarEntryTypes: [],
  calendarEntryReminderTime: [],
  calendarEntryRecurrences: [],
  monthlyAvailability: [],
};

const CalendarReducer = function (state = initState, action) {
  switch (action.type) {
    case 'GET_CALENDAR_ENTRY_RECURRENCES_FULFILLED': {
      return {
        ...state,
        loading: false,
        calendarEntryRecurrences: action.payload,
      };
    }

    case 'GET_CALENDAR_ENTRY_TYPES_FULFILLED': {
      return {
        ...state,
        loading: false,
        calendarEntryTypes: action.payload,
      };
    }

    case 'GET_CALENDAR_ENTRY_REMINDER_TIME_FULFILLED': {
      return {
        ...state,
        loading: false,
        calendarEntryReminderTime: action.payload,
      };
    }

    case 'GET_CALENDAR_ENTRIES_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'GET_CALENDAR_ENTRIES_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'GET_CALENDAR_ENTRIES_FULFILLED': {
      return {
        ...state,
        loading: false,
        dayEntries: action.payload,
      };
    }

    case 'SAVE_CALENDAR_ENTRY_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'SAVE_CALENDAR_ENTRY_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'SAVE_CALENDAR_ENTRY_FULFILLED': {
      return {
        ...state,
        loading: false,
        dayEntries: action.payload,
      };
    }

    case 'DELETE_CALENDAR_ENTRY_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'DELETE_CALENDAR_ENTRY_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'DELETE_CALENDAR_ENTRY_FULFILLED': {
      return {
        ...state,
        loading: false,
        dayEntries: action.payload,
      };
    }

    case 'GET_CALENDAR_MONTHLY_AVAILABILITY_FULFILLED': {
      return {
        ...state,
        loading: false,
        monthlyAvailability: action.payload,
      };
    }

    case 'SET_CALENDAR_ENTRY_SEEN_FULFILLED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'UPDATE_CALENDAR_ENTRY_SEEN': {
      const dayEntries = state.dayEntries;
      const indexToUpdate = state.dayEntries
        ?.map(function (c) {
          return c.ID;
        })
        .indexOf(action.calendarEntryId);

      if (indexToUpdate > -1) {
        dayEntries[indexToUpdate].Seen = true;
      }

      return {
        ...state,
        dayEntries: [...dayEntries],
        loading: false,
      };
    }
    case 'CLEAR_DAY_ENTRIES': {
      return {
        ...state,
        dayEntries: [],
      };
    }
    default:
      return state;
  }
};

export default CalendarReducer;
