import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Row, snackbarVariants } from '../../../utilities/constants';
import {
  attachPaymentMethod,
  getPaymentMethods,
  getStripeCustomer,
} from '../../../api/api-functions';
import { retryInvoice, updateBillingInfo } from '../../../pages/Products/api';

import BillingDetails from '../RegistrationCheckoutForm/BillingDetails';
import ButtonWithLoader from '../../../pure-ui/ButtonWithLoader/ButtonWithLoader';
import InvoiceSummary from './InvoiceSummary';
import { setRefreshOwnerSubscriptionsAndInvoicesTrue } from '../../../actions/user-actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

const RetryInvoice = (props) => {
  const {
    stripe,
    elements,
    paymentElement,
    invoice,
    setIsOpenCheckout,
    pendingInvoice,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [billingInfo, setBillingInfo] = useState({});
  const [userCards, setUserCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [addCard, setAddCard] = useState(false);
  const [isLoading, setisLoading] = useState('');

  const fetchStripeDetails = async () => {
    const customer = await getStripeCustomer();
    const defaultCard = customer?.invoice_settings?.default_payment_method;
    setBillingInfo({
      paymentAddress: customer?.address?.line1,
      paymentCity: customer?.address?.city,
      paymentCountry: customer?.address?.country,
      paymentState: customer?.address?.state,
      paymentZIP: customer?.address?.postal_code,
    });

    const cards = await getPaymentMethods();
    const sortedCards = [...cards].sort((a, b) => {
      if (a.id === defaultCard) return -1;
      if (b.id === defaultCard) return 1;
    });
    setUserCards(sortedCards);
    setSelectedCard(sortedCards[0]?.id);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchStripeDetails();
    };

    fetchData();
  }, []);

  const handleAddCard = async (ev) => {
    ev.preventDefault();
    setisLoading('addingCard');
    try {
      const { error: submitError } = await elements.submit();

      if (submitError) {
        enqueueSnackbar(
          'Please select a card or add a new one!',
          snackbarVariants.error
        );
        setisLoading('');
        return;
      }

      const newCard = await stripe.createPaymentMethod({
        elements,
      });

      await attachPaymentMethod(newCard.paymentMethod.id);
      await fetchStripeDetails();
      setSelectedCard(newCard.paymentMethod.id);
      const cardElement = elements.getElement('payment');
      cardElement.clear();
      setAddCard(false);
      setisLoading('');
    } catch (error) {
      console.log('Error occured while adding card:', error);
      enqueueSnackbar(error?.response?.data?.Message, snackbarVariants.error);
      setisLoading('');
    }
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();
    setisLoading(`subscribing-${selectedCard}`);

    try {
      const result = await updateBillingInfo(billingInfo);
      if (!result?.IsBillingAddressValid) {
        enqueueSnackbar('Billing address is invalid', snackbarVariants.error);
        setisLoading('');
        return;
      }

      const response = await retryInvoice(invoice.id, selectedCard);

      if (response.status !== 200) {
        enqueueSnackbar(response?.statusText, snackbarVariants.error);
        return;
      }

      setIsOpenCheckout(false);
      dispatch(setRefreshOwnerSubscriptionsAndInvoicesTrue());

      setisLoading('');
    } catch (error) {
      console.log('Error occured while subscription:', error);
      enqueueSnackbar(error?.response?.data?.Message, snackbarVariants.error);
      setisLoading('');
    }
  };

  return (
    <Grid className="flex flex-col items-center w-full">
      <Paper
        style={pendingInvoice ? { boxShadow: 'none', width: '100%' } : {}}
        className="w-[90%] max-w-[750px]"
      >
        <form
          onSubmit={handleFormSubmit}
          autoComplete="off"
          className="my-[60px]"
        >
          <>
            <InvoiceSummary invoice={invoice} />
            <div className="flex flex-col w-full md:p-0 p-[30px] box-border">
              <BillingDetails
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
              />
            </div>
          </>
          <Row>
            <div className="md:p-[20px] p-2">
              {userCards.map((card, index) => {
                return (
                  <div
                    key={card.id}
                    className="rounded-lg py-2 px-3 flex justify-between items-center mt-2 border border-gray-300 border-solid w-full max-w-[600px] box-border"
                  >
                    <div className="flex md:flex-row flex-col justify-start items-center gap-x-3 gap-y-1">
                      <img
                        className="w-[50px] border border-gray-300 border-solid rounded-md"
                        src={`/images/cards/${card.card.brand}.svg`}
                        alt={card.card.brand}
                      />
                      <div>{`•••• •••• •••• ${card.card.last4}`}</div>
                      {index === 0 ? (
                        <div className="text-green">Current Default</div>
                      ) : null}
                    </div>
                    <div className="flex flex-row">
                      <ButtonWithLoader
                        isLoading={isLoading === `subscribing-${card?.id}`}
                        onClick={() => {
                          if (selectedCard === card?.id) {
                            setSelectedCard(null);
                          } else setSelectedCard(card?.id);
                        }}
                        title={index === 0 ? 'Default' : 'Use Card'}
                        disabled={isLoading !== ''}
                        isProcessCompleted={
                          selectedCard === card?.id ? true : false
                        }
                        titleOnComplete="Selected"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {addCard ? (
              <div className="md:p-[50px] p-3 max-w-[600px]">
                {paymentElement}
                <div className="flex flex-row justify-center gap-x-3 mt-3">
                  <div className="flex flex-row">
                    <ButtonWithLoader
                      isLoading={isLoading === 'addingCard'}
                      onClick={handleAddCard}
                      title="Add New Card"
                      disabled={isLoading !== ''}
                    />
                  </div>
                  <Button
                    onClick={() => setAddCard(false)}
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-row mb-3 w-full justify-center">
                <Button
                  className="pb-3"
                  onClick={() => setAddCard(true)}
                  variant="contained"
                  color="primary"
                  disabled={isLoading !== ''}
                >
                  Add Card
                </Button>
              </div>
            )}
          </Row>

          <Row className="text-left">
            <div className="pl-4">
              <FormControlLabel
                label="I agree"
                control={
                  <Checkbox
                    color="primary"
                    name="privacyPolicy"
                    checked={privacyPolicy}
                    onChange={() => setPrivacyPolicy(!privacyPolicy)}
                  />
                }
              />
              <a
                onClick={() => {
                  window.open(
                    'https://www.worldwideassist.net/privacy-policy.html',
                    '_blank'
                  );
                }}
                className="checkout_link"
              >
                Privacy policy
              </a>
            </div>
            <div className="pl-4">
              <FormControlLabel
                label="I agree"
                control={
                  <Checkbox
                    color="primary"
                    name="termsAndConditions"
                    checked={termsAndConditions}
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                  />
                }
              />
              <a
                onClick={() => {
                  window.open(
                    'https://www.worldwideassist.net/terms-and-conditions.html',
                    '_blank'
                  );
                }}
                className="checkout_link"
              >
                Terms and conditions
              </a>
            </div>
          </Row>
          <Row>
            <ButtonWithLoader
              isLoading={isLoading === `subscribing-${selectedCard}`}
              disabled={
                isLoading !== '' ||
                !stripe ||
                !termsAndConditions ||
                !privacyPolicy
              }
              onClick={handleFormSubmit}
              title={
                isLoading === `subscribing-${selectedCard}`
                  ? 'Processing...'
                  : `PROCESS INVOICE`
              }
            />
          </Row>
          {!pendingInvoice && (
            <div className="flex flex-row w-full justify-center">
              <Button
                onClick={() => setIsOpenCheckout(false)}
                variant="contained"
                color="primary"
                className=""
              >
                Back
              </Button>
            </div>
          )}
        </form>
      </Paper>
    </Grid>
  );
};

export default RetryInvoice;
