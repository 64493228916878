const initUserSettings = {
  UserID: 0,
  EmailNotification_NewTask: false,
  EmailNotification_NewTaskComment: false,
  EmailNotification_MissedChatMessage: false,
  EmailNotification_NotificationFrequency: {},
  EmailNotification_NewCalendarEntry: false,
  PushNotification_NewTask: false,
  PushNotification_NewChatMessage: false,
  PushNotification_NewTaskComment: false,
  PushNotification_NewTaskDocument: false,
  PushNotification_NewCalendarEntry: false,
  PushNotification_ReminderCalendarEntry: false,
  PushNotification_NewRegistration: false,
};

const initState = {
  loading: false,
  errors: {},
  settings: [],
  userSettings: initUserSettings,
  notificationFrequencies: undefined,
};

const SettingsReducer = function (state = initState, action) {
  switch (action.type) {
    case 'LOGOUT': {
      return initState;
    }
    case 'GET_GLOBAL_SETTINGS_PENDING': {
      return { ...state, loading: true };
    }
    case 'GET_GLOBAL_SETTINGS_REJECTED': {
      return { ...state, loading: false };
    }
    case 'GET_GLOBAL_SETTINGS_FULFILLED': {
      return { ...state, settings: action.payload, loading: false };
    }

    case 'SAVE_GLOBAL_SETTING_PENDING': {
      return { ...state, loading: true };
    }
    case 'SAVE_GLOBAL_SETTING_REJECTED': {
      return { ...state, loading: false };
    }
    case 'SAVE_GLOBAL_SETTING_FULFILLED': {
      return { ...state, loading: false };
    }

    case 'SET_GLOBAL_SETTINGS': {
      return { ...state, settings: action.data, loading: false };
    }

    case 'GET_USER_SETTINGS_PENDING': {
      return { ...state, loading: true };
    }
    case 'GET_USER_SETTINGS_REJECTED': {
      return { ...state, loading: false };
    }
    case 'GET_USER_SETTINGS_FULFILLED': {
      if (action.payload) {
        return { ...state, userSettings: action.payload, loading: false };
      }

      return {
        ...state,
        userSettings: initUserSettings,
        loading: false,
      };
    }

    case 'SAVE_USER_SETTINGS_PENDING': {
      return { ...state, loading: true };
    }
    case 'SAVE_USER_SETTINGS_REJECTED': {
      return { ...state, loading: false };
    }
    case 'SAVE_USER_SETTINGS_FULFILLED': {
      return { ...state, userSettings: action.payload, loading: false };
    }

    case 'SET_USER_SETTING': {
      const { userSettings } = state;
      userSettings[action.key] = action.value;
      return { ...state, userSettings: userSettings, loading: false };
    }

    case 'GET_NOTIFICATION_FREQUENCIES_PENDING': {
      return { ...state, loading: true };
    }
    case 'GET_NOTIFICATION_FREQUENCIES_REJECTED': {
      return { ...state, loading: false };
    }
    case 'GET_NOTIFICATION_FREQUENCIES_FULFILLED': {
      return {
        ...state,
        notificationFrequencies: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default SettingsReducer;
