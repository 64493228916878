import { DISPATCHER_ACTION_TYPE } from '../reducers/notistack-dispatcher-reducer';

export const POST_MESSAGE_NOTISTACK = 'POST_MESSAGE_NOTISTACK';
export const CLEAR_MESSAGE_NOTISTACK = 'CLEAR_MESSAGE_NOTISTACK';
export const CLOSE_MESSAGES_NOTISTACK = 'CLOSE_MESSAGES_NOTISTACK';
export const LOGOUT_NOTISTACK = 'LOGOUT_NOTISTACK';
/**
 * @param { { variant : 'success' | 'error' | 'info' | 'warning'} } variant
 * @param { string } message
 * @returns
 */
export function postMessageNotistack(
  message,
  variant = 'info',
  logout = false
) {
  return {
    type: POST_MESSAGE_NOTISTACK,
    payload: {
      dispatcherAction: logout
        ? DISPATCHER_ACTION_TYPE.POST_AND_LOGOUT
        : DISPATCHER_ACTION_TYPE.POST_MESSAGE,
      variant: variant,
      message,
      timestamp: new Date(),
    },
  };
}

export function postSuccessMessageNotistack(message) {
  return {
    type: POST_MESSAGE_NOTISTACK,
    payload: {
      dispatcherAction: DISPATCHER_ACTION_TYPE.POST_MESSAGE,
      variant: 'success',
      message,
      timestamp: new Date(),
    },
  };
}

export function postErrorMessageNotistack(message, logout = false) {
  return {
    type: POST_MESSAGE_NOTISTACK,
    payload: {
      dispatcherAction: logout
        ? DISPATCHER_ACTION_TYPE.POST_AND_LOGOUT
        : DISPATCHER_ACTION_TYPE.POST_MESSAGE,
      variant: 'error',
      message,
      timestamp: new Date(),
    },
  };
}

export function postWarningMessageNotistack(message) {
  return {
    type: POST_MESSAGE_NOTISTACK,
    payload: {
      dispatcherAction: DISPATCHER_ACTION_TYPE.POST_MESSAGE,
      variant: 'warning',
      message,
      timestamp: new Date(),
    },
  };
}

export function clearMessageNotistack() {
  return {
    type: CLEAR_MESSAGE_NOTISTACK,
    payload: {
      dispatcherAction: DISPATCHER_ACTION_TYPE.CLEAR_MESSAGE,
      message: '',
      timestamp: new Date(),
    },
  };
}

export function logoutNotistack() {
  return {
    type: LOGOUT_NOTISTACK,
    payload: {
      dispatcherAction: DISPATCHER_ACTION_TYPE.LOG_OUT,
      timestamp: new Date(),
    },
  };
}
