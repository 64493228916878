import { Button, Paper, Step, StepLabel, Stepper } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { snackbarVariants, styles } from '../../utilities/constants';
import { useEffect, useState } from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import ProductsRegistration from '../../components/ProductsRegistration/ProductsRegistration';
import RegistrationCheckoutForm from '../../components/Stripe/RegistrationCheckoutForm';
import RetryInvoice from '../../components/Stripe/RetryInvoice';
import StripePaymentElement from '../../components/Stripe';
import { ownerInvoices } from '../Products/api';
import { setSubscribe } from '../../actions/authentication-actions';
import useForm from '../../hooks/useForm';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import vcLogo from './../../assets/wwa-logo.svg';

const LoginUnsubsciredUser = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const redirectStatus = query.get('redirect_status');
  const user = useSelector((store) => store.user);
  const [failedInvoice, setFailedInvoice] = useState(null);

  const { form, setForm, updateValue } = useForm({
    stepIndex: user?.pendingInvoiceId ? 1 : 0,
    addedProducts: [],
    addedServices: [],
  });

  useEffect(() => {
    if (redirectStatus) {
      enqueueSnackbar('Registration successful!', snackbarVariants.success);
      goToLogin();
    }
  }, [redirectStatus]);

  const fetchInvoice = async () => {
    const response = await ownerInvoices();
    const { invoicesArray } = response;
    const failedInvoice = invoicesArray.filter(
      (invoice) =>
        invoice?.id === user?.pendingInvoiceId && invoice?.status === 'open'
    );
    if (failedInvoice?.length) setFailedInvoice(failedInvoice[0]);
  };

  useEffect(() => {
    if (user?.pendingInvoiceId) fetchInvoice();
  }, []);

  const handleNext = () => {
    if (form?.stepIndex < 1) updateValue('stepIndex', form?.stepIndex + 1);
  };

  const handlePrev = () => {
    if (form?.stepIndex > 0) updateValue('stepIndex', form?.stepIndex - 1);
  };

  const goToLogin = () => {
    dispatch(setSubscribe(true));
  };

  const onChangeCoupon = (e) => {
    updateValue('paymentCoupon', e.target.value);
  };

  const filterFunc = (array, productId) => {
    const res = array.filter((item) => item.productId === productId);
    return res;
  };

  const onAdd = (productId, priceId, amount, service) => {
    if (service) {
      // Add or remove service
      filterFunc(form?.addedServices, productId).length === 0
        ? setForm({
            ...form,
            addedServices: [
              ...form.addedServices,
              { productId, priceId, amount },
            ],
          })
        : setForm({
            ...form,
            addedServices: form.addedServices.filter(
              (s) => s.productId !== productId
            ),
          });
    } else {
      // New product added
      filterFunc(form.addedProducts, productId).length === 0
        ? setForm({ ...form, addedProducts: [{ productId, priceId, amount }] })
        : setForm({ ...form, addedProducts: [] });
    }
  };

  const isAdded = (productId) => {
    return (
      filterFunc([...form.addedProducts, ...form.addedServices], productId)
        .length !== 0
    );
  };

  const renderStepper = (stepIndex) => {
    return (
      <Stepper activeStep={stepIndex} className="w-[280px] m-auto">
        <Step>
          <StepLabel>Products</StepLabel>
        </Step>
        <Step>
          <StepLabel>Subscribe</StepLabel>
        </Step>
      </Stepper>
    );
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <ProductsRegistration onAdd={onAdd} isAdded={isAdded} />;
      case 1:
        return user?.pendingInvoiceId ? (
          <StripePaymentElement>
            <RetryInvoice
              invoice={failedInvoice}
              setIsOpenCheckout={goToLogin}
              pendingInvoice
            />
          </StripePaymentElement>
        ) : (
          <StripePaymentElement>
            <RegistrationCheckoutForm
              productInfo={[...form.addedProducts, ...form.addedServices]}
              paymentCoupon={form?.paymentCoupon}
              onChangeCoupon={onChangeCoupon}
              goToLogin={goToLogin}
            />
          </StripePaymentElement>
        );
      default:
        return '';
    }
  };

  return (
    <div className="flex flex-col w-full items-center">
      <Paper
        className={`flex flex-col w-full max-w-[750px] text-center md:p-[40px] ${
          form?.stepIndex === 2 ? 'p-0' : 'p-[20px]'
        } box-border`}
      >
        <img
          className="self-center w-[250px]"
          src={vcLogo}
          alt="VirtualAssistant"
        />
        {isWidthUp('md', props.width) ? renderStepper(form?.stepIndex) : null}
        <>
          {getStepContent(form?.stepIndex)}
          <div style={{ marginTop: 12 }}>
            <Button
              onClick={form?.stepIndex === 0 ? goToLogin : handlePrev}
              className="mr-3"
              disabled={user?.pendingInvoiceId}
            >
              {form?.stepIndex === 0 ? 'Login' : 'Back'}
            </Button>
            <Button
              disabled={
                form?.stepIndex === 0 && form.addedProducts.length === 0
              }
              variant="contained"
              color="primary"
              style={styles.nextButton}
              onClick={form?.stepIndex === 1 ? goToLogin : handleNext}
            >
              {form?.stepIndex === 1 ? 'Login' : 'Next'}
            </Button>
            <br />
            <br />
          </div>
        </>
      </Paper>
    </div>
  );
};

export default withWidth()(connect(null, null)(LoginUnsubsciredUser));
