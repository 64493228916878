
const countries = [
  { label: 'Afghanistan', dial_code: '+93', value: 'AF', flag: '🇦🇫' },
  { label: 'Åland Islands', dial_code: '+358', value: 'AX', flag: '🇦🇽' },
  { label: 'Albania', dial_code: '+355', value: 'AL', flag: '🇦🇱' },
  { label: 'Algeria', dial_code: '+213', value: 'DZ', flag: '🇩🇿' },
  { label: 'Andorra', dial_code: '+376', value: 'AD', flag: '🇦🇩' },
  { label: 'Angola', dial_code: '+244', value: 'AO', flag: '🇦🇴' },
  { label: 'Anguilla', dial_code: '+1264', value: 'AI', flag: '🇦🇮' },
  { label: 'Antarctica', dial_code: '+672', value: 'AQ', flag: '🇦🇶' },
  {
    label: 'Antigua and Barbuda',
    dial_code: '+1268',
    value: 'AG',
    flag: '🇦🇬',
  },
  { label: 'Argentina', dial_code: '+54', value: 'AR', flag: '🇦🇷' },
  { label: 'Armenia', dial_code: '+374', value: 'AM', flag: '🇦🇲' },
  { label: 'Aruba', dial_code: '+297', value: 'AW', flag: '🇦🇼' },
  { label: 'Australia', dial_code: '+61', value: 'AU', flag: '🇦🇺' },
  { label: 'Austria', dial_code: '+43', value: 'AT', flag: '🇦🇹' },
  { label: 'Azerbaijan', dial_code: '+994', value: 'AZ', flag: '🇦🇿' },
  { label: 'Bahamas', dial_code: '+1242', value: 'BS', flag: '🇧🇸' },
  { label: 'Bahrain', dial_code: '+973', value: 'BH', flag: '🇧🇭' },
  { label: 'Bangladesh', dial_code: '+880', value: 'BD', flag: '🇧🇩' },
  { label: 'Barbados', dial_code: '+1246', value: 'BB', flag: '🇧🇧' },
  { label: 'Belarus', dial_code: '+375', value: 'BY', flag: '🇧🇾' },
  { label: 'Belgium', dial_code: '+32', value: 'BE', flag: '🇧🇪' },
  { label: 'Belize', dial_code: '+501', value: 'BZ', flag: '🇧🇿' },
  { label: 'Benin', dial_code: '+229', value: 'BJ', flag: '🇧🇯' },
  { label: 'Bermuda', dial_code: '+1441', value: 'BM', flag: '🇧🇲' },
  { label: 'Bhutan', dial_code: '+975', value: 'BT', flag: '🇧🇹' },
  {
    label: 'Bolivia, Plurinational State of bolivia',
    dial_code: '+591',
    value: 'BO',
    flag: '🇧🇴',
  },
  {
    label: 'Bosnia and Herzegovina',
    dial_code: '+387',
    value: 'BA',
    flag: '🇧🇦',
  },
  { label: 'Botswana', dial_code: '+267', value: 'BW', flag: '🇧🇼' },
  { label: 'Bouvet Island', dial_code: '+47', value: 'BV', flag: '🏳' },
  { label: 'Brazil', dial_code: '+55', value: 'BR', flag: '🇧🇷' },
  {
    label: 'British Indian Ocean Territory',
    dial_code: '+246',
    value: 'IO',
    flag: '🇮🇴',
  },
  {
    label: 'Brunei Darussalam',
    dial_code: '+673',
    value: 'BN',
    flag: '🇧🇳',
  },
  { label: 'Bulgaria', dial_code: '+359', value: 'BG', flag: '🇧🇬' },
  { label: 'Burkina Faso', dial_code: '+226', value: 'BF', flag: '🇧🇫' },
  { label: 'Burundi', dial_code: '+257', value: 'BI', flag: '🇧🇮' },
  { label: 'Cambodia', dial_code: '+855', value: 'KH', flag: '🇰🇭' },
  { label: 'Cameroon', dial_code: '+237', value: 'CM', flag: '🇨🇲' },
  { label: 'Canada', dial_code: '+1', value: 'CA', flag: '🇨🇦' },
  { label: 'Cape Verde', dial_code: '+238', value: 'CV', flag: '🇨🇻' },
  {
    label: 'Cayman Islands',
    dial_code: '+ 345',
    value: 'KY',
    flag: '🇰🇾',
  },
  {
    label: 'Central African Republic',
    dial_code: '+236',
    value: 'CF',
    flag: '🇨🇫',
  },
  { label: 'Chad', dial_code: '+235', value: 'TD', flag: '🇹🇩' },
  { label: 'Chile', dial_code: '+56', value: 'CL', flag: '🇨🇱' },
  { label: 'China', dial_code: '+86', value: 'CN', flag: '🇨🇳' },
  {
    label: 'Christmas Island',
    dial_code: '+61',
    value: 'CX',
    flag: '🇨🇽',
  },
  {
    label: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    value: 'CC',
    flag: '🇨🇨',
  },
  { label: 'Colombia', dial_code: '+57', value: 'CO', flag: '🇨🇴' },
  { label: 'Comoros', dial_code: '+269', value: 'KM', flag: '🇰🇲' },
  { label: 'Congo', dial_code: '+242', value: 'CG', flag: '🇨🇬' },
  {
    label: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    value: 'CD',
    flag: '🇨🇩',
  },
  { label: 'Cook Islands', dial_code: '+682', value: 'CK', flag: '🇨🇰' },
  { label: 'Costa Rica', dial_code: '+506', value: 'CR', flag: '🇨🇷' },
  { label: "Cote d'Ivoire", dial_code: '+225', value: 'CI', flag: '🇨🇮' },
  { label: 'Croatia', dial_code: '+385', value: 'HR', flag: '🇭🇷' },
  { label: 'Cuba', dial_code: '+53', value: 'CU', flag: '🇨🇺' },
  { label: 'Cyprus', dial_code: '+357', value: 'CY', flag: '🇨🇾' },
  { label: 'Czech Republic', dial_code: '+420', value: 'CZ', flag: '🇨🇿' },
  { label: 'Denmark', dial_code: '+45', value: 'DK', flag: '🇩🇰' },
  { label: 'Djibouti', dial_code: '+253', value: 'DJ', flag: '🇩🇯' },
  { label: 'Dominica', dial_code: '+1767', value: 'DM', flag: '🇩🇲' },
  {
    label: 'Dominican Republic',
    dial_code: '+1849',
    value: 'DO',
    flag: '🇨🇩',
  },
  { label: 'Ecuador', dial_code: '+593', value: 'EC', flag: '🇪🇨' },
  { label: 'Egypt', dial_code: '+20', value: 'EG', flag: '🇪🇬' },
  { label: 'El Salvador', dial_code: '+503', value: 'SV', flag: '🇸🇻' },
  {
    label: 'Equatorial Guinea',
    dial_code: '+240',
    value: 'GQ',
    flag: '🇬🇶',
  },
  { label: 'Eritrea', dial_code: '+291', value: 'ER', flag: '🇪🇷' },
  { label: 'Estonia', dial_code: '+372', value: 'EE', flag: '🇪🇪' },
  { label: 'Ethiopia', dial_code: '+251', value: 'ET', flag: '🇪🇹' },
  {
    label: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    value: 'FK',
    flag: '🇫🇰',
  },
  { label: 'Faroe Islands', dial_code: '+298', value: 'FO', flag: '🇫🇴' },
  { label: 'Fiji', dial_code: '+679', value: 'FJ', flag: '🇫🇯' },
  { label: 'Finland', dial_code: '+358', value: 'FI', flag: '🇫🇮' },
  { label: 'France', dial_code: '+33', value: 'FR', flag: '🇫🇷' },
  { label: 'French Guiana', dial_code: '+594', value: 'GF', flag: '🇬🇫' },
  {
    label: 'French Polynesia',
    dial_code: '+689',
    value: 'PF',
    flag: '🇵🇫',
  },
  {
    label: 'French Southern Territories',
    dial_code: '+262',
    value: 'TF',
    flag: '🇹🇫',
  },
  { label: 'Gabon', dial_code: '+241', value: 'GA', flag: '🇬🇦' },
  { label: 'Gambia', dial_code: '+220', value: 'GM', flag: '🇬🇲' },
  { label: 'Georgia', dial_code: '+995', value: 'GE', flag: '🇬🇪' },
  { label: 'Germany', dial_code: '+49', value: 'DE', flag: '🇩🇪' },
  { label: 'Ghana', dial_code: '+233', value: 'GH', flag: '🇬🇭' },
  { label: 'Gibraltar', dial_code: '+350', value: 'GI', flag: '🇬🇮' },
  { label: 'Greece', dial_code: '+30', value: 'GR', flag: '🇬🇷' },
  { label: 'Greenland', dial_code: '+299', value: 'GL', flag: '🇬🇱' },
  { label: 'Grenada', dial_code: '+1473', value: 'GD', flag: '🇬🇩' },
  { label: 'Guadeloupe', dial_code: '+590', value: 'GP', flag: '🇬🇵' },
  { label: 'Guam', dial_code: '+1671', value: 'GU', flag: '🇬🇺' },
  { label: 'Guatemala', dial_code: '+502', value: 'GT', flag: '🇬🇹' },
  { label: 'Guernsey', dial_code: '+44', value: 'GG', flag: '🇬🇬' },
  { label: 'Guinea', dial_code: '+224', value: 'GN', flag: '🇬🇳' },
  { label: 'Guinea-Bissau', dial_code: '+245', value: 'GW', flag: '🇬🇼' },
  { label: 'Guyana', dial_code: '+592', value: 'GY', flag: '🇬🇾' },
  { label: 'Haiti', dial_code: '+509', value: 'HT', flag: '🇭🇹' },
  {
    label: 'Heard Island and Mcdonald Islands',
    dial_code: '+0',
    value: 'HM',
    flag: '🏳',
  },
  {
    label: 'Holy See (Vatican City State)',
    dial_code: '+379',
    value: 'VA',
    flag: '🇻🇦',
  },
  { label: 'Honduras', dial_code: '+504', value: 'HN', flag: '🇭🇳' },
  { label: 'Hong Kong', dial_code: '+852', value: 'HK', flag: '🇭🇰' },
  { label: 'Hungary', dial_code: '+36', value: 'HU', flag: '🇭🇺' },
  { label: 'Iceland', dial_code: '+354', value: 'IS', flag: '🇮🇸' },
  { label: 'India', dial_code: '+91', value: 'IN', flag: '🇮🇳' },
  { label: 'Indonesia', dial_code: '+62', value: 'ID', flag: '🇮🇩' },
  {
    label: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    value: 'IR',
    flag: '🇮🇷',
  },
  { label: 'Iraq', dial_code: '+964', value: 'IQ', flag: '🇮🇶' },
  { label: 'Ireland', dial_code: '+353', value: 'IE', flag: '🇮🇪' },
  { label: 'Isle of Man', dial_code: '+44', value: 'IM', flag: '🇮🇲' },
  { label: 'Israel', dial_code: '+972', value: 'IL', flag: '🇮🇱' },
  { label: 'Italy', dial_code: '+39', value: 'IT', flag: '🇮🇹' },
  { label: 'Jamaica', dial_code: '+1876', value: 'JM', flag: '🇯🇲' },
  { label: 'Japan', dial_code: '+81', value: 'JP', flag: '🇯🇵' },
  { label: 'Jersey', dial_code: '+44', value: 'JE', flag: '🇯🇪' },
  { label: 'Jordan', dial_code: '+962', value: 'JO', flag: '🇯🇴' },
  { label: 'Kazakhstan', dial_code: '+7', value: 'KZ', flag: '🇰🇿' },
  { label: 'Kenya', dial_code: '+254', value: 'KE', flag: '🇰🇪' },
  { label: 'Kiribati', dial_code: '+686', value: 'KI', flag: '🇰🇮' },
  {
    label: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    value: 'KP',
    flag: '🇰🇵',
  },
  {
    label: 'Korea, Republic of South Korea',
    dial_code: '+82',
    value: 'KR',
    flag: '🇰🇷',
  },
  { label: 'Kuwait', dial_code: '+965', value: 'KW', flag: '🇰🇼' },
  { label: 'Kyrgyzstan', dial_code: '+996', value: 'KG', flag: '🇰🇬' },
  { label: 'Laos', dial_code: '+856', value: 'LA', flag: '🇱🇦' },
  { label: 'Latvia', dial_code: '+371', value: 'LV', flag: '🇱🇻' },
  { label: 'Lebanon', dial_code: '+961', value: 'LB', flag: '🇱🇧' },
  { label: 'Lesotho', dial_code: '+266', value: 'LS', flag: '🇱🇸' },
  { label: 'Liberia', dial_code: '+231', value: 'LR', flag: '🇱🇷' },
  {
    label: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    value: 'LY',
    flag: '🇱🇾',
  },
  { label: 'Liechtenstein', dial_code: '+423', value: 'LI', flag: '🇱🇮' },
  { label: 'Lithuania', dial_code: '+370', value: 'LT', flag: '🇱🇹' },
  { label: 'Luxembourg', dial_code: '+352', value: 'LU', flag: '🇱🇺' },
  { label: 'Macao', dial_code: '+853', value: 'MO', flag: '🇲🇴' },
  { label: 'Macedonia', dial_code: '+389', value: 'MK', flag: '🇲🇰' },
  { label: 'Madagascar', dial_code: '+261', value: 'MG', flag: '🇲🇬' },
  { label: 'Malawi', dial_code: '+265', value: 'MW', flag: '🇲🇼' },
  { label: 'Malaysia', dial_code: '+60', value: 'MY', flag: '🇲🇾' },
  { label: 'Maldives', dial_code: '+960', value: 'MV', flag: '🇲🇻' },
  { label: 'Mali', dial_code: '+223', value: 'ML', flag: '🇲🇱' },
  { label: 'Malta', dial_code: '+356', value: 'MT', flag: '🇲🇹' },
  {
    label: 'Marshall Islands',
    dial_code: '+692',
    value: 'MH',
    flag: '🇲🇭',
  },
  { label: 'Martinique', dial_code: '+596', value: 'MQ', flag: '🇲🇶' },
  { label: 'Mauritania', dial_code: '+222', value: 'MR', flag: '🇲🇷' },
  { label: 'Mauritius', dial_code: '+230', value: 'MU', flag: '🇲🇺' },
  { label: 'Mayotte', dial_code: '+262', value: 'YT', flag: '🇾🇹' },
  { label: 'Mexico', dial_code: '+52', value: 'MX', flag: '🇲🇽' },
  {
    label: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    value: 'FM',
    flag: '🇫🇲',
  },
  { label: 'Moldova', dial_code: '+373', value: 'MD', flag: '🇲🇩' },
  { label: 'Monaco', dial_code: '+377', value: 'MC', flag: '🇲🇨' },
  { label: 'Mongolia', dial_code: '+976', value: 'MN', flag: '🇲🇳' },
  { label: 'Montenegro', dial_code: '+382', value: 'ME', flag: '🇲🇪' },
  { label: 'Montserrat', dial_code: '+1664', value: 'MS', flag: '🇲🇸' },
  { label: 'Morocco', dial_code: '+212', value: 'MA', flag: '🇲🇦' },
  { label: 'Mozambique', dial_code: '+258', value: 'MZ', flag: '🇲🇿' },
  { label: 'Myanmar', dial_code: '+95', value: 'MM', flag: '🇲🇲' },
  { label: 'Namibia', dial_code: '+264', value: 'NA', flag: '🇳🇦' },
  { label: 'Nauru', dial_code: '+674', value: 'NR', flag: '🇳🇷' },
  { label: 'Nepal', dial_code: '+977', value: 'NP', flag: '🇳🇵' },
  { label: 'Netherlands', dial_code: '+31', value: 'NL', flag: '🇳🇱' },
  {
    label: 'Netherlands Antilles',
    dial_code: '+599',
    value: 'AN',
    flag: '🇳🇱',
  },
  { label: 'New Caledonia', dial_code: '+687', value: 'NC', flag: '🇳🇨' },
  { label: 'New Zealand', dial_code: '+64', value: 'NZ', flag: '🇳🇿' },
  { label: 'Nicaragua', dial_code: '+505', value: 'NI', flag: '🇳🇮' },
  { label: 'Niger', dial_code: '+227', value: 'NE', flag: '🇳🇪' },
  { label: 'Nigeria', dial_code: '+234', value: 'NG', flag: '🇳🇬' },
  { label: 'Niue', dial_code: '+683', value: 'NU', flag: '🇳🇺' },
  { label: 'Norfolk Island', dial_code: '+672', value: 'NF', flag: '🇳🇫' },
  {
    label: 'Northern Mariana Islands',
    dial_code: '+1670',
    value: 'MP',
    flag: '🏳',
  },
  { label: 'Norway', dial_code: '+47', value: 'NO', flag: '🇳🇴' },
  { label: 'Oman', dial_code: '+968', value: 'OM', flag: '🇴🇲' },
  { label: 'Pakistan', dial_code: '+92', value: 'PK', flag: '🇵🇰' },
  { label: 'Palau', dial_code: '+680', value: 'PW', flag: '🇵🇼' },
  {
    label: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    value: 'PS',
    flag: '🇵🇸',
  },
  { label: 'Panama', dial_code: '+507', value: 'PA', flag: '🇵🇦' },
  {
    label: 'Papua New Guinea',
    dial_code: '+675',
    value: 'PG',
    flag: '🇵🇬',
  },
  { label: 'Paraguay', dial_code: '+595', value: 'PY', flag: '🇵🇾' },
  { label: 'Peru', dial_code: '+51', value: 'PE', flag: '🇵🇪' },
  { label: 'Philippines', dial_code: '+63', value: 'PH', flag: '🇵🇭' },
  { label: 'Pitcairn', dial_code: '+64', value: 'PN', flag: '🇵🇳' },
  { label: 'Poland', dial_code: '+48', value: 'PL', flag: '🇵🇱' },
  { label: 'Portugal', dial_code: '+351', value: 'PT', flag: '🇵🇹' },
  { label: 'Puerto Rico', dial_code: '+1939', value: 'PR', flag: '🇵🇷' },
  { label: 'Qatar', dial_code: '+974', value: 'QA', flag: '🇶🇦' },
  { label: 'Romania', dial_code: '+40', value: 'RO', flag: '🇷🇴' },
  { label: 'Russia', dial_code: '+7', value: 'RU', flag: '🇷🇺' },
  { label: 'Rwanda', dial_code: '+250', value: 'RW', flag: '🇷🇼' },
  { label: 'Reunion', dial_code: '+262', value: 'RE', flag: '🇫🇷' },
  {
    label: 'Saint Barthelemy',
    dial_code: '+590',
    value: 'BL',
    flag: '🇧🇱',
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    value: 'SH',
    flag: '🇸🇭',
  },
  {
    label: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    value: 'KN',
    flag: '🇰🇳',
  },
  { label: 'Saint Lucia', dial_code: '+1758', value: 'LC', flag: '🇱🇨' },
  { label: 'Saint Martin', dial_code: '+590', value: 'MF', flag: '🏳' },
  {
    label: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    value: 'PM',
    flag: '🇵🇲',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    value: 'VC',
    flag: '🇻🇨',
  },
  { label: 'Samoa', dial_code: '+685', value: 'WS', flag: '🇼🇸' },
  { label: 'San Marino', dial_code: '+378', value: 'SM', flag: '🇸🇲' },
  {
    label: 'Sao Tome and Principe',
    dial_code: '+239',
    value: 'ST',
    flag: '🇸🇹',
  },
  { label: 'Saudi Arabia', dial_code: '+966', value: 'SA', flag: '🇸🇦' },
  { label: 'Senegal', dial_code: '+221', value: 'SN', flag: '🇸🇳' },
  { label: 'Serbia', dial_code: '+381', value: 'RS', flag: '🇷🇸' },
  { label: 'Seychelles', dial_code: '+248', value: 'SC', flag: '🇸🇨' },
  { label: 'Sierra Leone', dial_code: '+232', value: 'SL', flag: '🇸🇱' },
  { label: 'Singapore', dial_code: '+65', value: 'SG', flag: '🇸🇬' },
  { label: 'Slovakia', dial_code: '+421', value: 'SK', flag: '🇸🇰' },
  { label: 'Slovenia', dial_code: '+386', value: 'SI', flag: '🇸🇮' },
  {
    label: 'Solomon Islands',
    dial_code: '+677',
    value: 'SB',
    flag: '🇸🇧',
  },
  { label: 'Somalia', dial_code: '+252', value: 'SO', flag: '🇸🇴' },
  { label: 'South Africa', dial_code: '+27', value: 'ZA', flag: '🇿🇦' },
  { label: 'South Sudan', dial_code: '+211', value: 'SS', flag: '🇸🇸' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    value: 'GS',
    flag: '🇬🇸',
  },
  { label: 'Spain', dial_code: '+34', value: 'ES', flag: '🇪🇸' },
  { label: 'Sri Lanka', dial_code: '+94', value: 'LK', flag: '🇱🇰' },
  { label: 'Sudan', dial_code: '+249', value: 'SD', flag: '🇸🇩' },
  { label: 'Suriname', dial_code: '+597', value: 'SR', flag: '🇸🇷' },
  {
    label: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    value: 'SJ',
    flag: '🇩🇰',
  },
  { label: 'Swaziland', dial_code: '+268', value: 'SZ', flag: '🇸🇿' },
  { label: 'Sweden', dial_code: '+46', value: 'SE', flag: '🇸🇪' },
  { label: 'Switzerland', dial_code: '+41', value: 'CH', flag: '🇨🇭' },
  {
    label: 'Syrian Arab Republic',
    dial_code: '+963',
    value: 'SY',
    flag: '🇸🇾',
  },
  { label: 'Taiwan', dial_code: '+886', value: 'TW', flag: '🇹🇼' },
  { label: 'Tajikistan', dial_code: '+992', value: 'TJ', flag: '🇹🇯' },
  {
    label: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    value: 'TZ',
    flag: '🇹🇿',
  },
  { label: 'Thailand', dial_code: '+66', value: 'TH', flag: '🇹🇭' },
  { label: 'Timor-Leste', dial_code: '+670', value: 'TL', flag: '🇹🇱' },
  { label: 'Togo', dial_code: '+228', value: 'TG', flag: '🇹🇬' },
  { label: 'Tokelau', dial_code: '+690', value: 'TK', flag: '🇹🇰' },
  { label: 'Tonga', dial_code: '+676', value: 'TO', flag: '🇹🇴' },
  {
    label: 'Trinidad and Tobago',
    dial_code: '+1868',
    value: 'TT',
    flag: '🇹🇹',
  },
  { label: 'Tunisia', dial_code: '+216', value: 'TN', flag: '🇹🇳' },
  { label: 'Turkey', dial_code: '+90', value: 'TR', flag: '🇹🇷' },
  { label: 'Turkmenistan', dial_code: '+993', value: 'TM', flag: '🇹🇲' },
  {
    label: 'Turks and Caicos Islands',
    dial_code: '+1649',
    value: 'TC',
    flag: '🇹🇨',
  },
  { label: 'Tuvalu', dial_code: '+688', value: 'TV', flag: '🇹🇻' },
  { label: 'Uganda', dial_code: '+256', value: 'UG', flag: '🇺🇬' },
  { label: 'Ukraine', dial_code: '+380', value: 'UA', flag: '🇺🇦' },
  {
    label: 'United Arab Emirates',
    dial_code: '+971',
    value: 'AE',
    flag: '🇦🇪',
  },
  { label: 'United Kingdom', dial_code: '+44', value: 'GB', flag: '🇬🇧' },
  { label: 'United States', dial_code: '+1', value: 'US', flag: '🇺🇸' },
  { label: 'Uruguay', dial_code: '+598', value: 'UY', flag: '🇺🇾' },
  { label: 'Uzbekistan', dial_code: '+998', value: 'UZ', flag: '🇺🇿' },
  { label: 'Vanuatu', dial_code: '+678', value: 'VU', flag: '🇻🇺' },
  {
    label: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    value: 'VE',
    flag: '🇻🇪',
  },
  { label: 'Vietnam', dial_code: '+84', value: 'VN', flag: '🇻🇳' },
  {
    label: 'Virgin Islands, British',
    dial_code: '+1284',
    value: 'VG',
    flag: '🇻🇬',
  },
  {
    label: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    value: 'VI',
    flag: '🇻🇮',
  },
  {
    label: 'Wallis and Futuna',
    dial_code: '+681',
    value: 'WF',
    flag: '🇼🇫',
  },
  { label: 'Yemen', dial_code: '+967', value: 'YE', flag: '🇾🇪' },
  { label: 'Zambia', dial_code: '+260', value: 'ZM', flag: '🇿🇲' },
  { label: 'Zimbabwe', dial_code: '+263', value: 'ZW', flag: '🇿🇼' },
];

export default countries;
