import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Row, snackbarVariants } from '../../../utilities/constants';
import {
  getPreviewInvoice,
  postSubscription,
  updateBillingInfo,
} from '../../../pages/Products/api';

import BillingDetails from './BillingDetails';
import ButtonWithLoader from '../../../pure-ui/ButtonWithLoader/ButtonWithLoader';
import InvoicePreview from './InvoicePreview';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

export const defaultTotal = {
  subtotal: 0,
  tax: 0,
  total: 0,
};

const RegistrationCheckoutForm = (props) => {
  const {
    stripe,
    elements,
    paymentElement,
    productInfo,
    paymentCoupon,
    onChangeCoupon,
    goToLogin
  } = props;

  const router = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setProcessingTo] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [previewInvoice, setPreviewInvoice] = useState([]);
  const [total, setTotal] = useState(defaultTotal);
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const [validCoupon, setValidCoupon] = useState(null);
  const [billingInfo, setBillingInfo] = useState({});

  const setInvoices = (data) => {
    const { invoicesData, totalData, status } = data;
    if (status === 200) {
      setTotal(totalData);
      setPreviewInvoice(invoicesData);
      setCouponErrorMessage('');
      return true;
    } else if (status === 422) {
      setCouponErrorMessage('Please enter a valid coupon');
      return false;
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPreviewInvoice(getPriceIds(), paymentCoupon);
      setInvoices(data);
    };

    fetchData();
  }, []);

  const getPriceIds = () => {
    return productInfo.map((product) => product.priceId);
  };

  const onApplyPaymentCoupon = async () => {
    const data = await getPreviewInvoice(getPriceIds(), paymentCoupon);
    const isValidCoupon = setInvoices(data);
    if (isValidCoupon && paymentCoupon !== '') {
      setValidCoupon(paymentCoupon);
    }
  };

  const processSubscription = async () => {
    try {
      const result = await updateBillingInfo(billingInfo);
      if (!result?.IsBillingAddressValid) {
        enqueueSnackbar('Billing address is invalid', snackbarVariants.error);
        setProcessingTo(false);
        return;
      }
      const { error: submitError } = await elements.submit();

      if (submitError) {
        enqueueSnackbar(submitError?.message, snackbarVariants.error);
        setProcessingTo(false);
        return;
      }

      const clientSecret = await postSubscription(getPriceIds(), validCoupon);

      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${process.env.REACT_APP_FRONTEND_URL}/login`,
        },
      });

      if (error) {
        enqueueSnackbar(error.message, snackbarVariants.error);
        setProcessingTo(false);
        return;
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.Message, snackbarVariants.error);
      setProcessingTo(false);
      console.log(error);
      return;
    }
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    setProcessingTo(true);

    const isFreePackage = total?.total === 0;
    if (isFreePackage) {
      await postSubscription(getPriceIds(), validCoupon);
      enqueueSnackbar('Registration Successful!', snackbarVariants.success);
      setTimeout(() => {
        router.push('/Login');
        if(goToLogin) goToLogin()
      }, 1000);
    } else await processSubscription();
  };

  return (
    <Grid className="w-full">
      <div className="w-full">
        <form
          onSubmit={handleFormSubmit}
          autoComplete="off"
          className="my-[60px]"
        >
          <>
            <InvoicePreview
              previewInvoice={previewInvoice}
              paymentCoupon={paymentCoupon}
              onChangeCoupon={onChangeCoupon}
              couponErrorMessage={couponErrorMessage}
              onApplyPaymentCoupon={onApplyPaymentCoupon}
              total={total}
            />
            {total?.total !== 0 ? (
              <div className="flex flex-col w-full md:p-0 p-[30px] box-border">
                <BillingDetails
                  billingInfo={billingInfo}
                  setBillingInfo={setBillingInfo}
                />
              </div>
            ) : null}
          </>
          {total?.total !== 0 ? (
            <Row>
              <div className="md:p-[50px] p-[10px]">{paymentElement}</div>
            </Row>
          ) : null}

          <Row className="text-left">
            <div className="pl-4">
              <FormControlLabel
                label="I agree"
                control={
                  <Checkbox
                    color="primary"
                    name="privacyPolicy"
                    checked={privacyPolicy}
                    onChange={() => setPrivacyPolicy(!privacyPolicy)}
                  />
                }
              />
              <a
                onClick={() => {
                  window.open(
                    'https://www.worldwideassist.net/privacy-policy.html',
                    '_blank'
                  );
                }}
                className="checkout_link"
              >
                Privacy policy
              </a>
            </div>
            <div className="pl-4">
              <FormControlLabel
                label="I agree"
                control={
                  <Checkbox
                    color="primary"
                    name="termsAndConditions"
                    checked={termsAndConditions}
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                  />
                }
              />
              <a
                onClick={() => {
                  window.open(
                    'https://www.worldwideassist.net/terms-and-conditions.html',
                    '_blank'
                  );
                }}
                className="checkout_link"
              >
                Terms and conditions
              </a>
            </div>
          </Row>
          <Row>
            <ButtonWithLoader
              isLoading={isProcessing}
              disabled={
                isProcessing || !stripe || !termsAndConditions || !privacyPolicy
              }
              onClick={handleFormSubmit}
              title={isProcessing ? 'Processing...' : `Subscribe`}
            />
          </Row>
        </form>
      </div>
    </Grid>
  );
};

export default RegistrationCheckoutForm;
