import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { newDataLayer, registerDataLayer } from 'src/utilities/google-tools';
import { useHistory, useLocation } from 'react-router-dom';
import {
  validateCompanyInfo,
  validatePersonalInfo,
  validateUserCompanyWorkingHours,
} from '../../utilities/validations/registration-validations';

import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { checkToken } from '../Products/api';
import isEmpty from 'is-empty';
import { registerUserWithLink } from '../../api/api-functions';
import { getTimeStringFromDateString } from '../../utilities/work-time-parser';
import CustomTimePicker from '../../components/inputs/CustomTimePicker';

const styles = {
  paper: {
    width: '100%',
    minWidth: '375px',
    textAlign: 'center',
    display: 'inline-block',
    paddingBottom: 50,
    paddingTop: 50,
  },
  formControl: {
    minWidth: 250,
  },
  input: {
    minWidth: 250,
  },
};

const COMPANY_OWNER_NOT_SELECTED = -1;

function RegistrationWithLink() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    emailAddress: '',
    phoneNumber: '',
    username: '',
    // odavde
    clientId: -1,
    companyName: '',
    companyAddress: '',
    companyTypeOfBusiness: '',
    ceoFirstName: '',
    ceoLastName: '',
    ceoPhoneNumber: '',
    ceoEmail: '',
    // novi parametri
    createdByID: -1,
    adminClientCompanyID: -1,
    token: '',

    workDayMonday: false,
    workDayTuesday: false,
    workDayWednesday: false,
    workDayThursday: false,
    workDayFriday: false,
    workDaySaturday: false,
    workDaySunday: false,

    workDayMondayFrom: null,
    workDayMondayTo: null,
    workDayTuesdayFrom: null,
    workDayTuesdayTo: null,
    workDayWednesdayFrom: null,
    workDayWednesdayTo: null,
    workDayThursdayFrom: null,
    workDayThursdayTo: null,
    workDayFridayFrom: null,
    workDayFridayTo: null,
    workDaySaturdayFrom: null,
    workDaySaturdayTo: null,
    workDaySundayFrom: null,
    workDaySundayTo: null,
    adminClientOwnerId: COMPANY_OWNER_NOT_SELECTED,

    acceptAgreement: false,
    role: 0,

    errors: {},
    loading: true,
    message: '',
    snackbarOppen: false,
  });

  const worktimeActiveHintStyle = '#212121';

  const location = useLocation();
  let history = useHistory();
  const query = new URLSearchParams(location.search);
  const cleanToken = query.get('token');

  // API CALL - check token
  useEffect(() => {
    (async () => {
      try {
        const res = await checkToken(cleanToken);
        if (!res.Success) {
          setFormData({
            ...formData,
            message: res.Message,
            snackbarOppen: true,
            loading: false,
          });
          return;
        }

        setFormData({
          ...formData,
          emailAddress: res.Result.email,
          clientId: res.Result.clientID,
          createdByID: res.Result.createdByID,
          adminClientCompanyID: res.Result.adminClientCompanyID,
          role: res.Result.role,
          token: cleanToken,
          loading: false,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onToggle = (e, iv) => {
    setFormData({ ...formData, [e.target.name]: iv });
  };

  const handleSubmit = () => {
    setFormData({ ...formData, message: '', snackbarOppen: false });
    validate(formData.role).then(({ valid }) => {
      if (valid) {
        setFormData({ ...formData, errors: {} });
        registerUserWithLink({
          AdminClientCompanyId: formData.adminClientCompanyID,
          CreatedByID: formData.createdByID,
          Token: formData.token,
          User_UserTypeID: formData.role,
          User_Username: formData.username,
          User_FirstName: formData.firstname,
          User_LastName: formData.lastname,
          User_PhoneNumber: formData.phoneNumber,
          User_Email: formData.emailAddress,
          Guest_ClientId: formData.clientId,
          AdminClient_UserId:
            formData.adminClientOwnerId === COMPANY_OWNER_NOT_SELECTED
              ? null
              : formData.adminClientOwnerId,
          Company_Name: formData.companyName,
          Company_Address: formData.companyAddress,
          Company_TypeOfBusiness: formData.companyTypeOfBusiness,
          CEO_FirstName: formData.ceoFirstName,
          CEO_LastName: formData.ceoLastName,
          CEO_PhoneNumber: formData.ceoPhoneNumber,
          CEO_Email: formData.ceoEmail,
          WorkDay_Monday: formData.workDayMonday,
          WorkDay_Tuesday: formData.workDayTuesday,
          WorkDay_Wednesday: formData.workDayWednesday,
          WorkDay_Thursday: formData.workDayThursday,
          WorkDay_Friday: formData.workDayFriday,
          WorkDay_Saturday: formData.workDaySaturday,
          WorkDay_Sunday: formData.workDaySunday,
          WorkHours_Monday_From: getTimeStringFromDateString(
            formData.workDayMondayFrom
          ),
          WorkHours_Monday_To: getTimeStringFromDateString(
            formData.workDayMondayTo
          ),
          WorkHours_Tuesday_From: getTimeStringFromDateString(
            formData.workDayTuesdayFrom
          ),
          WorkHours_Tuesday_To: getTimeStringFromDateString(
            formData.workDayTuesdayTo
          ),
          WorkHours_Wednesday_From: getTimeStringFromDateString(
            formData.workDayWednesdayFrom
          ),
          WorkHours_Wednesday_To: getTimeStringFromDateString(
            formData.workDayWednesdayTo
          ),
          WorkHours_Thursday_From: getTimeStringFromDateString(
            formData.workDayThursdayFrom
          ),
          WorkHours_Thursday_To: getTimeStringFromDateString(
            formData.workDayThursdayTo
          ),
          WorkHours_Friday_From: getTimeStringFromDateString(
            formData.workDayFridayFrom
          ),
          WorkHours_Friday_To: getTimeStringFromDateString(
            formData.workDayFridayTo
          ),
          WorkHours_Saturday_From: getTimeStringFromDateString(
            formData.workDaySaturdayFrom
          ),
          WorkHours_Saturday_To: getTimeStringFromDateString(
            formData.workDaySaturdayTo
          ),
          WorkHours_Sunday_From: getTimeStringFromDateString(
            formData.workDaySundayFrom
          ),
          WorkHours_Sunday_To: getTimeStringFromDateString(
            formData.workDaySundayTo
          ),
        })
          .then(async (data) => {
            if (data.StatusCode === 400) {
              setFormData({
                ...formData,
                message: data.Message,
                snackbarOppen: true,
                loading: false,
              });
            } else {
              setFormData({
                ...formData,
                message: data.Message,
                snackbarOppen: true,
                loading: false,
              });
              setTimeout(() => {
                history.push('/login');
              }, 2500);
            }
          })
          .catch((error) => {
            setFormData({
              message: error,
              snackbarOppen: true,
              loading: false,
            });
          });
      }
    });
  };

  const validate = async (role) => {
    var errorsFinal;
    var result;
    var valid;

    const validateUser = [2, 3, 4, 5];
    const validateCompany = [3];
    const validateWorkingHours = [3];

    if (validateUser.includes(role)) {
      result = await validatePersonalInfo(formData, false, false, true);
      if (!result.valid) {
        errorsFinal = Object.assign(result.errors, errorsFinal);
      }
    }

    if (validateCompany.includes(role)) {
      result = validateCompanyInfo(formData);
      if (!result.valid) {
        errorsFinal = Object.assign(result.errors, errorsFinal);
      }
    }

    if (validateWorkingHours.includes(role)) {
      result = validateUserCompanyWorkingHours(formData);
      if (!result.valid) {
        errorsFinal = Object.assign(result.errors, errorsFinal);
      }
    }

    valid = isEmpty(errorsFinal);
    if (!valid) {
      setFormData({ ...formData, errors: errorsFinal });
    }

    return { valid };
  };

  const onSnackbarClose = () => {
    setFormData({ ...formData, snackbarOppen: false, message: '' });
  };

  registerDataLayer(newDataLayer('Registration With Link'));

  return !formData.loading ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '100px 0',
      }}
    >
      <form style={{ display: 'inline-block' }}>
        {formData.role ? (
          <Paper style={styles.paper}>
            <div>
              <h2>User info</h2>
              <TextField
                helperText="Required *"
                name="firstname"
                placeholder="First name"
                value={formData.firstname}
                error={formData.errors?.firstname}
                onChange={handleChange}
                style={styles.input}
                size="small"
                margin="normal"
              />
              <br />
              <TextField
                name="lastname"
                placeholder="Last name"
                value={formData.lastname}
                error={formData.errors?.lastname}
                helperText="Required *"
                onChange={handleChange}
                style={styles.input}
                size="small"
                margin="normal"
              />
              <br />
              <TextField
                name="phoneNumber"
                type="number"
                placeholder="Phone number"
                value={formData.phoneNumber}
                error={formData.errors?.phoneNumber}
                helperText="Required *"
                onChange={handleChange}
                style={styles.input}
                size="small"
                margin="normal"
              />
              <br />
              <br />
              <TextField
                name="username"
                placeholder="Username"
                value={formData.username}
                error={formData.errors?.username}
                helperText="Required *"
                onChange={handleChange}
                style={styles.input}
                size="small"
                margin="normal"
              />
            </div>

            {formData.role === 3 ? (
              <>
                <br />
                <div>
                  <h3>Company info</h3>
                  <TextField
                    name="companyName"
                    placeholder="Company name"
                    value={formData.companyName}
                    error={formData.errors?.companyName}
                    onChange={handleChange}
                    style={styles.input}
                    size="small"
                    margin="normal"
                  />
                  <br />

                  <TextField
                    name="companyAddress"
                    placeholder="Company address"
                    value={formData.companyAddress}
                    error={formData.errors?.companyAddress}
                    onChange={handleChange}
                    style={styles.input}
                    size="small"
                    margin="normal"
                  />
                  <br />

                  <TextField
                    name="companyTypeOfBusiness"
                    placeholder="Company type of business"
                    multiline={true}
                    rows={1}
                    rowsMax={6}
                    value={formData.companyTypeOfBusiness}
                    error={formData.errors?.companyTypeOfBusiness}
                    onChange={handleChange}
                    style={styles.input}
                    size="small"
                    margin="normal"
                  />
                  <br />
                  <br />
                  <br />

                  <TextField
                    name="ceoFirstName"
                    placeholder="CEO First Name"
                    value={formData.ceoFirstName}
                    error={formData.errors?.ceoFirstName}
                    helperText={formData.errors?.ceoFirstName}
                    onChange={handleChange}
                    style={styles.input}
                    size="small"
                    margin="normal"
                  />
                  <br />

                  <TextField
                    name="ceoLastName"
                    placeholder="CEO Last Name"
                    value={formData.ceoLastName}
                    error={formData.errors?.ceoLastName}
                    helperText={formData.errors?.ceoLastName}
                    onChange={handleChange}
                    style={styles.input}
                    size="small"
                    margin="normal"
                  />
                  <br />

                  <TextField
                    name="ceoPhoneNumber"
                    placeholder="CEO Phone Number"
                    value={formData.ceoPhoneNumber}
                    error={formData.errors?.ceoPhoneNumber}
                    helperText={formData.errors?.ceoPhoneNumber}
                    onChange={handleChange}
                    style={styles.input}
                    size="small"
                    margin="normal"
                  />
                  <br />

                  <TextField
                    name="ceoEmail"
                    placeholder="CEO Email Address"
                    value={formData.ceoEmail}
                    error={formData.errors?.ceoEmail}
                    helperText={formData.errors?.ceoEmail}
                    onChange={handleChange}
                    style={styles.input}
                    size="small"
                    margin="normal"
                  />
                  <br />
                  <br />
                  <br />

                  <h3>Working days</h3>
                  <Grid container>
                    <Grid item xl={10} md={10} sm={10} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item sm={7} xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="workDayMonday"
                                value={formData.workDayMonday}
                                checked={formData.workDayMonday}
                                onChange={onToggle}
                                color="primary"
                              />
                            }
                            label="Monday"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item sm={1} xs={2} />
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayMondayFrom"
                            placeholder="Start hours"
                            value={formData.workDayMondayFrom}
                            disabled={!formData.workDayMonday}
                            hintStyle={
                              formData.workDayMonday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayMondayFrom}
                            error={formData.errors?.workDayMondayFrom}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayMondayTo"
                            value={formData.workDayMondayTo}
                            placeholder="End hours"
                            disabled={!formData.workDayMonday}
                            hintStyle={
                              formData.workDayMonday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayMondayTo}
                            error={formData.errors?.workDayMondayTo}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item sm={7} xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="workDayTuesday"
                                value={formData.workDayTuesday}
                                checked={formData.workDayTuesday}
                                onChange={onToggle}
                                color="primary"
                              />
                            }
                            label="Tuesday"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item sm={1} xs={2} />
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayTuesdayFrom"
                            placeholder="Start hours"
                            value={formData.workDayTuesdayFrom}
                            disabled={!formData.workDayTuesday}
                            hintStyle={
                              formData.workDayTuesday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayTuesdayFrom}
                            error={formData.errors?.workDayTuesdayFrom}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayTuesdayTo"
                            value={formData.workDayTuesdayTo}
                            placeholder="End hours"
                            disabled={!formData.workDayTuesday}
                            hintStyle={
                              formData.workDayTuesday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayTuesdayTo}
                            error={formData.errors?.workDayTuesdayTo}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item sm={7} xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="workDayWednesday"
                                value={formData.workDayWednesday}
                                checked={formData.workDayWednesday}
                                onChange={onToggle}
                                color="primary"
                              />
                            }
                            label="Wednesday"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item sm={1} xs={2} />
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayWednesdayFrom"
                            placeholder="Start hours"
                            value={formData.workDayWednesdayFrom}
                            disabled={!formData.workDayWednesday}
                            hintStyle={
                              formData.workDayWednesday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayWednesdayFrom}
                            error={formData.errors?.workDayWednesdayFrom}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayWednesdayTo"
                            value={formData.workDayWednesdayTo}
                            placeholder="End hours"
                            disabled={!formData.workDayWednesday}
                            hintStyle={
                              formData.workDayWednesday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayWednesdayTo}
                            error={formData.errors?.workDayWednesdayTo}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item sm={7} xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="workDayThursday"
                                value={formData.workDayThursday}
                                checked={formData.workDayThursday}
                                onChange={onToggle}
                                color="primary"
                              />
                            }
                            label="Thursday"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item sm={1} xs={2} />
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayThursdayFrom"
                            placeholder="Start hours"
                            value={formData.workDayThursdayFrom}
                            disabled={!formData.workDayThursday}
                            hintStyle={
                              formData.workDayThursday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayThursdayFrom}
                            error={formData.errors?.workDayThursdayFrom}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayThursdayTo"
                            value={formData.workDayThursdayTo}
                            placeholder="End hours"
                            disabled={!formData.workDayThursday}
                            hintStyle={
                              formData.workDayThursday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayThursdayTo}
                            error={formData.errors?.workDayThursdayTo}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item sm={7} xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="workDayFriday"
                                value={formData.workDayFriday}
                                checked={formData.workDayFriday}
                                onChange={onToggle}
                                color="primary"
                              />
                            }
                            label="Friday"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item sm={1} xs={2} />
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayFridayFrom"
                            placeholder="Start hours"
                            value={formData.workDayFridayFrom}
                            disabled={!formData.workDayFriday}
                            hintStyle={
                              formData.workDayFriday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayFridayFrom}
                            error={formData.errors?.workDayFridayFrom}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDayFridayTo"
                            value={formData.workDayFridayTo}
                            placeholder="End hours"
                            disabled={!formData.workDayFriday}
                            hintStyle={
                              formData.workDayFriday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDayFridayTo}
                            error={formData.errors?.workDayFridayTo}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item sm={7} xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="workDaySaturday"
                                value={formData.workDaySaturday}
                                checked={formData.workDaySaturday}
                                onChange={onToggle}
                                color="primary"
                              />
                            }
                            label="Saturday"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item sm={1} xs={2} />
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDaySaturdayFrom"
                            placeholder="Start hours"
                            value={formData.workDaySaturdayFrom}
                            disabled={!formData.workDaySaturday}
                            hintStyle={
                              formData.workDaySaturday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDaySaturdayFrom}
                            error={formData.errors?.workDaySaturdayFrom}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDaySaturdayTo"
                            value={formData.workDaySaturdayTo}
                            placeholder="End hours"
                            disabled={!formData.workDaySaturday}
                            hintStyle={
                              formData.workDaySaturday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDaySaturdayTo}
                            error={formData.errors?.workDaySaturdayTo}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item sm={7} xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="workDaySunday"
                                value={formData.workDaySunday}
                                checked={formData.workDaySunday}
                                onChange={onToggle}
                                color="primary"
                              />
                            }
                            label="Sunday"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item sm={1} xs={2} />
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDaySundayFrom"
                            placeholder="Start hours"
                            value={formData.workDaySundayFrom}
                            disabled={!formData.workDaySunday}
                            hintStyle={
                              formData.workDaySunday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDaySundayFrom}
                            error={formData.errors?.workDaySundayFrom}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                        <Grid item sm={2} xs={5}>
                          <CustomTimePicker
                            name="workDaySundayTo"
                            value={formData.workDaySundayTo}
                            placeholder="End hours"
                            disabled={!formData.workDaySunday}
                            hintStyle={
                              formData.workDaySunday
                                ? { color: worktimeActiveHintStyle }
                                : {}
                            }
                            helperText={formData.errors?.workDaySundayTo}
                            error={formData.errors?.workDaySundayTo}
                            onTimeChange={onToggle}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              <></>
            )}

            <>
              <br />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                type="button"
              >
                Create
              </Button>
            </>
          </Paper>
        ) : (
          <></>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={formData.snackbarOppen}
          message={formData.message}
          autoHideDuration={2500}
          onClose={onSnackbarClose}
        />
      </form>
    </div>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default RegistrationWithLink;
