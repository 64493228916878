export const ADMIN_CLIENT_COMPANY_ID_NOT_SELECTED = null;

const initialState = {
  clientId: null,
  assignAssistantOpen: false,
  assignAssistantId: null,
  clients: [],
  assistants: [],
  allAssistants: [],
  adminClientsCompanyOwners: [],
  adminClientCompanyId: ADMIN_CLIENT_COMPANY_ID_NOT_SELECTED,
};

export default function coworkersReducer(state = initialState, action) {
  switch (action.type) {
    case 'COWORKERS_SET_STATE':
      return {
        ...state,
        ...action.payload,
      };

    case 'COWORKERS_GET_COMPANY_OWNERS_FULFILLED':
      return {
        ...state,
        adminClientsCompanyOwners: action.payload,
      };

    case 'GET_CLIENTS_FULFILLED':
      return {
        ...state,
        clients: action.payload,
      };

    case 'GET_CLIENTS_FOR_ADMIN_CLIENT_COMPANY_FULFILLED':
      return {
        ...state,
        clients: action.payload,
      };

    case 'GET_COWORKERS_FOR_CLIENT_FULFILLED':
      return {
        ...state,
        assistants: action.payload,
      };

    case 'GET_AVAILABLE_COWORKERS_FULFILLED':
      return {
        ...state,
        allAssistants: action.payload,
      };

    case 'ASSIGN_COWORKER_TO_CLIENT_FULFILLED':
      return {
        ...state,
        assistants: action.payload,
        assignAssistantOpen: false,
        allAssistants: state.allAssistants.filter(
          (el) => el.userId !== state.assignAssistantId
        ),
        assignAssistantId: null,
      };

    case 'UNASSIGN_COWORKER_FROM_CLIENT_FULFILLED':
      return {
        ...state,
        assistants: action.payload,
      };

    default:
      return state;
  }
}
