import { Backdrop } from '@material-ui/core';
import { PureComponent } from 'react';

/**
 * Prevents scrolling of content behind the backdrop.
 */
export default class BackDropIOSWorkaround extends PureComponent {
  onTouchMove = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <Backdrop
        {...this.props}
        onTouchMove={this.onTouchMove}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.30)' }}
      />
    );
  }
}
