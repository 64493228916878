import { newDataLayer, registerDataLayer } from '../../utilities/google-tools';

import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { UserActivityStatus } from '../../common/enum';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logUserActivity } from '../../api/api-functions';
import { logout } from '../../actions/authentication-actions';
import { setAuthorizationToken } from '../../utilities/api-helper';

class Logout extends Component {
  componentWillMount() {
    this.props.logout();
    logUserActivity(UserActivityStatus.Logout);
    setAuthorizationToken(null);
    localStorage.clear();
    registerDataLayer(newDataLayer('Logout'))
  }

  render() {
    if (this.props.match.params.autoLogout === true)
      return <Redirect to={'/Login/' + this.props.match.params.autoLogout} />;
    else return <Redirect to={'/Login'} />;
  }
}

function matchDispatchToProps(dispach) {
  return bindActionCreators({ logout: logout }, dispach);
}

export default connect(null, matchDispatchToProps)(Logout);
