import ReactGA from 'react-ga4'
import TagManager from "react-gtm-module";

export const initTag = (tag) => {
    if (!tag) {
        return
    }
    const tagManagerArgs = {
        gtmId: tag,
    }
    
    TagManager.initialize(tagManagerArgs)
}

export const initGA = () => {
    if (!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        return
    }

    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

export const newDataLayer = (dataLayerName, dataLayer) => {
    let tagManagerArgs = {};

    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
        tagManagerArgs = {
            dataLayer,
            dataLayerName,
        }
    }

    return tagManagerArgs;
}

export const registerDataLayer = (args) => {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
        TagManager.dataLayer(args);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: args.dataLayerName });
    }
}