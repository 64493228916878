import { ApiError, LogActivityError } from '../common/ApiError';

import { LoginError } from './api-functions';
import { debug } from '../utilities/debug-helper';
import isPromise from 'is-promise';
import { postErrorMessageNotistack } from '../actions/notistack-dispatcher-actions';

export const catchErrorMiddleware = (store) => (next) => (action) => {
  if (!isPromise(action?.payload)) {
    return next(action);
  } else {
    return next(action).catch((error) => {
      if (error instanceof LogActivityError) {
        debug.warn(
          'Error middleware - Activity Log api call error: ',
          error.getMessage(),
          error.getStatusCode()
        );
      } else if (error instanceof ApiError) {
        debug.log(
          'Error middleware - Api error catched',
          error.getMessage(),
          error.getStatusCode()
        );
        debug.dir(error);

        if (error.getStatusCode() === 401) {
          store.dispatch(postErrorMessageNotistack(error.getMessage(), true));
        } else {
          store.dispatch(postErrorMessageNotistack(error.getMessage()));
        }
      } else if (error instanceof LoginError) {
        store.dispatch(postErrorMessageNotistack(error.message));
      } else {
        store.dispatch(
          postErrorMessageNotistack(
            error instanceof Error ? error.message : error
          )
        );
        debug.log('Error middleware - catched unknwon error', error);
      }
    });
  } //endif not primise
};
