import './ProductsStripe.css';

import Select from 'react-select';
import countries from './countries';
import states from './states';
import styled from '@emotion/styled';

const FormFieldContainer = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;

  &:first-of-type {
    border-top: none;
  }
`;

const Label = styled.label`
  min-width: 70px;
  padding: 15px 0;
  color: black;
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  padding: 15px 15px 15px 8px;
  color: black;
  background-color: transparent;
  animation: 1ms void-animation-out;
  border: none;
  border-bottom: 1px solid gainsboro;
  border-left: 1px solid gainsboro;

  &::placeholder {
    color: silver;
  }

  &:focus {
    outline: none;
    border-bottom: 3px solid #5bc0de;
    padding-bottom: 13px;
  }
`;

const FormField = ({ label, type, name, placeholder }) => {
  return (
    <FormFieldContainer>
      <Label htmlFor={name}>{label}:</Label>
      <Input name={name} type={type} placeholder={placeholder} required />
    </FormFieldContainer>
  );
};

const FormDropdown = ({
  label,
  name,
  placeholder,
  selectedOption,
  onChange,
  type,
  disabled = false,
}) => {
  const options = type === 'countries' ? countries : states;

  const colourStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#5bc0de' : null,
        color: isFocused ? 'white' : '#333333',
      };
    },
  };

  return (
    // <FormFieldContainer>
    //   <Label htmlFor={name}>{label}:</Label>
    <Select
      value={selectedOption}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      className={'checkout_dropdown'}
      styles={colourStyles}
      isDisabled={disabled}
    />
    // </FormFieldContainer >
  );
};

export { FormField, FormDropdown };
