import React from 'react';
import { Label } from '../../../components/Label';
import { Row } from '../../../utilities/constants';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
} from '@material-ui/core';

const InvoicePreview = (props) => {
  const {
    previewInvoice,
    paymentCoupon,
    onChangeCoupon,
    couponErrorMessage,
    onApplyPaymentCoupon,
    total,
  } = props;

  const getPrice = (amount) => {
    return (amount / 100).toFixed(2);
  };

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className="checkout_table_head">
            <TableRow>
              <TableCell>Package/Service</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Tax</TableCell>
              <TableCell>Coupon</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previewInvoice.map((invoice, index) => {
              return (
                <TableRow key={index + 1}>
                  <TableCell>{invoice.description}</TableCell>
                  <TableCell>${getPrice(invoice.amount)}</TableCell>
                  <TableCell>${getPrice(invoice.taxes)}</TableCell>
                  <TableCell>${getPrice(invoice.discounts)}</TableCell>
                  <TableCell>
                    $
                    {getPrice(
                      invoice.amount + invoice.taxes - invoice.discounts
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={5}>
                <div className="flex flex-row w-full items-center">
                  <div className="flex flex-col w-1/4 pl-[20px]">
                    <Label text="Coupon" />
                  </div>
                  <div className="flex flex-col w-2/4">
                    <TextField
                      name="paymentCoupon"
                      placeholder="Coupon"
                      value={paymentCoupon}
                      onChange={onChangeCoupon}
                      error={couponErrorMessage ? true : false}
                      helperText={couponErrorMessage}
                      className="w-full max-w-[500px]"
                      size="small"
                      margin="normal"
                      disabled={!total?.total}
                    />
                  </div>
                  <div className="flex flex-row w-1/4 justify-center">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!total?.total}
                      onClick={() => onApplyPaymentCoupon()}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow
              key={0}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" className="total_row">
                Summary
              </TableCell>
              <TableCell className="total_row">
                ${getPrice(total.subtotal)}
              </TableCell>
              <TableCell className="total_row">
                ${getPrice(total.tax)}
              </TableCell>
              <TableCell className="total_row">
                ${getPrice(total.totalDiscount)}
              </TableCell>
              <TableCell className="total_row">
                ${getPrice(total.total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default InvoicePreview;
