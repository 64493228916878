const labelStyles = {
  labelContainer: {},
  labelInner: {},
  labelMobile: {
    fontSize: '10pt',
  },
};

//When FormControlLabel with control attribute doesnt do the job this is used instead
export function Label(props) {
  return (
    <label>
      <div
        style={
          props.isDesktop ? labelStyles.labelInner : labelStyles.labelMobile
        }
      >
        {props.text}
      </div>
    </label>
  );
}
