import { format } from 'date-fns';
import { getDefaultTaskFilter } from '../components/TaskFilter/TaskFilterHelper';

const initState = {
  loading: false,
  tasks: [],
  filters: getDefaultTaskFilter(),
  filtersChanged: false,
};

const compareArrays = (array1, array2) => {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
};

const TasksReducer = function (state = initState, action) {
  switch (action.type) {
    case 'TASKS_SET_SEARCH_TEXT': {
      return {
        ...state,
        filters: { ...state.filters, titleFilter: action.searchText },
      };
    }

    case 'GET_TASKS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_TASKS_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_TASKS_FULFILLED': {
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      };
    }
    case 'GET_GUEST_AVAILABLE_TASKS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_GUEST_AVAILABLE_TASKS_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_GUEST_AVAILABLE_TASKS_FULFILLED': {
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      };
    }

    case 'ADD_GUEST_TO_TASK_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'ADD_GUEST_TO_TASK_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'ADD_GUEST_TO_TASK_FULFILLED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'SET_FILTERS': {
      const defValues = getDefaultTaskFilter();
      const realValues = action.payload;
      const diff =
        defValues.titleFilter === realValues.titleFilter &&
        compareArrays(
          defValues.adminClientCompanyOwners,
          realValues.adminClientCompanyOwners
        ) &&
        compareArrays(defValues.statuses, realValues.statuses) &&
        compareArrays(defValues.clients, realValues.clients) &&
        compareArrays(defValues.assignees, realValues.assignees) &&
        format(new Date(defValues.creationDateFrom), 'y-MM-dd') ===
          format(new Date(realValues.creationDateFrom), 'y-MM-dd') &&
        format(new Date(defValues.creationDateTo), 'y-MM-dd') ===
          format(new Date(realValues.creationDateTo), 'y-MM-dd');

      return {
        ...state,
        filters: action.payload,
        filtersChanged: !diff,
      };
    }

    case 'RESET_FILTERS': {
      return {
        ...state,
        filters: getDefaultTaskFilter(),
        filtersChanged: false,
      };
    }

    case 'TASKS_UNSUBSCRIBE_TASK_FROM_NOTIFICATIONS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'TASKS_UNSUBSCRIBE_TASK_FROM_NOTIFICATIONS_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'TASKS_UNSUBSCRIBE_TASK_FROM_NOTIFICATIONS_FULFILLED': {
      const { tasks } = state;
      const task = tasks.find((f) => f.taskId == action.payload.taskId);
      const index = tasks.indexOf(task);
      tasks[index].subscribedToPushNotifications = false;

      return {
        ...state,
        tasks,
        loading: false,
      };
    }

    case 'TASKS_SUBSCRIBE_TASK_TO_NOTIFICATIONS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'TASKS_SUBSCRIBE_TASK_TO_NOTIFICATIONS_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'TASKS_SUBSCRIBE_TASK_TO_NOTIFICATIONS_FULFILLED': {
      const { tasks } = state;
      const task = tasks.find((f) => f.taskId == action.payload.taskId);
      const index = tasks.indexOf(task);
      tasks[index].subscribedToPushNotifications = true;

      return {
        ...state,
        tasks: tasks,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default TasksReducer;
