import './login.css';

import { Button, Paper, TextField } from '@material-ui/core';
import { Component, Fragment } from 'react';

import Footer from '../../components/Footer';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import LoginUnsubscribedUser from './LoginUnsubscribedUser';
import ReactGA from 'react-ga4'
import { authenticate } from '../../actions/authentication-actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateAuthenticateInputs } from '../../utilities/validations/authenticate-validations';
import vcLogo from './../../assets/wwa-logo.svg';
import withWidth from '@material-ui/core/withWidth';

const errorStyle = {
  width: '444px',
  padding: '18px',
  textAlign: 'center',
  display: 'inline-block',
  backgroundColor: 'red',
  color: 'white',
};
const errorStyleMobile = {
  width: '100%',
  padding: '18px',
  marginLeft: '-18px',
  textAlign: 'center',
  display: 'inline-block',
  backgroundColor: 'red',
  color: 'white',
};

const loginForm = {
  width: '100%',
};

const textField = {
  width: '80%',
  minWidth: '250px',
  maxWidth: '300px',
};

const logoStyle = {
  textAlign: 'center',
  display: 'inline-block',
};

const forgetPasswordStyle = {
  textDecoration: 'none',
  color: 'rgb(64, 180, 229)',
  ':hover': {
    color: 'solid black',
  },
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.props.user.errors = {};
    this.state = {
      username: '',
      password: '',
      forgotPassword: false,
      apiValidation: false,
      goToPayment: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.props.loginBlocked) {
      return;
    }

    const { valid } = validateAuthenticateInputs(this.state);
    localStorage.removeItem('customer');

    if (!valid) {
      this.setState({ ...this.state, apiValidation: valid });
    } else {
      this.props.authenticate(this.state.username, this.state.password);
      this.setState({ apiValidation: valid });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  renderDesktop() {
    return (
      <Fragment>
        <div
          style={{
            padding: '40px',
            width: '100%',
            height: '100%',
            textAlign: 'center',
          }}
        >
          {this.props.match.params.logout === 'true' ? (
            <Paper style={errorStyle}>Your token has expired</Paper>
          ) : null}
        </div>
        <div className="login-wrapper-desktop">
          <Paper className="login-wrapper-desktop-paper" zdepth={2}>
            {this.renderControls()}
          </Paper>
        </div>
      </Fragment>
    );
  }

  renderMobile() {
    return (
      <Fragment>
        <div style={{ padding: '40px', width: '100%' }}>
          {this.props.match.params.logout === 'true' ? (
            <Paper style={errorStyleMobile}>Your token has expired</Paper>
          ) : null}
        </div>
        <div className="login-wrapper-mobile">{this.renderControls()}</div>
      </Fragment>
    );
  }

  renderControls() {
    const { username, password } = this.state;
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div style={logoStyle}>
          <img
            src={vcLogo}
            style={{ float: 'left', width: '250px' }}
            alt="VirtualAssistant"
          />
        </div>
        <form onSubmit={this.onSubmit} style={loginForm}>
          <TextField
            id="username"
            value={username}
            error={!!this.props.user.errors.username}
            helperText={this.props.user.errors.username}
            onChange={this.onChange}
            label="Username or email"
            style={textField}
            autoComplete="off"
          />
          <br />
          <TextField
            id="password"
            value={password}
            error={!!this.props.user.errors.password}
            helperText={this.props.user.errors.password}
            onChange={this.onChange}
            type="password"
            label="Password"
            style={textField}
          />
          <br />
          <br />
          <a href="/ForgotPassword" style={forgetPasswordStyle}>
            <span style={{ fontSize: '12px' }}>Forgot password?</span>
          </a>
          <br />
          <br />
          <Button
            type="submit"
            label="Login"
            variant="contained"
            color="primary"
            style={{ color: 'white', textTransform: 'none' }}
            disabled={this.props.user.loading || this.props.loginBlocked}
          >
            Login
          </Button>
          <Link to="/Register">
            <Button style={{ textDecoration: 'none !important', textTransform: 'none' }} onClick={() => {
              gtag('event', 'conversion', { 'send_to': 'AW-11423556295/n8njCM-0h5kZEMfFlscq' });
            }}>
              Sign up
            </Button>
          </Link>
          <div className="flex flex-row justify-center items-center gap-x-2 mt-8">
            <img
              className="h-[33px] cursor-pointer"
              src="/images/icons/appstore.svg"
              alt="Apple App Store"
              onClick={() => window.open("https://apps.apple.com/us/app/wwa/id1515570024", "_blank")}
            />
            <img
              className="h-[33px] cursor-pointer"
              src="/images/icons/playstore.svg"
              alt="Google Play Store"
              onClick={() => window.open("https://play.google.com/store/apps/details?id=com.wwa.worldwideassist", "_blank")}
            />
          </div>
          <br />
          <br />
        </form>
      </div>
    );
  }

  render() {
    if (this.props.user.loggedUser.username != null) {
      const loggedUser = this.props.user.loggedUser;
      const navigateToTasks =
        loggedUser.isClient ||
        loggedUser.isAssistant ||
        loggedUser.isGuest ||
        loggedUser.isAdminClient;

      if (navigateToTasks) {
        this.props.history.push('/tasks/' + this.props.user.loggedUser.userId);
      } else {
        this.props.history.push('/');
      }
    }

    return (
      <>
        {this.props.user.subscribed === false ? (
          <>
            <LoginUnsubscribedUser />
          </>
        ) : (
          <>
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              {this.props.width !== 'xs'
                ? this.renderDesktop()
                : this.renderMobile()}
            </Grid>
            <Grid className='fixed md:bottom-[30px] md:right-[30px] bottom-5 right-4'>
              <h5 className='my-2'>Powered by </h5>
              <Footer />
            </Grid>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ...ownProps,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ authenticate: authenticate }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withWidth()(Login));
