import Validator from 'validator';
import isEmpty from 'is-empty';

export function validateAuthenticateInputs(data) {
  const errors = {};

  if (Validator.isEmpty(data.username)) {
    errors.username = 'Username is requried';
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = 'Password is requried';
  }

  return {
    errors,
    valid: isEmpty(errors),
  };
}

export function validateRecoveryEmail(data) {
  const errors = {};

  if (Validator.isEmpty(data.email)) {
    errors.email = 'Recovery address is required!';
  } else if (!Validator.isEmail(data.email)) {
    errors.email = 'Must enter valid email!';
  }

  return {
    errors,
    valid: isEmpty(errors),
  };
}
