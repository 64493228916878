const DEBUG_LOG_ENABLED = process.env.REACT_APP_ENABLE_DEBUG_LOG;
const DEBUG_MESSAGE_PREFIX = '[DEBUG MESSAGE]';
const DEBUG_MESSAGE_DEFAULT_NAMESPACE = '[GENERAL]';

export function logDebugMessage(
  debugMessage,
  debugMessageNamespace = DEBUG_MESSAGE_DEFAULT_NAMESPACE
) {
  if (DEBUG_LOG_ENABLED) {
    console.log(
      `${DEBUG_MESSAGE_PREFIX} ${debugMessageNamespace} ${debugMessage}`
    );
  }
}

function log(...args) {
  if (DEBUG_LOG_ENABLED) {
    console.log(...args);
  }
}

function warn(...args) {
  if (DEBUG_LOG_ENABLED) {
    console.warn(...args);
  }
}

function dir(...args) {
  if (DEBUG_LOG_ENABLED) {
    console.dir(...args);
  }
}

export const debug = {
  log,
  warn,
  dir,
};
