const initState = {
  loading: false,
  taskNotification: '',
  taskCreated: false,
  taskId: null,
  title: '',
  clients: [],
  assistants: [],
  assignee: null,
  client: null,
};

const NewTasksReducer = function (state = initState, action) {
  switch (action.type) {
    case 'GET_CLIENTS_FULFILLED': {
      return {};
    }
    case 'CREATE_TASK_PENDING': {
      return {
        ...state,
        loading: true,
        taskCreated: false,
      };
    }

    case 'CREATE_TASK_FULFILLED': {
      return {
        ...state,
        loading: false,
        taskId: action.payload,
        taskCreated: true,
      };
    }
    case 'CREATE_TASK_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'FLUSH_CREATED_TASK': {
      return {
        ...state,
        loading: false,
        taskNotification: '',
        taskCreated: false,
        selectedTask: null,
        taskId: null,
      };
    }

    default:
      return state;
  }
};

export default NewTasksReducer;
