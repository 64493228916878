import { applyMiddleware, createStore } from 'redux';

import Reducers from './reducers/reducers';
import api_calls from './api/api-middleware';
import { catchErrorMiddleware } from './api/api-error-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import promise from 'redux-promise-middleware';

const authInterceptor =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      action.payload !== undefined &&
      action.payload.response !== undefined &&
      action.payload.response.status === 401
    ) {
      window.location = '/Logout/true';
    } else {
      return next(action);
    }
  };

export default function configureStore() {
  let middleware = applyMiddleware(
    catchErrorMiddleware,
    promise,
    api_calls,
    authInterceptor
  );
  let store = createStore(Reducers, composeWithDevTools(middleware));
  let persistor = persistStore(store);
  return { persistor, store };
}
