import {
  CHECK_BILLING_DEFAULT,
  CHECK_BILLING_PENDING,
  FINISH_REGISTRATION,
  UNBLOCK_LOGIN,
} from '../actions/user-actions';
import {
  getDefaultUsersFilter,
  toActualRoledFullNamedUser,
  toLowerCasedUser,
} from '../components/User/utils';

import { REGEX_USERS_FILTERING } from '../common/appConstants';
import _ from 'lodash';

const initState = {
  loading: false,
  registrationSuccess: false,
  loggedUser: {},
  user: {},
  adminClientCompany: {},
  errors: {},
  users: [],
  guests: [],
  calendarUsers: [],
  phonebookUsers: [],
  adminClientOwnerUsers: [],
  otherAdminClientCompany: null,
  ownerActive: null,
  selectedAdminClientOwnerId: -1,
  filter: null,
  filterApplyed: false,
  searchText: '',
  finishRegistrationdSuccess: false,
  checkBilling: -1, // -2 -> error, -1 -> false, 0 -> pending, 1 -> true
  checkBillingErrorMessage: null,
  refreshOwnerSubscriptionsAndInvoices: false,
  blockedTime: undefined,
  loginBlocked: true,
  chatRooms: [],
  pendingInvoiceId: null,
};

const UserReducer = function (state = initState, action) {
  switch (action.type) {
    case 'SET_USERS_SEARCH_TEXT': {
      return {
        ...state,
        searchText: action.searchText,
      };
    }
    case 'SET_USERS_FILTER': {
      if (_.isEqual(state.filter, action.filter)) {
        return state;
      }

      return {
        ...state,
        filter: action.filter,
        filterApplyed: !_.isEqual(
          action.filter,
          getDefaultUsersFilter(state.loggedUser.role)
        ),
      };
    }

    case 'RESET_USER_STORE_STATE': {
      return resetUserStoreState(state);
    }

    case 'AUTHENTICATE_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'AUTHENTICATE_REJECTED': {
      return {
        ...state,
        //errors: { username: action.payload },
        loading: false,
        subscribed: null,
        blockedTime: action.payload?._data?.timeBlocked * 60 * 1000,
        loginBlocked: true,
      };
    }

    case UNBLOCK_LOGIN: {
      return {
        ...state,
        blockedTime: undefined,
        loginBlocked: false,
      };
    }

    case 'AUTHENTICATE_FULFILLED': {
      return {
        ...state,
        loggedUser: action.payload.subscribed
          ? {
              userId: action.payload.userId,
              username: action.payload.username,
              firstName: action.payload.firstName,
              lastName: action.payload.lastName,
              fullName:
                action.payload.firstName + ' ' + action.payload.lastName,
              avatar: action.payload.avatar,
              email: action.payload.email,
              phoneNumber: action.payload.phoneNumber,
              companyId: action.payload.companyId,
              companyOwner: action.payload.companyOwner,
              adminClientCompanyId: action.payload.adminClientCompanyId,
              adminClientCompany: action.payload.adminClientCompany,
              role: action.payload.role,
              isAdmin: action.payload.role === 'Admin',
              isAssistant: action.payload.role === 'Assistant',
              isClient: action.payload.role === 'Client',
              isGuest: action.payload.role === 'Guest',
              isAdminClient: action.payload.role === 'AdminClient',
              active: true,
              subscribed: action.payload.subscribed,
              firstLogin: action.payload.firstLogin,
            }
          : {},
        filter: action.payload.subscribed
          ? getDefaultUsersFilter(action.payload.role)
          : {},
        loading: false,
        subscribed: action.payload.subscribed,
        pendingInvoiceId: action.payload?.pendingInvoiceId,
      };
    }

    case 'SET_SUBSCRIBE': {
      return {
        ...state,
        subscribed: action.payload.subscribed,
      };
    }

    case CHECK_BILLING_PENDING: {
      return {
        ...state,
        checkBilling: 0,
      };
    }

    case CHECK_BILLING_DEFAULT: {
      return {
        ...state,
        checkBilling: -1,
      };
    }
    case 'REGISTER_REJECTED': {
      return {
        ...state,
        registrationSuccess: false,
      };
    }
    case 'REGISTER_FULFILLED': {
      return {
        ...state,
        registrationSuccess: true,
      };
    }
    case FINISH_REGISTRATION: {
      return {
        ...state,
        finishRegistrationdSuccess: action.payload,
      };
    }
    case 'REGISTER': {
      return {
        ...state,
        finishRegistrationdSuccess: action.payload,
      };
    }
    case 'LOGOUT': {
      return initState;
    }
    case 'SAVE_AVATAR_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'SAVE_AVATAR_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'SAVE_AVATAR_FULFILLED': {
      const tmpLoggedUser = state.loggedUser;
      tmpLoggedUser.avatar = action.payload;
      return {
        ...state,
        loggedUser: tmpLoggedUser,
        loading: false,
      };
    }
    case 'UPDATE_LOGGED_USER': {
      return {
        ...state,
        loggedUser: {
          ...action.user,
          isAdmin: action.user.role === 'Admin',
          isAssistant: action.user.role === 'Assistant',
          isClient: action.user.role === 'Client',
          isGuest: action.user.role === 'Guest',
          isAdminClient: action.user.role === 'AdminClient',
        },
        loading: false,
      };
    }
    case 'GET_USERS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_USERS_REJECTED': {
      return {
        ...state,
        loading: false,
        users: [],
      };
    }
    case 'GET_USERS_FULFILLED': {
      return {
        ...state,
        users: mapToFilterableUsers(mapUsers(action.payload)),
        loading: false,
      };
    }

    case 'GET_ADMIN_OWNER_CLIENTS_FULFILLED':
      return {
        ...state,
        users: action.payload,
      };

    case 'GET_USER_GUESTS_FULFILLED': {
      return {
        ...state,
        guests: action.payload,
        loading: false,
      };
    }
    case 'GET_CAL_USERS_FULFILLED': {
      return {
        ...state,
        calendarUsers: mapToFilterableUsers(action.payload),
        loading: false,
      };
    }

    case 'GET_CAL_USERS_REJECTED': {
      return {
        ...state,
        calendarUsers: [],
        loading: false,
      };
    }

    case 'GET_USER_BASE_FULFILLED': {
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    }

    case 'GET_USER_ADMIN_CLIENT_COMPANY_FULFILLED': {
      return {
        ...state,
        adminClientCompany: action.payload,
        loading: false,
      };
    }

    case 'GET_PHONEBOOK_USERS_FULFILLED': {
      return {
        ...state,
        phonebookUsers: mapToFilterableUsers(action.payload),
        loading: false,
      };
    }

    case 'GET_PHONEBOOK_USERS_REJECTED': {
      return {
        ...state,
        phonebookUsers: [],
        loading: false,
      };
    }

    case 'GET_ADMIN_CLIENT_OWNER_USERS_PENDING': {
      return {
        ...state,
        adminClientOwnerUsers: [],
        loading: true,
      };
    }

    case 'GET_ADMIN_CLIENT_OWNER_USERS_FULFILLED': {
      return {
        ...state,
        adminClientOwnerUsers: action.payload ?? [],
        loading: false,
      };
    }

    case 'GET_ADMIN_CLIENT_COMPANY_WITH_OWNER_FULFILLED': {
      return {
        ...state,
        otherAdminClientCompany: action.payload,
      };
    }

    case 'SET_SELECTED_ADMIN_CLIENT_OWNER': {
      return {
        ...resetUserStoreState(state),
        selectedAdminClientOwnerId: action.adminClientOwnerId,
      };
    }

    case 'CHECK_BILLING_FULFILLED': {
      const { status, message } = action.payload;
      if (status) {
        return {
          ...state,
          checkBilling: 1,
        };
      } else {
        return {
          ...state,
          checkBilling: -2,
          checkBillingErrorMessage: message,
        };
      }
    }

    case 'CHECK_BILLING_REJECTED': {
      return {
        ...state,
        checkBilling: -2,
        checkBillingErrorMessage: action.payload.message,
      };
    }

    case 'REMOVE_SERVICE_FULFILLED':
    case 'ADD_NEW_SUBSCRIPTION_ITEM_FULFILLED':
    case 'UPGRADE_USER_PACKAGE_FULFILLED':
    case 'DOWNGRADE_USER_PACKAGE_FULFILLED':
    case 'CANCEL_ACCOUNT_FULFILLED':
    case 'REACTIVATE_ACCOUNT_FULFILLED': {
      return {
        ...state,
        refreshOwnerSubscriptionsAndInvoices: true,
        loading: false,
      };
    }

    case 'REMOVE_SERVICE_REJECTED':
    case 'ADD_NEW_SUBSCRIPTION_ITEM_REJECTED':
    case 'UPGRADE_USER_PACKAGE_REJECTED':
    case 'DOWNGRADE_USER_PACKAGE_REJECTED':
    case 'CANCEL_ACCOUNT_REJECTED':
    case 'REACTIVATE_ACCOUNT_REJECTED': {
      return {
        ...state,
        refreshOwnerSubscriptionsAndInvoices: false,
        loading: false,
      };
    }

    case 'REMOVE_SERVICE_PENDING':
    case 'ADD_NEW_SUBSCRIPTION_ITEM_PENDING':
    case 'UPGRADE_USER_PACKAGE_PENDING':
    case 'DOWNGRADE_USER_PACKAGE_PENDING':
    case 'CANCEL_ACCOUNT_PENDING':
    case 'REACTIVATE_ACCOUNT_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'SET_REFRESH_SUBS_INVOICES_FALSE': {
      return {
        ...state,
        refreshOwnerSubscriptionsAndInvoices: false,
        loading: false,
      };
    }
    case 'SET_REFRESH_SUBS_INVOICES_TRUE': {
      return {
        ...state,
        refreshOwnerSubscriptionsAndInvoices: true,
        loading: false,
      };
    }

    case 'MARK_INVOICE_AS_PAID_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'MARK_INVOICE_AS_PAID_FULFILLED': {
      return {
        ...state,
        loading: false,
        refreshOwnerSubscriptionsAndInvoices: true,
      };
    }

    case 'MARK_INVOICE_AS_PAID_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;

    case 'GET_CHAT_ROOMS_FULFILLED': {
      const chatRooms = action.payload.chatGroups.flatMap((cg) => cg.chatRooms);

      return {
        ...state,
        chatRooms,
      };
    }
  }
};

export default UserReducer;

/**
 *  @typedef {object} UserType
 *  @property {number} userId
 *  @property {string} username
 *  @property {boolean} companyOwner
 *  @property {boolean} active
 *  @property {number} adminClientCompanyId
 */
/** @type { (user: UserType[]) => UserType} */
function mapUsers(users) {
  const actualUsers = typeof users !== 'undefined' ? users : [];

  const ownersMap = actualUsers
    .filter((u) => u.companyOwner)
    .reduce(
      (acc, nextUser) => ({
        ...acc,
        [nextUser.adminClientCompanyId]: nextUser.active,
      }),
      {}
    );

  return actualUsers.map((u) => ({
    ...u,
    ownerActive: u.companyOwner
      ? null
      : ownersMap[u.adminClientCompanyId] ?? null,
  }));
}

function mapToFilterableUsers(users) {
  if (users == null) {
    return [];
  }

  return REGEX_USERS_FILTERING
    ? users.map(toActualRoledFullNamedUser)
    : users.map(toLowerCasedUser);
}

function resetUserStoreState(state) {
  return {
    ...initState,
    loading: state.loading,
    loggedUser: state.loggedUser,
    ownerActive: state.ownerActive,
    adminClientOwnerUsers: state.adminClientOwnerUsers,
    filter: state.filter,
    filterApplyed: state.filterApplyed,
    searchText: state.searchText,
  };
}
