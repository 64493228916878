import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

const options = {
  mode: 'setup',
  currency: 'usd',
  paymentMethodCreation: 'manual',
  appearance: {
    theme: 'stripe',
    labels: 'floating',
  },
};

const StripePaymentElement = ({ children }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentCard children={children} />
    </Elements>
  );
};

export default StripePaymentElement;

const PaymentCard = ({ children }) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentElement = <PaymentElement />;

  return (
    <>
      {React.Children.map(children, (child) => {
        // Passing stripe, elements and paymentElement as a prop to each child component
        return React.cloneElement(child, { stripe, elements, paymentElement });
      })}
    </>
  );
};
