import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import App from './App';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import configureStore from './app-store';

const theme = createTheme({
  palette: {
    primary: { main: '#40B4E5' },
    secondary: { main: '#026180' },
    primary2: { main: '#3397C3' },
  },
  timePicker: {
    headerColor: '#40B4E5',
  },
  typography: {
    useNextVariants: true,
  },
});

const Root = () => {
  const { persistor, store } = configureStore();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div />}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default Root;
