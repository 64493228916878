import { useState } from 'react';
import { getLoggedUserFromToken } from '../../api/api-functions'
import React, { useEffect } from 'react'

const Webview = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const accessTokenParam = urlSearchParams.get('access_token');
    const [user, setUser] = useState(undefined)

    const fetchUser = async () => {
        const response = await getLoggedUserFromToken(accessTokenParam)
        setUser(response)
    }

    useEffect(() => {
        if (accessTokenParam)
            fetchUser()
    }, [accessTokenParam])


    return (
        <section className='flex flex-col w-full justify-center items-center'>
            <p>{`Current logged user's id is ${user?.userId} and it's username is ${user?.username}`}</p>
        </section>
    )
}

export default Webview