function getTimeStringFromDateString(date) {
  if (!date) return null;

  try {
    const dateFromString = new Date(date);
    return `${dateFromString.getHours()}:${dateFromString.getMinutes()}`;
  } catch {
    return null;
  }
}

function appendTimeToCurrentDate(timeString) {
  if (!timeString) return null;
  try {
    const split = timeString.split(':');
    let date = new Date();
    date.setHours(parseInt(split[0], 10));
    date.setMinutes(parseInt(split[1]));
    date.setSeconds(0);

    return date;
  } catch {
    return null;
  }
}

function getLocalHoursFromUTC(timeString) {
  if (!timeString) return null;
  try {
    const split = timeString.split(':');
    const date = new Date();
    const hoursDiff = date.getHours() - date.getUTCHours();

    date.setHours(parseInt(split[0], 10) + hoursDiff);
    date.setMinutes(parseInt(split[1]));
    date.setSeconds(0);

    return date;
  } catch {
    return null;
  }
}

function convert24HTo12HTime(time) {
  if (!time) return null;
  const split = time.split(':');
  const hours = split[0] % 12 || 12;
  const minutes = split[1];
  const ampm = split[0] < 12 ? 'AM' : 'PM';
  return `${hours}:${minutes} ${ampm}`;
}

function getAppointmentTime(dateTime, endTime) {
  if (!dateTime) return null;
  const time = getTimeStringFromDateString(dateTime);
  const split = time.split(':');
  const mins = split[1];
  if (endTime)
    return `${mins >= 30 ? (Number(split[0]) + 1).toString() : split[0]}:30`;
  return `${split[0]}:${mins < 30 ? '0' : '30'}`;
}

export {
  getTimeStringFromDateString,
  appendTimeToCurrentDate,
  convert24HTo12HTime,
  getAppointmentTime,
  getLocalHoursFromUTC,
};
