import { Dialog, LinearProgress } from '@material-ui/core';

import BackDropIOSWorkaround from '../BackDropIOSWorkaround';

export function FallbackLoading() {
  const styles = {
    progressBar: {
      zIndex: 99999,
      position: 'relative',
      display: 'block',
      height: 6,
      margin: 0,
      padding: 0,
      visibility: props.open ? 'visible' : 'hidden',
    },
    emptyDialog: {
      zIndex: '99998',
      overflow: 'hidden',
    },
  };
  return (
    <>
      <LinearProgress style={styles.progressBar} />
      {/* Use empty dialog to block user action and display Backdrop */}
      <Dialog
        style={styles.emptyDialog}
        open={true}
        BackdropComponent={BackDropIOSWorkaround}
      />
    </>
  );
}
