import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ButtonWithLoader = (props) => {
  const classes = useStyles();
  const { isLoading, isProcessCompleted, onClick, title, titleOnComplete, disabled } =
    props;

  const buttonClassname = clsx({
    [classes.buttonSuccess]: isProcessCompleted,
  });

  return (
    <div className="flex flex-col w-full relative">
      <Button
        variant="contained"
        color="primary"
        className={buttonClassname}
        disabled={isLoading || disabled}
        onClick={onClick}
      >
        {isProcessCompleted ? titleOnComplete : title}
      </Button>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default ButtonWithLoader;
