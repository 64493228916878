import { useEffect, useRef } from 'react';

import { DISPATCHER_ACTION_TYPE } from '../reducers/notistack-dispatcher-reducer';
import { LOGOUT_AFTER_UNAUTHORIZED_MILLIS } from '../common/appConstants';
import React from 'react';
import { clearMessageNotistack } from '../actions/notistack-dispatcher-actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

export function NotistackDispatcher({ children, ...restProps }) {
  const { enqueueSnackbar /*closeSnackbar*/ } = useSnackbar();
  const {
    dispatcherAction,
    messageToEnqueue,
    variant,
    lastMessageKey,
    timestamp,
  } = useSelector((store) => store.notifStackDispatcher);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      dispatcherAction === DISPATCHER_ACTION_TYPE.POST_AND_LOGOUT ||
      dispatcherAction === DISPATCHER_ACTION_TYPE.POST_MESSAGE
    ) {
      enqueueSnackbar(messageToEnqueue, {
        variant,
        preventDuplicate: true,
        onExit: () => dispatch(clearMessageNotistack()),
      });
    }

    if (
      dispatcherAction === DISPATCHER_ACTION_TYPE.POST_AND_LOGOUT ||
      dispatcherAction === DISPATCHER_ACTION_TYPE.LOG_OUT
    ) {
      setTimeout(
        () => history.push('/logout/true'),
        LOGOUT_AFTER_UNAUTHORIZED_MILLIS
      );
    }
  }, [timestamp]);

  if (React.isValidElement(children)) {
    return React.cloneElement(children, restProps);
  }
  return children;
}
