import {
  CLEAR_MESSAGE_NOTISTACK,
  LOGOUT_NOTISTACK,
  POST_MESSAGE_NOTISTACK,
} from '../actions/notistack-dispatcher-actions';

const initialState = {
  dispatcherAction: '',
  messageToEnqueue: '',
  variant: 'info',
  lastMessageKey: null,
  timestamp: null,
};

export const DISPATCHER_ACTION_TYPE = {
  POST_MESSAGE: 'post_message',
  POST_AND_LOGOUT: 'post_and_logout',
  LOG_OUT: 'log_out',
  CLEAR_MESSAGE: 'clear_message',
};

export function notistackDispatcherReducer(state = initialState, action) {
  switch (action.type) {
    case POST_MESSAGE_NOTISTACK: {
      const dispatcherAction =
        action.payload?.dispatcherAction ?? DISPATCHER_ACTION_TYPE.POST_MESSAGE;
      let message = action.payload?.message ?? 'Unknown error';
      const variant = action.payload?.variant ?? 'info';
      const key = action.payload?.key;
      let messge =
        typeof message !== 'string' ? 'Unknown error message format' : message; //ui crash bag when is not string
      return {
        ...state,
        dispatcherAction,
        messageToEnqueue: message,
        variant,
        lastMessageKey: key,
        timestamp: action.payload.timestamp,
      };
    }
    case CLEAR_MESSAGE_NOTISTACK:
      return {
        ...initialState,
        dispatcherAction: DISPATCHER_ACTION_TYPE.CLEAR_MESSAGE,
        messageToEnqueue: action.message,
        timestamp: action.payload.timestamp,
      };

    case LOGOUT_NOTISTACK:
      return {
        ...state,
        dispatcherAction: DISPATCHER_ACTION_TYPE.LOG_OUT,
        timestamp: action.payload.timestamp,
      };

    default:
      return state;
  }
}
