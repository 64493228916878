import { useState } from 'react';

const useForm = (initialFormValues) => {
  const [form, setForm] = useState(initialFormValues);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setForm({ ...form, [name]: newValue });
  };

  const updateValue = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  return { form, setForm, handleChange, updateValue };
};

export default useForm;
