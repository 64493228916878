export const Enviornment = {
  production: 'production',
  test: 'test',
  development: 'development',
};

export const UserActivityStatus = {
  Login: 1,
  Logout: 2,
  Active: 3,
  Idle: 4,
};

export const Control = {
  NotDefined: 0,
  Tasks: 1,
};

export const CalendarEntryType = {
  Appointment: 1,
  Reminder: 2,
  CallReminder: 3,
};

export const ContactDetailType = {
  Email: 1,
  Phone: 2,
};

export const ContactDetailSubtype = {
  Home: 1,
  Work: 2,
  Mobile: 3,
  Other: 4,
  Main: 5,
};

export const TaskNotificationDataUpdateType = {
  Document: 1,
  Comment: 2,
  TaskCreate: 3,
  AssigneeChange: 4,
};
