import { useDispatch, useSelector } from 'react-redux';

import Login from '.';
import React from 'react';
import { unblockLogin } from '../../actions/user-actions';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export const Auth = (props) => {
  let blockedTime = useSelector((state) => state.user.blockedTime);
  const dispatch = useDispatch();
  const [timeoutObj, setTimeoutObj] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const readStoredTime = () => {
    const time = parseInt(localStorage.getItem('blockedTime'));
    if (isNaN(time)) {
      return 0;
    }

    return time;
  };

  const isLoginBlocked = useCallback(
    (fromProps) => {
      if (fromProps) {
        return blockedTime !== undefined && blockedTime > 0;
      } else {
        const fromStorage = readStoredTime();

        if (!fromStorage || isNaN(fromStorage)) {
          return false;
        }

        return fromStorage > 0;
      }
    },
    [blockedTime]
  );

  const [isBlocked, setIsBlocked] = useState(
    isLoginBlocked(true) || isLoginBlocked(false)
  );

  const getBlockTime = useCallback(
    (fromProps) => {
      if (fromProps) {
        return blockedTime;
      } else if (!fromProps) {
        return readStoredTime();
      }

      return undefined;
    },
    [blockedTime]
  );

  const setBlockTimeActions = useCallback(
    (fromProps) => {
      let savedDate = getBlockTime(fromProps);

      if (!savedDate || isNaN(savedDate) || savedDate <= 0) {
        return;
      }

      const dates = Date.now() + savedDate;
      localStorage.setItem('blockedTime', savedDate.toFixed(0));
      localStorage.setItem('date-blocked', dates.toFixed(0));
      setIsBlocked(true);
    },
    [blockedTime]
  );

  useEffect(() => {
    const dateNow = Date.now();
    const dateUntillBlocked = parseInt(localStorage.getItem('date-blocked'));

    if (!isNaN(dateUntillBlocked) || dateNow > dateUntillBlocked) {
      return;
    } else {
      localStorage.setItem(
        'blockedTime',
        (dateUntillBlocked - dateNow).toFixed(0)
      );
    }

    if (isLoginBlocked(false)) {
      setBlockTimeActions(false);
    } else if (isLoginBlocked(true)) {
      setBlockTimeActions(true);
    }
  }, [setBlockTimeActions, isLoginBlocked]);

  useEffect(() => {
    if (!isBlocked) {
      return;
    }

    if (timeoutObj) {
      return;
    }

    let savedDate = readStoredTime();

    if (!savedDate) {
      return;
    }

    enqueueSnackbar(
      `You are blocked from logging in for ${(savedDate / 60 / 1000).toFixed(
        0
      )} minutes`,
      {
        variant: 'error',
        autoHideDuration: 2000,
      }
    );

    const period = setInterval(() => {
      const newTime = readStoredTime() - 1000;
      localStorage.setItem('blockedTime', `${newTime}`);

      if (newTime <= 0) {
        localStorage.removeItem('blockedTime');
        localStorage.removeItem('date-blocked');
        setIsBlocked(false);
        dispatch(unblockLogin());
        clearInterval(period);
        setTimeoutObj(undefined);
      }
    }, 1000);

    setTimeoutObj(period);
  }, [blockedTime, isBlocked]);

  return <Login loginBlocked={isBlocked} {...props} />;
};
