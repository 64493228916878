import { Redirect } from 'react-router-dom';
import { logDebugMessage } from './../utilities/debug-helper';

// TODO - Rethink this component
const Authorized =
  (role) => (roles) => (CustomComponent, props) => (redirectRoute) => {
    // debugLog(role, roles, CustomComponent, props, redirectRoute);
    if (roles.includes(role)) {
      // logDebugMessage('+++ Authorized Navigate +++ ');
      return props ? () => <CustomComponent {...props} /> : CustomComponent;
    }
    // logDebugMessage('+++  Authorized Redirect +++');

    return () => <Redirect to={redirectRoute}></Redirect>;
  };

export default Authorized;

function debugLog(role, roles, customComponent, props, redirectRoute) {
  logDebugMessage('=========== Authorized =========== ');
  logDebugMessage('Current role: ', role);
  logDebugMessage('Authorized roles: ', roles);
  logDebugMessage('Component: ', customComponent);
  logDebugMessage('Props: ', props);
  logDebugMessage('Redirect route: ', redirectRoute);
}
