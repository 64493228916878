const initState = {
  loading: false,
  data: [],
  settings: [],
  filter: { adminClientCompanyId: -1 },
  adminClientsOwners: [],
};

const PushOnReducer = function (state = initState, action) {
  switch (action.type) {
    case 'GET_PUSH_ON_SETTINGS_PENDING': {
      return { ...state, loading: true };
    }

    case 'GET_PUSH_ON_SETTINGS_REJECTED': {
      return { ...state, loading: false };
    }

    case 'GET_PUSH_ON_SETTINGS_FULFILLED': {
      return { ...state, loading: false, settings: action.payload };
    }

    case 'SET_PUSH_ON_SETTINGS_FILTER': {
      return { ...state, filter: action.filter };
    }

    case 'GET_PUSH_ON_SETTINGS_ADMIN_CLIENT_OWNERS_FULFILLED': {
      return {
        ...state,
        adminClientsOwners: action.payload,
      };
    }

    default:
      return state;
  }
};

export default PushOnReducer;
