export const TOGGLE_CHAT_WINDOW = 'TOGGLE_CHAT_WINDOW';

export function toggleChatWindow() {
  return {
    type: TOGGLE_CHAT_WINDOW,
  };
}

export const CREATED_USER_CHAT = 'CREATED_USER_CHAT';

export function createdUserChat() {
  return {
    type: CREATED_USER_CHAT,
  };
}

export const SET_ACTIVE_CHAT_ROOM = 'SET_ACTIVE_CHAT_ROOM';

export function setActiveChatRoom(chatRoomId) {
  return {
    type: SET_ACTIVE_CHAT_ROOM,
    chatRoomId,
  };
}

export const UPDATE_CHAT_NOTIFICATION_SETTING =
  'UPDATE_CHAT_NOTIFICATION_SETTING';

export function updateChatNotificationSettings() {
  return {
    type: UPDATE_CHAT_NOTIFICATION_SETTING,
  };
}

export const UPDATE_CHAT_NOTIFICATION_DATA = 'UPDATE_CHAT_NOTIFICATION_DATA';
export function updateChatNotificationData() {
  return {
    type: UPDATE_CHAT_NOTIFICATION_DATA,
  };
}

export const GET_CHAT_ROOMS = 'GET_CHAT_ROOMS';

export function getChatRooms(userId, loggedUser) {
  return {
    type: GET_CHAT_ROOMS,
    userId,
    loggedUser,
  };
}

export function getUserChatRooms(userId) {
  return {
    type: 'GET_USER_CHATROOMS',
    payload: userId,
  };
}

export function createUnreadChatRoom(chatRooms) {
  return {
    type: 'CREATE_UNREAD_GROUP',
    payload: {
      chatRooms,
    },
  };
}

export const SAVE_CHAT_MESSAGE_TO_ROOM = 'SAVE_CHAT_MESSAGE_TO_ROOM';

export function saveChatMessageToRoom(
  roomId,
  message,
  recipientIsSender,
  updateCounter
) {
  return {
    type: SAVE_CHAT_MESSAGE_TO_ROOM,
    roomId,
    message,
    recipientIsSender,
    updateCounter,
  };
}

export const GET_CHAT_ROOM_MESSAGES = 'GET_CHAT_ROOM_MESSAGES';
export function getChatRoomMessages(chatRoomId, lastId, messagesCount) {
  return {
    type: GET_CHAT_ROOM_MESSAGES,
    chatRoomId,
    lastId,
    messagesCount,
  };
}

export const SET_MESSAGE_SEEN = 'SET_MESSAGE_SEEN';
export function setMessageSeen(messageId) {
  return {
    type: SET_MESSAGE_SEEN,
    messageId,
  };
}

export const RESET_NEW_MESSAGES_COUNT = 'RESET_NEW_MESSAGES_COUNT';
export function resetNewMessagesCount() {
  return {
    type: RESET_NEW_MESSAGES_COUNT,
  };
}

export const CHAT_SET_ADMIN_CLIENT_OWNER_ID = 'CHAT_SET_ADMIN_CLIENT_OWNER_ID';
export function setSelectedAdminClientOwner(userId) {
  return {
    type: CHAT_SET_ADMIN_CLIENT_OWNER_ID,
    userId,
  };
}

export const GET_OVERSEEN_CHAT_GROUPS = 'GET_OVERSEEN_CHAT_GROUPS';
export function getOverseenChatGroups() {
  return {
    type: GET_OVERSEEN_CHAT_GROUPS,
  };
}

export const GET_OVERSEEN_CHAT_ROOM_MESSAGES =
  'GET_OVERSEEN_CHAT_ROOM_MESSAGES';
export function getOverseenChatRoomMessages(roomId) {
  return {
    type: GET_OVERSEEN_CHAT_ROOM_MESSAGES,
    roomId,
  };
}

export const CHAT_GET_ADMIN_CLIENT_OWNERS_USERS =
  'CHAT_GET_ADMIN_CLIENT_OWNERS_USERS';
export function getAdminClientOwnersUsersChat() {
  return {
    type: CHAT_GET_ADMIN_CLIENT_OWNERS_USERS,
  };
}

export const GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES =
  'GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES';

export function getOverseenAdditinalChatRoomMessages(roomId, lastMessageId) {
  return {
    type: GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES,
    roomId,
    lastMessageId,
  };
}
