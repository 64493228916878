import { Dialog, LinearProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';

import BackDropIOSWorkaround from '../BackDropIOSWorkaround';

export function Loading(props) {
  // Dialog/Backdrop bar will not be rendered if the operation takes less then RENDER_DELAY
  const RENDER_DELAY = 500;

  const [visible, setVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (props.open === true) {
      const tid = setTimeout(() => {
        setVisible(true);
      }, RENDER_DELAY);
      setTimeoutId(tid);
    }
    if (props.open === false) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
      setVisible(false);
    }
  }, [props.open]);

  const styles = {
    progressBar: {
      zIndex: 99999,
      position: 'relative',
      display: 'block',
      height: 6,
      margin: 0,
      padding: 0,
      visibility: props.open ? 'visible' : 'hidden',
    },
    emptyDialog: {
      zIndex: '99998',
      overflow: 'hidden',
    },
  };
  return (
    <>
      <LinearProgress style={styles.progressBar} />
      {/* Use empty dialog to block user action and display Backdrop */}
      <Dialog
        style={styles.emptyDialog}
        open={visible}
        BackdropComponent={BackDropIOSWorkaround}
      />
    </>
  );
}
