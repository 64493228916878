export const GET_ASSISTANT_FOR_CLIENT = 'GET_ASSISTANT_FOR_CLIENT';
export function getAssistantsForClient(clientId) {
  return { type: GET_ASSISTANT_FOR_CLIENT, clientId: clientId };
}

export const GET_CLIENTS_FOR_ASSISTANT = 'GET_CLIENTS_FOR_ASSISTANT';
export function getClientsForAssistant(assistantId) {
  return { type: GET_CLIENTS_FOR_ASSISTANT, assistantId: assistantId };
}

export const GET_CLIENTS = 'GET_CLIENTS';
export function getClients() {
  return { type: GET_CLIENTS };
}

export const SAVE_AVATAR = 'SAVE_AVATAR';
export function saveAvatar(image) {
  return { type: SAVE_AVATAR, image: image };
}

export const SAVE_USER = 'SAVE_USER';
export function saveUser(data) {
  return { type: SAVE_USER, user: data };
}

export const UPDATE_LOGGED_USER = 'UPDATE_LOGGED_USER';
export function updateLoggedUser(user) {
  return { type: UPDATE_LOGGED_USER, user: user };
}

export const GET_CREATE_USERS_LIMIT = 'GET_CREATE_USERS_LIMIT';
export function getCreateUsersLimit() {
  return {
    type: GET_CREATE_USERS_LIMIT,
  };
}

export const UPLOAD_FILE = 'UPLOAD_FILE';
export function uploadFile(selectedFile, roomId, type) {
  return {
    type: UPLOAD_FILE,
    payload: { selectedFile, roomId, type },
  };
}

export const GET_ADMIN_USERS_LIMIT = 'GET_ADMIN_USERS_LIMIT';
export function getAdminUsersLimit(userId) {
  return {
    type: GET_ADMIN_USERS_LIMIT,
    userId: userId,
  };
}

export const GET_USERS = 'GET_USERS';
export function getUsers(dispatch, roleId, adminClientCompanyId = null) {
  dispatch({
    type: GET_USERS,
    roleId,
    adminClientCompanyId,
  });
}

export const PUSH_ON_GET_CLIENTS = 'PUSH_ON_GET_CLIENTS';
export function pushOnGetClients(roleId = 3, adminClientCompanyId = null) {
  return {
    type: GET_USERS,
    roleId,
    adminClientCompanyId,
  };
}

export const GET_ADMIN_OWNER_CLIENTS = 'GET_ADMIN_OWNER_CLIENTS';
export function getAdminClientOwnerClients(adminClientOwnerId) {
  return {
    type: GET_ADMIN_OWNER_CLIENTS,
    adminClientOwnerId,
  };
}

export const LOG_USER_ACTIVITY = 'LOG_USER_ACTIVITY';
export function logUserActivity(dispatch, userActivityTypeId) {
  dispatch({
    type: LOG_USER_ACTIVITY,
    userActivityTypeId,
  });
}

export const CHECK_USERNAME = 'CHECK_USERNAME';
export function checkUserName(dispatch, userId, userName) {
  dispatch({
    type: CHECK_USERNAME,
    userId,
    userName,
  });
}

export const GET_USER_GUESTS = 'GET_USER_GUESTS';
export function getUserGuests(userId) {
  return { type: GET_USER_GUESTS, userId };
}

export const GET_CALENDAR_USERS = 'GET_CAL_USERS';
export function getCalendarUsers(adminClientCompanyId = null) {
  return { type: GET_CALENDAR_USERS, adminClientCompanyId };
}

export const GET_USER_BASE = 'GET_USER_BASE';
export function getUserBase(userId) {
  return { type: GET_USER_BASE, userId };
}

export const GET_USER_ADMIN_CLIENT_COMPANY = 'GET_USER_ADMIN_CLIENT_COMPANY';
export function getUserAdminClientCompany(adminClientCompanyId) {
  return {
    type: GET_USER_ADMIN_CLIENT_COMPANY,
    adminClientCompanyId,
  };
}

export const GET_PHONEBOOK_USERS = 'GET_PHONEBOOK_USERS';
export function getPhonebookUsers(adminClientCompanyId = null) {
  return { type: GET_PHONEBOOK_USERS, adminClientCompanyId };
}

export const GET_ADMIN_CLIENT_OWNER_USERS = 'GET_ADMIN_CLIENT_OWNER_USERS';
export function getAdminClientOwnersUsers() {
  return { type: GET_ADMIN_CLIENT_OWNER_USERS };
}

export const GET_ADMIN_CLIENT_COMPANY_WITH_OWNER =
  'GET_ADMIN_CLIENT_COMPANY_WITH_OWNER';
export function getAdminClientCompanyWithOwner(adminClientCompanyId) {
  return { type: GET_ADMIN_CLIENT_COMPANY_WITH_OWNER, adminClientCompanyId };
}

export const SET_SELECTED_ADMIN_CLIENT_OWNER =
  'SET_SELECTED_ADMIN_CLIENT_OWNER';
export function setSelectedAdminClientCompanyId(adminClientOwnerId) {
  return {
    type: SET_SELECTED_ADMIN_CLIENT_OWNER,
    adminClientOwnerId,
  };
}

export const SET_USERS_FILTER = 'SET_USERS_FILTER';
export function setUsersFilter(filter) {
  return {
    type: SET_USERS_FILTER,
    filter,
  };
}

export const SET_USERS_SEARCH_TEXT = 'SET_USERS_SEARCH_TEXT';
export function setUsersSearchText(searchText) {
  return {
    type: SET_USERS_SEARCH_TEXT,
    searchText,
  };
}

export const RESET_USER_STORE_STATE = 'RESET_USER_STORE_STATE';
export function resetUserStoreState() {
  return {
    type: RESET_USER_STORE_STATE,
  };
}

export const FINISH_REGISTRATION = 'FINISH_REGISTRATION';
export function finishRegistration(data) {
  return { type: FINISH_REGISTRATION, payload: data };
}

export const CHECK_BILLING_PENDING = 'CHECK_BILLING_PENDING';
export function checkBillingPending() {
  return {
    type: CHECK_BILLING_PENDING,
  };
}

export const CHECK_BILLING_DEFAULT = 'CHECK_BILLING_DEFAULT';
export function checkBillingDefault() {
  return {
    type: CHECK_BILLING_DEFAULT,
  };
}

export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export function removeService(subscriptionItemId) {
  return {
    type: REMOVE_SERVICE,
    payload: { subscriptionItemId },
  };
}

export const SET_REFRESH_SUBS_INVOICES_FALSE =
  'SET_REFRESH_SUBS_INVOICES_FALSE';
export function setRefreshOwnerSubscriptionsAndInvoicesFalse() {
  return {
    type: SET_REFRESH_SUBS_INVOICES_FALSE,
  };
}

export const SET_REFRESH_SUBS_INVOICES_TRUE = 'SET_REFRESH_SUBS_INVOICES_TRUE';
export function setRefreshOwnerSubscriptionsAndInvoicesTrue() {
  return {
    type: SET_REFRESH_SUBS_INVOICES_TRUE,
  };
}

export const ADD_NEW_SUBSCRIPTION_ITEM = 'ADD_NEW_SUBSCRIPTION_ITEM';
export function addNewSubscriptionItem(priceId) {
  return {
    type: ADD_NEW_SUBSCRIPTION_ITEM,
    payload: { priceId },
  };
}

export const UPGRADE_USER_PACKAGE = 'UPGRADE_USER_PACKAGE';
export function upgradeUserPackage(priceId) {
  return {
    type: UPGRADE_USER_PACKAGE,
    payload: { priceId },
  };
}

export const DOWNGRADE_USER_PACKAGE = 'DOWNGRADE_USER_PACKAGE';
export function downgradeUserPackage(priceId) {
  return {
    type: DOWNGRADE_USER_PACKAGE,
    payload: { priceId },
  };
}

export const CANCEL_ACCOUNT = 'CANCEL_ACCOUNT';
export function cancelAccount() {
  return {
    type: CANCEL_ACCOUNT,
  };
}

export const REACTIVATE_ACCOUNT = 'REACTIVATE_ACCOUNT';
export function reactivateAccount() {
  return {
    type: REACTIVATE_ACCOUNT,
  };
}

export const MARK_INVOICE_AS_PAID = 'MARK_INVOICE_AS_PAID';
export function markInvoiceAsPaid(invoiceId) {
  return {
    type: MARK_INVOICE_AS_PAID,
    payload: { invoiceId },
  };
}

export const UNBLOCK_LOGIN = 'UNBLOCK_LOGIN';
export function unblockLogin() {
  return {
    type: UNBLOCK_LOGIN,
  };
}
