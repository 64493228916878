import { defaultTotal } from '../../components/Stripe/RegistrationCheckoutForm';
import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_VIRTUALASSISSTANT_API_ADDRESS;
export const API_BASE_URL = BASE_URL + '/api';

const getProducts = async () => {
  try {
    const config = {
      method: 'GET',
      url: `${API_BASE_URL}/products`,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(config);
    const { status } = response;

    if (status !== 200) {
      return [];
    }

    const { data } = response;
    return data;
  } catch (error) {
    throw error;
  }
};

const postSubscription = async (priceIds, coupon) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'POST',
      url: `${API_BASE_URL}/subscriptions`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        PriceIds: priceIds,
        Coupon: coupon,
      },
    };

    const response = await axios(config);
    const { status } = response;
    if (status !== 200) {
      const { statusText } = response;
      return statusText;
    }

    const { data } = response;
    const clientSecret = data.clientSecret;

    return clientSecret;
  } catch (error) {
    throw error;
  }
};

const retryInvoice = async (invoiceId, paymentMethodId) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'POST',
      url: `${API_BASE_URL}/invoices/retryinvoice`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        InvoiceId: invoiceId,
        PaymentMethodId: paymentMethodId,
      },
    };

    const response = await axios(config);

    const { status } = response;
    if (status !== 200) {
      const { statusText } = response;
      return statusText;
    }

    return response;
  } catch (error) {
    throw error;
  }
};

const updateBillingInfo = async (billingInfo) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'PUT',
      url: `${API_BASE_URL}/customers/updateuserbillingaddress`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        User_City: billingInfo.paymentCity,
        User_Country: billingInfo.paymentCountry,
        User_Line1: billingInfo.paymentAddress,
        User_PostalCode: billingInfo.paymentZIP,
        User_State: billingInfo.paymentState,
      },
    };

    const response = await axios(config);

    const { status } = response;
    if (status !== 200) {
      const { statusText } = response;
      return statusText;
    }

    return response?.data;
  } catch (error) {
    throw error;
  }
};

const getPreviewInvoice = async (prices, coupon) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'POST',
      url: `${API_BASE_URL}/invoices/invoicepreview`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        Prices: prices,
        Coupon: coupon === '' ? null : coupon,
      },
    };

    const response = await axios(config);
    const { status } = response;

    if (status !== 200) {
      return { invoicesData: [], totalData: defaultTotal, status: 400 };
    }

    const { data: invoicesData } = response;
    const { StripeInvoiceLineItems } = invoicesData;

    const invoices = StripeInvoiceLineItems.map((item) => {
      const amount = item.Amount;
      const currency = item.Currency;
      const description = item.Description;
      const { TaxAmounts } = item;
      const taxes = TaxAmounts.reduce(
        (previousValue, currentValue) =>
          previousValue + (currentValue.Amount ? currentValue.Amount : 0),
        0
      );

      const { DiscountAmounts } = item;
      const discounts = DiscountAmounts.reduce(
        (previousValue, currentValue) =>
          previousValue +
          (currentValue.DiscountAmount ? currentValue.DiscountAmount : 0),
        0
      );

      return { amount, description, currency, taxes, discounts };
    });

    const { Subtotal: subtotal } = invoicesData;
    const { TotalDiscountAmount: totalDiscount } = invoicesData;
    const { Tax: tax } = invoicesData;
    const { Total: total } = invoicesData;

    return {
      invoicesData: invoices,
      totalData: { subtotal, totalDiscount, tax, total },
      status: 200,
    };
  } catch (error) {
    if (error.response.status === 422) {
      return { invoicesData: [], totalData: defaultTotal, status: 422 };
    }
    throw error;
  }
};

const dataForAdminClientTable = (adminClients) => {
  return adminClients.map((adminClient) => ({
    username: adminClient.UserName,
    firstName: adminClient.FirstName,
    lastName: adminClient.LastName,
    companyName: adminClient.CompanyName,
    confirmationDate: adminClient.ConfirmationDate,
    postalCode: adminClient.UserAddress?.Postal_Code,
    active: adminClient.Active,
    userId: adminClient.UserId,
    status: adminClient.Status,
  }));
};

const dataForSubscriptionTable = (subscriptions) => {
  const result = [];

  subscriptions.map((subscription) => {
    const subscriptionObject = {};
    Object.assign(subscriptionObject, {
      companyId: subscription.CompanyID,
      fullName: subscription.UserFullName,
      status: subscription.Status,
      periodEnd: subscription.CurrentPeriodEnd,
    });
    const products = subscription.StripeSubscriptionItems;
    products.map((product) => {
      const productObject = {};
      Object.assign(productObject, {
        total: product.UnitAmount,
        subscriptionName: product.ProductName,
      });

      const resultObject = {};
      Object.assign(resultObject, subscriptionObject);
      Object.assign(resultObject, productObject);

      result.push(resultObject);
    });
  });
  return result;
};

const dataForInvoiceTable = (invoices) => {
  const tempInvoices = [];
  for (const invoice of invoices) {
    const duplicate = tempInvoices.filter(
      (tempInvoice) => tempInvoice.InvoiceId === invoice.InvoiceId
    )[0];

    if (!duplicate) {
      tempInvoices.push(invoice);
    } else {
      invoice.Status = 'failed';
      tempInvoices.push(invoice);
    }
  }

  return tempInvoices.map((invoice) => ({
    id: invoice.InvoiceId,
    userId: invoice.UserId,
    customerName: invoice.CustomerName,
    status: invoice.Status,
    number: invoice.Number,
    ammountDue: invoice.AmountDue,
    ammountRemaining: invoice.AmountRemaining,
    pdf: invoice.InvoicePdf,
  }));
};

const getAllAdminSubscriptions = async (take, page, status) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'GET',
      url: `${API_BASE_URL}/subscriptions/getallsubscriptions`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        take,
        page,
        status,
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const { data } = response;
      const result = dataForSubscriptionTable(
        data.filter((activeSub) => activeSub.Status === 'active')
      );
      return result;
    }

    return [];
  } catch (error) {
    throw error;
  }
};

const adminInvoiceUser = async (userId, take, page) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'GET',
      url: `${API_BASE_URL}/invoices/admininvoicesuser`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        userId,
        take,
        page,
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const data = response.data;
      const result = dataForInvoiceTable(data.Items);
      return result;
    }

    return [];
  } catch (error) {
    throw error;
  }
};

const adminSubscriptionsUser = async (userId, take, page) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'GET',
      url: `${API_BASE_URL}/subscriptions/getallsubscriptions`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        userId,
        take,
        page,
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const data = response.data;
      const result = dataForSubscriptionTable(data);
      return result;
    }

    return [];
  } catch (error) {
    throw error;
  }
};

const getAllregisteredAdminClients = async (take, page, searchTerm) => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'GET',
      url: `${API_BASE_URL}/User/GetAllRegisteredAdminClients`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        take,
        page,
        searchTerm,
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const data = response.data;
      const result = dataForAdminClientTable(data.Items);
      return result;
    }

    return [];
  } catch (error) {
    throw error;
  }
};

const getOwnerSubscriptions = (subscriptions) => {
  const result = [];

  subscriptions.map((subscription) => {
    const subscriptionObject = {};
    Object.assign(subscriptionObject, {
      periodEnd: subscription.CurrentPeriodEnd,
      cancelAt: subscription.CancelAt,
      canceledAtPeriodEnd: subscription.CanceledAtPeriodEnd,
    });
    const products = subscription.StripeSubscriptionItems;
    products.map((product) => {
      const productObject = {};
      Object.assign(productObject, {
        currency: product.Currency,
        priceId: product.PriceId,
        productId: product.ProductId,
        amount: product.UnitAmount,
        subscriptionName: product.ProductName,
        subscriptionItemId: product.StripeSubscriptionItemId,
        productName: product.ProductName + ' ' + product.ProductUserCount,
        service: product.ProductAppType === '2',
      });

      const resultObject = {};
      Object.assign(resultObject, subscriptionObject);
      Object.assign(resultObject, productObject);

      result.push(resultObject);
    });
  });
  return result;
};

const ownerSubscriptions = async () => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'GET',
      url: `${API_BASE_URL}/subscriptions`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const { data } = response;
      return getOwnerSubscriptions(
        data.filter((subscription) => subscription.Status !== 'false')
      );
    }

    return [];
  } catch (error) {
    throw error;
  }
};

const ownerInvoices = async () => {
  try {
    const authToken = localStorage.getItem('authToken');

    const config = {
      method: 'GET',
      url: `${API_BASE_URL}/invoices`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const { data } = response;
      const { Items, TotalCount } = data;
      return {
        totalCount: TotalCount,
        invoicesArray: dataForInvoiceTable(Items),
      };
    }

    return [];
  } catch (error) {
    throw error;
  }
};

const checkToken = async (token) => {
  try {
    const config = {
      method: 'POST',
      url: `${API_BASE_URL}/ValidateRegistrationLink`,
      data: { Token: token },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const { data } = response;
      return data;
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export {
  getProducts,
  postSubscription,
  getPreviewInvoice,
  getAllAdminSubscriptions,
  adminInvoiceUser,
  adminSubscriptionsUser,
  getAllregisteredAdminClients,
  ownerSubscriptions,
  ownerInvoices,
  retryInvoice,
  updateBillingInfo,
  checkToken,
};
