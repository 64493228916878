import { Component, Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { initGA, initTag } from './utilities/google-tools';

import { Auth } from './pages/Login/Auth';
import Authorized from './utilities/authorization-route';
import { FallbackLoading } from './components/Loading';
import Logout from './pages/authentication/logout';
import { NotistackDispatcher } from './components/NotistackDispatcher';
import RegistrationWithLink from './pages/RegistrationWithLink/RegistrationWithLink';
import { connect } from 'react-redux';
import Webview from './pages/Webview';

const ForgotPassword = lazy(() =>
  import('./pages/ForgotPassword/ForgotPassword')
);
const Register = lazy(() => import('./pages/Register'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Layout = lazy(() => import('./pages/Layout'));

initGA()
initTag(process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID)

class App extends Component {
  render() {
    let role = this.props.user.loggedUser.role
      ? this.props.user.loggedUser.role
      : 'Anonymus';
    return (
      <Router>
        <NotistackDispatcher>
          <Suspense fallback={FallbackLoading}>
            <Switch>
              <Route
                path="/RegisterWithLink"
                component={Authorized(role)(['Anonymus'])(RegistrationWithLink)(
                  '/'
                )}
              />
              <Route path="/Login/:logout?" component={Auth}></Route>
              <Route path="/webview" component={Webview}></Route>
              <Route path="/Logout/:autoLogout" component={Logout} />
              <Route
                path="/ForgotPassword"
                component={Authorized(role)(['Anonymus'])(ForgotPassword)('/')}
              />
              <Route
                path="/ResetPassword"
                component={Authorized(role)(['Anonymus'])(ResetPassword)('/')}
              ></Route>
              <Route path="/Logout/:autoLogout" component={Logout}></Route>
              <Route
                path="/Register"
                component={Authorized(role)(['Anonymus'])(Register)('/')}
              />
              <Route
                path="/"
                component={Authorized(role)([
                  'Admin',
                  'Assistant',
                  'Client',
                  'Guest',
                  'AdminClient',
                ])(Layout)('/Login')}
              />
            </Switch>
          </Suspense>
        </NotistackDispatcher>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(App);
