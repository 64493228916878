import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, TextField } from '@material-ui/core';

import { Label } from '../../../components/Label';
import { updateBillingInfo } from '../../../pages/Products/api';
import countries from '../../../components/Products/countries';
import { FormDropdown } from '../../../components/Products/FormField';
import { snackbarVariants, states } from '../../../utilities/constants';
import ButtonWithLoader from '../../../pure-ui/ButtonWithLoader/ButtonWithLoader';

const BillingDetails = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setisLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { billingInfo, setBillingInfo, showSubmitButton } = props;
  const [isProcessCompleted, setisProcessCompleted] = useState(false);
  const [prevBillingInfo, setPrevBillingInfo] = useState(billingInfo);
  const [disabled, setDisabled] = useState(showSubmitButton ? true : false);

  const onChange = (e) => {
    setBillingInfo({ ...billingInfo, [e.target.name]: e.target.value });
  };

  const setPaymentState = (selectedState) => {
    setBillingInfo({ ...billingInfo, paymentState: selectedState.value });
  };

  const findStateByValue = (value) => {
    const state = states.find((state) => state.value === value);
    if (state) {
      const { label } = state;
      return label;
    }
  };

  const setPaymentCountry = (selectedCountry) => {
    if (selectedCountry.value === 'US') {
      setBillingInfo({ ...billingInfo, paymentCountry: selectedCountry.value });
    } else {
      setBillingInfo({
        ...billingInfo,
        paymentCountry: selectedCountry.value,
        paymentState: '',
      });
    }
  };

  const findCountryByValue = (value) => {
    const country = countries.find((country) => country.value === value);
    if (country) {
      const { label } = country;
      return label;
    }
    return value;
  };

  const handleSubmit = async () => {
    try {
      setisLoading(true);
      setisProcessCompleted(false);
      const result = await updateBillingInfo(billingInfo);
      if (!result?.IsBillingAddressValid) {
        enqueueSnackbar('Billing address is invalid', snackbarVariants.error);
        setSubmitError('Billing address is invalid');
        await updateBillingInfo(prevBillingInfo);
      } else {
        enqueueSnackbar(
          'Billing address updated successfully',
          snackbarVariants.success
        );
        setSubmitError(null);
        setisProcessCompleted(true);
        setPrevBillingInfo(billingInfo);
        setDisabled(true);
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col lg:w-[80%] w-full my-[30px] shadow-md rounded-[3px] bg-white border border-solid border-gray-300 self-center items-center">
      {submitError && <h4 className="text-[#FF0000] mb-0">{submitError}</h4>}
      <h4>Billing Details</h4>
      <div className="flex flex-col items-center min-[600px]:w-2/3 w-[95%] self-center mb-3">
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3 justify-center items-start">
            <Label text="Address" />
          </div>
          <div className="flex flex-col w-2/3 items-start">
            <TextField
              className="w-full max-w-[500px]"
              name="paymentAddress"
              placeholder="Address"
              value={billingInfo?.paymentAddress}
              onChange={onChange}
              size="small"
              margin="normal"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3 justify-center items-start">
            <Label text="City" />
          </div>
          <div className="flex flex-col w-2/3 items-start">
            <TextField
              name="paymentCity"
              placeholder="City"
              value={billingInfo?.paymentCity}
              onChange={onChange}
              className="w-full max-w-[500px]"
              size="small"
              margin="normal"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3 justify-center items-start">
            <Label text="Country" />
          </div>
          <div className="flex flex-col w-2/3 items-start">
            <FormDropdown
              type="countries"
              name="paymentCountry"
              label="Country"
              placeholder={
                billingInfo?.paymentCountry
                  ? findCountryByValue(billingInfo?.paymentCountry)
                  : 'Select... (required *)'
              }
              selectedOption={billingInfo?.paymentCountry}
              onChange={setPaymentCountry}
              disabled={disabled}
            />
          </div>
        </div>
        {billingInfo?.paymentCountry === 'US' && (
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-1/3 justify-center items-start">
              <Label text="State" />
            </div>
            <div className="flex flex-col w-2/3 items-start">
              <FormDropdown
                type="states"
                name="paymentState"
                label="State"
                placeholder={
                  billingInfo?.paymentState
                    ? findStateByValue(billingInfo?.paymentState)
                    : 'Select...'
                }
                selectedOption={billingInfo?.paymentState}
                onChange={setPaymentState}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        <div className="flex flex-row w-full mb-[25px]">
          <div className="flex flex-col w-1/3 justify-center items-start">
            <Label text="ZIP" />
          </div>
          <div className="flex flex-col w-2/3 items-start">
            <TextField
              name="paymentZIP"
              placeholder="ZIP"
              value={billingInfo?.paymentZIP}
              onChange={onChange}
              className="w-full max-w-[500px]"
              size="small"
              margin="normal"
              disabled={disabled}
            />
          </div>
        </div>
        {showSubmitButton ? (
          <div className="flex flex-row">
            {disabled ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setDisabled(false);
                  setisProcessCompleted(false);
                }}
              >
                Edit Billing Details
              </Button>
            ) : (
              <div className="flex flex-row gap-x-3 justify-around">
                <ButtonWithLoader
                  isLoading={isLoading}
                  isProcessCompleted={isProcessCompleted}
                  onClick={handleSubmit}
                  title={isLoading ? 'Processing...' : `Save Billing Details`}
                  titleOnComplete="Successfully Updated"
                  disabled={isLoading}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setBillingInfo(prevBillingInfo);
                    setDisabled(true);
                    setSubmitError(null);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BillingDetails;
