import { Component } from 'react';
import { TimePicker } from '@material-ui/pickers';

const styles = {
  sizeMobile: {
    fontSize: '10pt',
  },
};
class CustomTimePicker extends Component {
  constructor() {
    super();
    this.state = {};

    this.onChange = this.onChange.bind(this);
  }

  onChange(date) {
    //eslint-disable-line no-unused-vars
    const { onTimeChange, name } = this.props;
    onTimeChange({ target: { name: name } }, date.toDate());
  }

  render() {
    const {
      name,
      value,
      fullWidth,
      style,
      placeholder,
      disabled,
      helperText,
      error,
      isDesktop,
    } = this.props;

    return (
      <TimePicker
        minutesStep={5}
        onChange={this.onChange}
        name={name}
        value={value}
        fullWidth={fullWidth}
        style={style == null ? styles.timePicker : style}
        placeholder={placeholder}
        disabled={disabled == null ? false : disabled}
        className={isDesktop ? styles.timePicker : 'sizeMobile'}
        helperText={helperText}
        error={error}
        InputProps={{ disableUnderline: true }}
      />
    );
  }
}

export default CustomTimePicker;
