const initState = {
  loading: false,
  registrationDisplay: 0,
  totalRegistrations: 0,
  errors: {},
  newRegistrations: [],
  confirmedRegistrations: [],
  rejectedRegistrations: [],
};

const RegistrationReducer = function (state = initState, action) {
  switch (action.type) {
    case 'LOGOUT': {
      return initState;
    }

    case 'CLEAR_REGISTRATIONS': {
      return initState;
    }

    case 'NEW_REGISTRATIONS_PENDING': {
      return { ...state, loading: true };
    }

    case 'NEW_REGISTRATIONS_FULFILLED': {
      return {
        ...state,
        totalRegistrations: action.payload.totalRegistrations,
        newRegistrations: [
          ...state.newRegistrations,
          ...action.payload.registrations,
        ],
        loading: false,
        registrationDisplay: 0,
      };
    }

    case 'CONFIRMED_REGISTRATIONS_PENDING': {
      return { ...state, loading: true };
    }

    case 'CONFIRMED_REGISTRATIONS_FULFILLED': {
      return {
        ...state,
        confirmedRegistrations: action.payload,
        registrationDisplay: 1,
        loading: false,
      };
    }

    case 'REJECTED_REGISTRATIONS_PENDING': {
      return { ...state, loading: true };
    }

    case 'REJECTED_REGISTRATIONS_FULFILLED': {
      return {
        ...state,
        rejectedRegistrations: action.payload,
        registrationDisplay: 2,
        loading: false,
      };
    }

    case 'CONFIRM_REGISTRATION_PENDING': {
      return { ...state, loading: true };
    }

    case 'CONFIRM_REGISTRATION_FULFILLED': {
      return {
        ...state,
        newRegistrations: state.newRegistrations.filter(
          (reg) => reg.registrationId !== action.payload
        ),
        confirmedRegistrations: state.confirmedRegistrations.filter(
          (reg) => reg.registrationId !== action.payload
        ),
        rejectedRegistrations: state.rejectedRegistrations.filter(
          (reg) => reg.registrationId !== action.payload
        ),
        loading: false,
      };
    }

    case 'REJECT_REGISTRATION_PENDING': {
      return { ...state, loading: true };
    }

    case 'REJECT_REGISTRATION_FULFILLED': {
      return {
        ...state,
        newRegistrations: state.newRegistrations.filter(
          (reg) => reg.registrationId !== action.payload
        ),
        confirmedRegistrations: state.confirmedRegistrations.filter(
          (reg) => reg.registrationId !== action.payload
        ),
        rejectedRegistrations: state.rejectedRegistrations.filter(
          (reg) => reg.registrationId !== action.payload
        ),
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default RegistrationReducer;
