export const BASE_URL = process.env.REACT_APP_VIRTUALASSISSTANT_API_ADDRESS;

const API_BASE_URL = BASE_URL + '/api';

// TODO: Refactor paths (create some helper, just dont repeat code like this)
// TODO: Refactor paths to lowercase
export const AUTH_TOKEN = BASE_URL + '/oauth2/GenerateToken';
export const VALIDATE_DATA = API_BASE_URL + '/ValidateData';
export const LOGGED_USER = API_BASE_URL + '/LoggedUser';
export const CHANGE_PASSWORD = API_BASE_URL + '/ChangePassword';
export const RECOVER_PASSWORD = API_BASE_URL + '/PasswordRecovery';
export const RESET_PASSWORD = API_BASE_URL + '/ResetPassword';
export const USER = API_BASE_URL + '/User';
export const USER_EMAIL = USER + '/ValidateEmail';
export const USER_BASE = API_BASE_URL + '/UserBase';
export const ADMIN_CLIENT_OWNER = API_BASE_URL + '/AdminClientOwner';
export const ADMIN_CLIENT_COMPANY = API_BASE_URL + '/AdminClientCompany';
export const CALENDAR_USERS = API_BASE_URL + '/CalendarUsers';
export const PHONEBOOK_USERS = API_BASE_URL + '/PhonebookUsers';
export const ADMIN_CLIENT_OWNER_USERS = API_BASE_URL + '/AdminClientOwnerUsers';
export const USER_ACTIVITY = API_BASE_URL + '/User/LogActivity';
export const COMPANY = API_BASE_URL + '/Company';
export const REGISTRATION = API_BASE_URL + '/Registration';
export const UPDATE_REGISTRATION =
  API_BASE_URL + '/User/updateuserregistration';
// export const NEW_REGISTRATIONS = API_BASE_URL + '/NewRegistrations';
export const NEW_REGISTRATIONS =
  API_BASE_URL + '/Registration/usersnotcompletedregistration';
export const CONFIRMED_REGISTRATIONS = API_BASE_URL + '/ConfirmedRegistrations';
export const REJECTED_REGISTRATIONS = API_BASE_URL + '/RejectedRegistrations';
export const CONFIRM_REGISTRATION = API_BASE_URL + '/ConfirmRegistration';
export const CLIENT = API_BASE_URL + '/Client';
export const CLIENTS_FOR_ADMIN_CLIENT = API_BASE_URL + '/Clients/AdminClient';
export const ASSISTANT = API_BASE_URL + '/Assistant';
export const AVAILABLE_ASSISTANTS = API_BASE_URL + '/AvailableAssistants';
export const ASSIGN_ASSISTANT = API_BASE_URL + '/AssignAssistant';
export const UNASSIGN_ASSISTANT = API_BASE_URL + '/UnassignAssistant';
export const TASK = API_BASE_URL + '/Task';
export const TASK_AVAILABLE_USERS_FOR_AC_COMPANY =
  API_BASE_URL + '/TaskAvailableUsers/AdminClientCompany';
export const TASKS = API_BASE_URL + '/Tasks';
export const TASK_STATUSES = API_BASE_URL + '/TaskStatuses';
export const TASK_STATUS = API_BASE_URL + '/TaskStatus';
export const TASK_ASSIGNEE = API_BASE_URL + '/TaskAssignee';
export const TASK_COMMENTS = API_BASE_URL + '/TaskComments';
export const LATEST_COMMENTS = API_BASE_URL + '/LatestComments';
export const DOCUMENTS = API_BASE_URL + '/Documents';
export const LATEST_DOCUMENTS = API_BASE_URL + '/LatestDocuments';
export const DOCUMENT = API_BASE_URL + '/Document';
export const SETTINGS = API_BASE_URL + '/Settings';
export const AVATAR = API_BASE_URL + '/Avatar';
export const CHAT_MESSAGES = API_BASE_URL + '/ChatMessages';
export const CHAT_ROOM_MESSAGES = API_BASE_URL + '/Chat/RoomMessages';
export const CHAT_GROUPS_OVERSEEN = API_BASE_URL + '/ChatMessages/Overseen/';
export const CHAT_ROOM_MESSAGES_OVERSEEN = API_BASE_URL + '/ChatRoomMessages';
export const CHAT_ROOM_MESSAGES_OVERSEEN_ADDITIONAL =
  API_BASE_URL + '/Chat/RoomMessages/Overseen';
export const GUEST_TASK = API_BASE_URL + '/GuestTask';
// export const GUEST_TASKS = API_BASE_URL + '/GuestTasks';
export const GUEST_AVAILABLE_TASKS = API_BASE_URL + '/GuestAvailableTasks';
export const GUEST_EXISTING = API_BASE_URL + '/ExistingGuests';
export const GUEST_USER = API_BASE_URL + '/UserGuests';
export const DELETE_GUEST_TASK = API_BASE_URL + '/DeleteGuestTask';
export const CREATE_USER = API_BASE_URL + '/CreateUser';
// admin client send registration email
export const CREATE_USER_WITH_REGISTRATION_LINK =
  API_BASE_URL + '/SendRegistrationLink';
export const REGISTER_USER_WITH_LINK = API_BASE_URL + '/RegisterUserWithLink';
export const GET_EMAIL_INVITATIONS = API_BASE_URL + '/GetEmailInvitations';
export const RESEND_REGISTRATION_LINK =
  API_BASE_URL + '/ResendRegistrationLink';

export const SPECIFIC_USER = API_BASE_URL + '/SpecificUser';
export const USER_SETTINGS = API_BASE_URL + '/UserSettings';
export const NOTIFICATION_FREQUENCIES =
  API_BASE_URL + '/NotificationFrequencies';
export const CALENDAR_ENTRY_TYPES = API_BASE_URL + '/CalendarEntryTypes';
export const CALENDAR_ENTRY_RECURRENCES =
  API_BASE_URL + '/CalendarEntryRecurrences';
export const CALENDAR_ENTRIES = API_BASE_URL + '/CalendarEntries';
export const CALENDAR_ENTRY = API_BASE_URL + '/CalendarEntry';
export const CALENDAR_ENTRY_REMINDER_TIME =
  API_BASE_URL + '/CalendarEntry/ReminderTime';
export const CALENDAR_ENTRY_SEEN = API_BASE_URL + '/CalendarEntry/Seen';
export const CALENDAR_MONTHLY_AVAILABILITY =
  API_BASE_URL + '/CalendarEntry/MonthlyAvailability';
export const PHONEBOOK_CONTACTS = API_BASE_URL + '/Contacts';
export const PHONEBOOK_AC_COMPANY_CONTACTS =
  API_BASE_URL + '/Contacts/AdminClientCompany';
export const PHONEBOOK_CONTACTS_DELETE = API_BASE_URL + '/DeleteContacts';
export const PHONEBOOK_CONTACT = API_BASE_URL + '/Contact';
export const PHONEBOOK_CONTACT_DETAIL = API_BASE_URL + '/ContactDetail';
export const PHONEBOOK_CONTACT_DETAIL_TYPES =
  API_BASE_URL + '/ContactDetailTypes';
export const PUSH_ON = API_BASE_URL + '/PushOn';
export const PUSH_ON_SETTINGS = PUSH_ON + '/Settings';
export const PUSH_ON_CLIENT_DATA = PUSH_ON + '/ClientSettings';
export const PUSH_ON_TASK = PUSH_ON + '/Task';
export const NOTIFICATION_DATA = API_BASE_URL + '/Notification/Data';
export const ADMIN_NOTIFICATION_DATA =
  API_BASE_URL + '/Notification/Data/Admin';
export const TASK_DOCUMENT_NOTIFICATION_DATA =
  API_BASE_URL + '/Notification/Data/Task/Document';
export const TASK_COMMENT_NOTIFICATION_DATA =
  API_BASE_URL + '/Notification/Data/Task/Comment';
export const TASK_NOTIFICATION_DATA = API_BASE_URL + '/Notification/Data/Task';
export const CALENDAR_NOTIFICATION_DATA =
  API_BASE_URL + '/Notification/Data/Calendar';
export const SET_TASK_COMMENTS_SEEN = API_BASE_URL + '/Task/Comments/Seen';
export const CREATE_USER_LIMIT = API_BASE_URL + '/counters/usercount';
export const USER_DATA = (id) => API_BASE_URL + `/SpecificUser/${id}`;
export const GET_USER_CHATROOMS = (id) => API_BASE_URL + `/ChatMessages/${id}`;
export const FORWARD_MESASGE = API_BASE_URL + '/Chat/ForwardMessage';

// Payment Settings
export const GET_STRIPE_CUSTOMER = API_BASE_URL + '/customers/stripe-customer-details';
export const GET_PAYMENT_METHODS = API_BASE_URL + '/customers/stripe-payment-methods';
export const ATTACH_PAYMENT_METHOD = (id) => API_BASE_URL + `/customers/attach-payment-methods/${id}`;
export const DELETE_PAYMENT_METHOD = (id) => API_BASE_URL + `/customers/detach-payment-methods/${id}`;
export const CHANGE_DEFAULT_PAYMENT_METHOD = (id) => API_BASE_URL + `/customers/set-default-payment-methods/${id}`;
