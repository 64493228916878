export const AUTHENTICATE = 'AUTHENTICATE';

export function authenticate(username, password) {
  return { type: AUTHENTICATE, username: username, password: password };
}

export const SET_SUBSCRIBE = 'SET_SUBSCRIBE';

export function setSubscribe(value) {
  return { type: SET_SUBSCRIBE, payload: { subscribed: value } };
}

export const REGISTER = 'REGISTER';

export function register(registrationData) {
  return { type: REGISTER, registrationData };
}

export const UPDATE_REGISTER = 'UPDATE_REGISTER';

export function updateRegister(registrationData) {
  return { type: UPDATE_REGISTER, registrationData };
}

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';

export function recoverPassword(data) {
  return { type: RECOVER_PASSWORD, data };
}

export const RESET_PASSWORD = 'RESET_PASSWORD';

export function resetPassword(data) {
  return { type: RESET_PASSWORD, data };
}

export const LOGOUT = 'LOGOUT';

export function logout() {
  return { type: LOGOUT };
}
