import '../../components/Products/ProductsStripe.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';

import lines from './lines.svg';
import check from './check.svg';
import ButtonSvg from './ButtonSvg';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useWindowDimensions } from '../../utilities/constants';
import { motion } from 'framer-motion';
import { SkeletonLoader } from '../../pure-ui/SkeletonLoader/SkeletonLoader';

export const BASE_URL = process.env.REACT_APP_VIRTUALASSISSTANT_API_ADDRESS;
const API_BASE_URL = BASE_URL + '/api';

const ProductRegistration = (props) => {
  const { isAdded, onAdd } = props;
  const { innerWidth } = useWindowDimensions();
  const [products, setProducts] = useState([]);
  const [nextProducts, setNextProducts] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setisLoading(true)
      try {
        const { data } = await axios.get(`${API_BASE_URL}/products`)
        const newProducts = data.sort((p1, p2) => {
          if (p1.ProductUserCount && p2.ProductUserCount) {
            return p1.ProductUserCount - p2.ProductUserCount;
          } else if (p1.ProductUserCount) {
            return -1;
          } else if (p2.ProductUserCount) {
            return 1;
          }

          return p1.PriceUnitUnitAmountDecimal - p2.PriceUnitUnitAmountDecimal;
        });
        setProducts(newProducts);
        // Select zero package automatically
        const freePackage = newProducts.filter((prod) => prod.PriceUnitUnitAmountDecimal === 0 && prod.ProductAppType === '3')[0]
        onAdd(
          freePackage?.ProductId,
          freePackage?.PriceId,
          freePackage?.PriceUnitUnitAmountDecimal,
          false
        )
      } catch (error) {
        console.log(error)
      } finally { setisLoading(false) }
    }
    fetchProducts()
  }, []);

  const createFeaturesArray = (product) => {
    return [
      `Allowed Users: ${product.ProductUserCount}`,
      'Chat, Task, Calendar, Phonebook',
      'Push on settings',
      'Users Manager',
    ];
  };

  return (
    <Grid container spacing={2} direction="column" alignContent="center">
      <div className="relative p-6 flex flex-col w-screen overflow-hidden box-border">
        <div className="relative z-2 self-center w-full box-border">
          <div className="max-w-[50rem] mx-auto mb-2 md:text-center">
            <div className="uppercase flex items-center justify-center">
              <div className="mx-3 text-[#ADA8C3]">
                Choose at least one user package!
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className='flex flex-row w-full justify-center items-center'>
              <div className='flex lg:flex-row flex-col w-full max-w-[1200px] gap-5'>
                {[1, 2, 3].map((box) => (
                  <div key={box} className='flex flex-col lg:w-1/3 w-full p-3'>
                    <SkeletonLoader className='h-[125px] w-full rounded-xl mb-5' />
                    <SkeletonLoader className='h-4 w-[90%] rounded-xl mb-2' />
                    <SkeletonLoader className='h-4 w-[80%] rounded-xl' />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-col w-full sm:px-10 box-border">
                <motion.div
                  key={nextProducts ? '1' : '0'}
                  initial={{ opacity: 0, x: nextProducts ? '100%' : '-100%' }}
                  animate="visible"
                  variants={{
                    visible: { opacity: 1, x: 0 },
                  }}
                  transition={{ duration: 0.5, ease: 'easeInOut' }}
                  className="flex lg:flex-row flex-col gap-[1rem] w-full max-w-[1400px] self-center relative box-border"
                >
                  {products
                    .filter((prod) => prod.ProductUserCount !== '')
                    .slice(
                      innerWidth < 1024 ? 0 : nextProducts ? 2 : 0,
                      innerWidth < 1024 ? 5 : nextProducts ? 5 : 3
                    )
                    .map((item, index) => (
                      <div
                        key={index}
                        className={`${isAdded(item.ProductId) ? 'bg-[#90EE90]' : ''
                          } box-border min-[1250px]:w-1/3 w-full h-full px-6 border border-[#252134] border-solid rounded-[2rem] even:py-14 odd:py-8 odd:my-4 [&>h4]:first:text-[#FFC876] [&>h4]:even:text-[#AC6AFF]`}
                      >
                        <h4
                          className={`text-[2rem] leading-normal mb-4 mt-0 ${index === 2 ? 'text-[#FF776F]' : ''
                            }`}
                        >
                          {item.ProductName}
                        </h4>

                        <p className="font-light text-[0.875rem] leading-6 md:text-base min-h-[4rem] mb-3">
                          {item.PriceUnitUnitAmountDecimal === 0
                            ? 'Limited Features'
                            : 'Limits Removed completely'}
                        </p>

                        <div className="flex items-center h-[5.5rem] mb-6">
                          <div className="text-[2rem] leading-normal md:text-[2.5rem]">
                            $
                          </div>
                          <div className="md:text-[5.5rem] text-[4rem] leading-none font-bold">
                            {(item.PriceUnitUnitAmountDecimal / 100).toFixed(2)}
                          </div>
                        </div>
                        <a
                          onClick={() =>
                            onAdd(
                              item.ProductId,
                              item.PriceId,
                              item.PriceUnitUnitAmountDecimal,
                              false
                            )
                          }
                          className="button cursor-pointer relative inline-flex items-center justify-center h-11 transition-colors hover:text-[#AC6AFF] px-7 w-full mb-6 box-border"
                        >
                          <span className="relative z-10">
                            {isAdded(item.ProductId) ? 'Selected' : 'Select'}
                          </span>
                          {ButtonSvg(false)}
                        </a>

                        <ul className="p-0">
                          {createFeaturesArray(item).map((feature, index) => (
                            <li
                              key={index}
                              className="flex items-start py-5 border-t border-[#252134] border-solid border-0 box-border"
                            >
                              <img
                                src={check}
                                width={24}
                                height={24}
                                alt="Check"
                              />
                              <p
                                className={`${index === 0 ? 'font-bold' : 'font-light'
                                  } text-[0.875rem] leading-6 md:text-base ml-4 my-0 text-left`}
                              >
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  <div
                    className={`absolute flex flex-row w-full justify-between top-1/2 -translate-y-1/2 ${innerWidth < 1024 ? 'hidden' : ''
                      }`}
                  >
                    <IconButton
                      onClick={() => setNextProducts(false)}
                      disabled={!nextProducts}
                      className="-translate-x-full rotate-180"
                    >
                      <DoubleArrowIcon
                        color={!nextProducts ? 'disabled' : 'primary'}
                        fontSize="large"
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => setNextProducts(true)}
                      disabled={nextProducts}
                      className="translate-x-full"
                    >
                      <DoubleArrowIcon
                        color={nextProducts ? 'disabled' : 'primary'}
                        fontSize="large"
                      />
                    </IconButton>
                  </div>
                  <div className="hidden lg:block absolute top-1/2 left-0 -translate-x-full w-[92.5rem] h-[11.0625rem] -translate-y-1/2 pointer-events-none">
                    <img
                      className="w-full"
                      src={lines}
                      width={1480}
                      height={177}
                      alt="Lines"
                    />
                  </div>
                  <div className="hidden lg:block absolute top-1/2 left-full w-[92.5rem] h-[11.0625rem] -translate-y-1/2 -scale-x-100 pointer-events-none">
                    <img
                      className="w-full"
                      src={lines}
                      width={1480}
                      height={177}
                      alt="Lines"
                    />
                  </div>
                </motion.div>
              </div>
              <h2 className="my-4">Add-ons you might like!</h2>
              <div className="flex flex-col w-full sm:px-10 box-border">
                <div className="flex lg:flex-row flex-col gap-[1rem] w-full max-w-[1400px] self-center relative box-border">
                  {products
                    .filter((prod) => prod.ProductUserCount === '')
                    .map((item, index) => (
                      <div
                        key={index}
                        className={`${isAdded(item.ProductId) ? 'bg-[#90EE90]' : ''
                          } box-border min-[1250px]:w-1/3 w-full h-full px-6 border border-[#252134] border-solid rounded-[2rem] even:py-14 odd:py-8 odd:my-4 [&>h4]:first:text-[#FFC876] [&>h4]:even:text-[#AC6AFF]`}
                      >
                        <h4
                          className={`text-[2rem] leading-normal mb-4 mt-0 ${index === 2 ? 'text-[#FF776F]' : ''
                            }`}
                        >
                          {item.ProductName}
                        </h4>

                        <p className="font-light text-[0.875rem] leading-6 md:text-base min-h-[4rem] mb-3 text-justify">
                          {item.ProductDescription}
                        </p>

                        <div className="flex items-center h-[5.5rem] mb-6">
                          <div className="text-[2rem] leading-normal md:text-[2.5rem]">
                            $
                          </div>
                          <div className="md:text-[4.5rem] text-[3rem] leading-none font-bold">
                            {(item.PriceUnitUnitAmountDecimal / 100).toFixed(2)}
                          </div>
                        </div>
                        <a
                          onClick={() =>
                            onAdd(
                              item.ProductId,
                              item.PriceId,
                              item.PriceUnitUnitAmountDecimal,
                              true
                            )
                          }
                          className="button cursor-pointer relative inline-flex items-center justify-center h-11 transition-colors hover:text-[#AC6AFF] px-7 w-full mb-6 box-border"
                        >
                          <span className="relative z-10">
                            {isAdded(item.ProductId)
                              ? 'Added'
                              : 'Add this Product'}
                          </span>
                          {ButtonSvg(false)}
                        </a>
                      </div>
                    ))}
                  <div className="hidden lg:block absolute top-1/2 left-0 -translate-x-full w-[92.5rem] h-[11.0625rem] -translate-y-1/2 pointer-events-none">
                    <img
                      className="w-full"
                      src={lines}
                      width={1480}
                      height={177}
                      alt="Lines"
                    />
                  </div>
                  <div className="hidden lg:block absolute top-1/2 left-full w-[92.5rem] h-[11.0625rem] -translate-y-1/2 -scale-x-100 pointer-events-none">
                    <img
                      className="w-full"
                      src={lines}
                      width={1480}
                      height={177}
                      alt="Lines"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default ProductRegistration;
