const initState = {
  loading: false,
  clientSecretaries: [],
};

const SecretariesReducer = function (state = initState, action) {
  switch (action.type) {
    case 'GET_SECRETARIES_FOR_CLIENT_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_SECRETARIES_FOR_CLIENT_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_SECRETARIES_FOR_CLIENT_FULFILLED': {
      return {
        ...state,
        loading: false,
        clientSecretaries: action.payload,
      };
    }

    default:
      return state;
  }
};

export default SecretariesReducer;
