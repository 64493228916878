import { validatePassword, validateUsername } from './password-validations';

import Validator from 'validator';
import isEmpty from 'is-empty';
import { validateData } from './../../api/api-functions';

export async function validatePersonalInfo(
  data,
  checkPassword,
  checkEmail,
  noValid
) {
  const errors = {};

  if (Validator.isEmpty(data.firstname)) {
    errors.firstname = 'First name is required';
  }

  if (Validator.isEmpty(data.lastname)) {
    errors.lastname = 'Last name is required';
  }

  if (checkEmail) {
    if (Validator.isEmpty(data.emailAddress)) {
      errors.emailAddress = 'Email is required';
    } else if (!Validator.isEmail(data.emailAddress)) {
      errors.emailAddress = 'Must enter valid email';
    }
  }

  const { message, valid } = validateUsername(data.username);
  if (!valid) errors.username = message;

  const regexPhone = /^\+?\d{7,15}$/;

  if (Validator.isEmpty(data.phoneNumber)) {
    errors.phoneNumber = 'Phone number is required';
  } else if (!regexPhone.test(data.phoneNumber)) {
    errors.phoneNumber = 'Phone number is not in right format';
  }

  if (checkPassword) {
    const passwordValidationResult = validatePassword(data.password);

    if (!passwordValidationResult.valid) {
      errors.password = passwordValidationResult.message;
    }

    if (Validator.isEmpty(data.repeatPassword)) {
      errors.repeatPassword = 'Repeat password is required';
    } else if (!Validator.equals(data.password, data.repeatPassword)) {
      errors.repeatPassword = "Passwords don't match";
    }
  }

  if (noValid) {
    return { errors, valid: isEmpty(errors) };
  } else {
    const updateUser = localStorage.getItem('updateUser');
    if (isEmpty(errors) && !updateUser) {
      await validateData({
        Username: data.username,
        Email: data.emailAddress,
      }).then((result) => {
        if (
          result.Success &&
          (result.Result.UsernameErrorMessage !== null ||
            result.Result.EmailErrorMessage !== null)
        ) {
          errors.username = result.Result.UsernameErrorMessage;
          errors.emailAddress = result.Result.EmailErrorMessage;
        }
      });
    }

    return { errors, valid: isEmpty(errors) };
  }
}

export function validateCompanyInfo(data) {
  const errors = {};

  if (!Validator.isEmpty(data.ceoEmail) && !Validator.isEmail(data.ceoEmail)) {
    errors.ceoEmail = 'Must enter valid email';
  } else if (
    !Validator.isEmpty(data.ceoPhoneNumber) &&
    !Validator.isMobilePhone(data.ceoPhoneNumber, 'any')
  ) {
    errors.ceoPhoneNumber = 'Phone number is not in right format';
  }

  return {
    errors,
    valid: isEmpty(errors),
  };
}

export function validateCompanyWorkingHours(data) {
  const errors = {};
  if (data.workDayMonday) {
    if (data.workDayMondayFrom === null) {
      errors.workDayMondayFrom = 'Please select';
    }
    if (data.workDayMondayTo === null) {
      errors.workDayMondayTo = 'Please select';
    }
    // if(data.workDayMondayFrom>=data.workDayMondayTo){
    //     errors.workDayMondayTo = "Must be after start hours";
    // }
  }

  if (data.workDayTuesday) {
    if (data.workDayTuesdayFrom === null) {
      errors.workDayTuesdayFrom = 'Please select';
    }
    if (data.workDayTuesdayTo === null) {
      errors.workDayTuesdayTo = 'Please select';
    }
    // if(data.workDayTuesdayFrom>=data.workDayTuesdayTo){
    //     errors.workDayTuesdayTo = "Must be after start hours";
    // }
  }

  if (data.workDayWednesday) {
    if (data.workDayWednesdayFrom === null) {
      errors.workDayWednesdayFrom = 'Please select';
    }
    if (data.workDayWednesdayTo === null) {
      errors.workDayWednesdayTo = 'Please select';
    }
    // if(data.workDayWednesdayFrom>=data.workDayWednesdayTo){
    //     errors.workDayWednesdayTo = "Must be after start hours";
    // }
  }

  if (data.workDayThursday) {
    if (data.workDayThursdayFrom === null) {
      errors.workDayThursdayFrom = 'Please select';
    }
    if (data.workDayThursdayTo === null) {
      errors.workDayThursdayTo = 'Please select';
    }
    // if(data.workDayThursdayFrom>=data.workDayThursdayTo){
    //     errors.workDayThursdayTo = "Must be after start hours";
    // }
  }

  if (data.workDayFriday) {
    if (data.workDayFridayFrom === null) {
      errors.workDayFridayFrom = 'Please select';
    }
    if (data.workDayFridayTo === null) {
      errors.workDayFridayTo = 'Please select';
    }
    // if(data.workDayFridayFrom>=data.workDayFridayTo){
    //     errors.workDayFridayTo = "Must be after start hours";
    // }
  }

  if (data.workDaySaturday) {
    if (data.workDaySaturdayFrom === null) {
      errors.workDaySaturdayFrom = 'Please select';
    }
    if (data.workDaySaturdayTo === null) {
      errors.workDaySaturdayTo = 'Please select';
    }
    // if(data.workDaySaturdayFrom>=data.workDaySaturdayTo){
    //     errors.workDaySaturdayTo = "Must be after start hours";
    // }
  }

  if (data.workDaySunday) {
    if (data.workDaySundayFrom === null) {
      errors.workDaySundayFrom = 'Please select';
    }
    if (data.workDaySundayTo === null) {
      errors.workDaySundayTo = 'Please select';
    }
    // if(data.workDaySundayFrom>=data.workDaySundayTo){
    //     errors.workDaySundayTo = "Must be after start hours";
    // }
  }

  if (Validator.isEmpty(data.paymentAddress)) {
    errors.paymentAddress = 'Address is required';
  }

  if (Validator.isEmpty(data.paymentCity)) {
    errors.paymentCity = 'City is required';
  }

  if (Validator.isEmpty(data.paymentZIP)) {
    errors.paymentZIP = 'ZIP is required';
  }

  if (data.paymentCountry === null) {
    errors.paymentCountry = 'Please select Country';
  }
  if (data.paymentCountry === 'US') {
    if (data.paymentState === null) {
      errors.paymentState = 'Please select State';
    }
  }

  return {
    errors,
    valid: isEmpty(errors),
  };
}

export function validateUserCompanyWorkingHours(data) {
  const errors = {};
  if (data.workDayMonday) {
    if (data.workDayMondayFrom === null) {
      errors.workDayMondayFrom = 'Please select';
    }
    if (data.workDayMondayTo === null) {
      errors.workDayMondayTo = 'Please select';
    }
    // if(data.workDayMondayFrom>=data.workDayMondayTo){
    //     errors.workDayMondayTo = "Must be after start hours";
    // }
  }

  if (data.workDayTuesday) {
    if (data.workDayTuesdayFrom === null) {
      errors.workDayTuesdayFrom = 'Please select';
    }
    if (data.workDayTuesdayTo === null) {
      errors.workDayTuesdayTo = 'Please select';
    }
    // if(data.workDayTuesdayFrom>=data.workDayTuesdayTo){
    //     errors.workDayTuesdayTo = "Must be after start hours";
    // }
  }

  if (data.workDayWednesday) {
    if (data.workDayWednesdayFrom === null) {
      errors.workDayWednesdayFrom = 'Please select';
    }
    if (data.workDayWednesdayTo === null) {
      errors.workDayWednesdayTo = 'Please select';
    }
    // if(data.workDayWednesdayFrom>=data.workDayWednesdayTo){
    //     errors.workDayWednesdayTo = "Must be after start hours";
    // }
  }

  if (data.workDayThursday) {
    if (data.workDayThursdayFrom === null) {
      errors.workDayThursdayFrom = 'Please select';
    }
    if (data.workDayThursdayTo === null) {
      errors.workDayThursdayTo = 'Please select';
    }
    // if(data.workDayThursdayFrom>=data.workDayThursdayTo){
    //     errors.workDayThursdayTo = "Must be after start hours";
    // }
  }

  if (data.workDayFriday) {
    if (data.workDayFridayFrom === null) {
      errors.workDayFridayFrom = 'Please select';
    }
    if (data.workDayFridayTo === null) {
      errors.workDayFridayTo = 'Please select';
    }
    // if(data.workDayFridayFrom>=data.workDayFridayTo){
    //     errors.workDayFridayTo = "Must be after start hours";
    // }
  }

  if (data.workDaySaturday) {
    if (data.workDaySaturdayFrom === null) {
      errors.workDaySaturdayFrom = 'Please select';
    }
    if (data.workDaySaturdayTo === null) {
      errors.workDaySaturdayTo = 'Please select';
    }
    // if(data.workDaySaturdayFrom>=data.workDaySaturdayTo){
    //     errors.workDaySaturdayTo = "Must be after start hours";
    // }
  }

  if (data.workDaySunday) {
    if (data.workDaySundayFrom === null) {
      errors.workDaySundayFrom = 'Please select';
    }
    if (data.workDaySundayTo === null) {
      errors.workDaySundayTo = 'Please select';
    }
    // if(data.workDaySundayFrom>=data.workDaySundayTo){
    //     errors.workDaySundayTo = "Must be after start hours";
    // }
  }

  return {
    errors,
    valid: isEmpty(errors),
  };
}
