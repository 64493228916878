import {
  ADD_NEW_SUBSCRIPTION_ITEM,
  CANCEL_ACCOUNT,
  DOWNGRADE_USER_PACKAGE,
  MARK_INVOICE_AS_PAID,
  REACTIVATE_ACCOUNT,
  REMOVE_SERVICE,
  UPGRADE_USER_PACKAGE,
  UPLOAD_FILE,
} from '../actions/user-actions';
import {
  addNewGuestToTask,
  addNewSubscriptionItem,
  assignAssistantToClient,
  authenticateUser,
  cancelAccount,
  changePassword,
  checkBillingInfo,
  checkUserName,
  confirmRegistration,
  createTask,
  deleteCalendarEntry,
  deleteContact,
  deleteContacts,
  deleteDocument,
  deleteTask,
  deleteTaskComment,
  downgradeUserPackage,
  generateToken,
  getAdminClientCompanyContacts,
  getAdminClientCompanyWithOwner,
  getAdminClientOwnerClients,
  getAdminClientOwnersUsers,
  getAdminNotificationData,
  getAdminUsersLimit,
  getAssistantsForClient,
  getAvailableAssistants,
  getCalendarEntries,
  getCalendarEntryRecurrences,
  getCalendarEntryReminderTime,
  getCalendarEntryTypes,
  getCalendarMonthlyAvailability,
  getCalendarNotificationData,
  getCalendarUsers,
  getChatMessages,
  getChatNotificationData,
  getChatRoomMessages,
  getClients,
  getClientsForAdminClient,
  getConfirmedRegistrations,
  getContactDetailTypes,
  getContacts,
  getCreateUsersLimit,
  getCustomer,
  getDocuments,
  getGlobalSettings,
  getGuestAvailableTasks,
  getGuestsForTask,
  getGuestsForUser,
  getLatestComments,
  getLatestDocuments,
  getLoggedUser,
  getNewRegistrations,
  getNotificationData,
  getNotificationFrequencies,
  getOverseenAdditinalChatRoomMessages,
  getOverseenChatGroups,
  getOverseenChatRoomMessages,
  getPhonebookUsers,
  getPushOnSettings,
  getRejectedRegistrations,
  getTask,
  getTaskAvailableUsersForCompany,
  getTaskCommentNotificationData,
  getTaskComments,
  getTaskDocumentNotificationData,
  getTaskNotificationData,
  getTaskStatuses,
  getTasks,
  getUserBase,
  getUserSettings,
  getUsers,
  importContacts,
  insertUserSettings,
  logUserActivity,
  markInvoiceAsPaid,
  postDocument,
  postTaskAssignees,
  postTaskComment,
  postTaskStatus,
  reactivateAccount,
  recoverPassword,
  registerUser,
  rejectRegistration,
  removeService,
  saveAvatar,
  saveCalendarEntry,
  saveContact,
  saveGlobalSetting,
  savePushOnSettingsForClient,
  saveTask,
  setCalendarEntrySeen,
  setTaskCommentsSeen,
  subscribeToTaskNotifications,
  unassignAssistantFromClient,
  unsubscribeFromTaskNotifications,
  updateRegisterUser,
  updateUserSettings,
  upgradeUserPackage,
  uploadFile,
} from './api-functions';

import { CHAT_ADMIN_CLIENT_OWNER_NOT_SELECTED } from '../reducers/chat-reducer';
import { getUserChatRooms } from './chat/user-data';
import { setActiveChatRoom } from '../actions/chat-actions';
import { setAuthorizationToken } from '../utilities/api-helper';

const api_calls = (store) => (next) => (action) => {
  const token = localStorage.getItem('authToken');
  setAuthorizationToken(token);
  switch (action.type) {
    case 'AUTHENTICATE':
      store.dispatch({
        type: 'AUTHENTICATE',
        payload: authenticateUser(
          'grant_type=password&username=' +
            action.username +
            '&password=' +
            action.password
        ),
      });
      break;

    case 'CHANGE_PASSWORD':
      store.dispatch({
        type: 'CHANGE_PASSWORD',
        payload: changePassword(action.data),
      });
      break;

    case 'REGISTER':
      store.dispatch({
        type: 'REGISTER',
        payload: registerUser(action.registrationData),
      });
      break;

    case 'REGISTER_FULFILLED': {
      store.dispatch({
        type: 'GENERATE_TOKEN_REGISTRATION',
        payload: generateToken(
          'grant_type=password&username=' +
            action.payload.username +
            '&password=' +
            action.payload.password
        ),
      });
      break;
    }

    case 'GENERATE_TOKEN_REGISTRATION_FULFILLED': {
      store.dispatch({
        type: 'GET_CUSTOMER',
        payload: getCustomer(),
      });
      break;
    }

    case 'GET_CUSTOMER_FULFILLED': {
      const { address } = action.payload.customer;
      if (address.state) {
        store.dispatch({ type: 'CHECK_BILLING', payload: checkBillingInfo() });
      } else {
        store.dispatch({
          type: 'CHECK_BILLING',
          payload: checkBillingInfo('noState'),
        });
      }
      break;
    }

    case 'UPDATE_REGISTER':
      store.dispatch({
        type: 'UPDATE_REGISTER',
        payload: updateRegisterUser(action.registrationData),
      });
      break;

    case 'UPDATE_REGISTER_FULFILLED':
      store.dispatch({
        type: 'GENERATE_TOKEN_UPDATE',
        payload: generateToken(
          'grant_type=password&username=' +
            action.payload.username +
            '&password=' +
            action.payload.password
        ),
      });
      break;
    case 'GENERATE_TOKEN_UPDATE_FULFILLED':
      if (action.payload.customer.User_State) {
        store.dispatch({
          type: 'CHECK_BILLING',
          payload: checkBillingInfo(),
        });
      } else {
        store.dispatch({
          type: 'CHECK_BILLING',
          payload: checkBillingInfo('noState'),
        });
      }

      break;

    case 'UPDATE_CHAT_NOTIFICATION_DATA':
      store.dispatch({
        type: 'UPDATE_CHAT_NOTIFICATION_DATA',
        payload: getChatNotificationData(),
      });
      break;

    case 'RECOVER_PASSWORD':
      store.dispatch({
        type: 'RECOVER_PASSWORD',
        payload: recoverPassword(action.data),
      });
      break;

    case 'NEW_REGISTRATIONS':
      store.dispatch({
        type: 'NEW_REGISTRATIONS',
        payload: getNewRegistrations(
          action.payload.page,
          action.payload.pageSize
        ),
      });
      break;

    case 'CONFIRMED_REGISTRATIONS':
      store.dispatch({
        type: 'CONFIRMED_REGISTRATIONS',
        payload: getConfirmedRegistrations(),
      });
      break;

    case 'REJECTED_REGISTRATIONS':
      store.dispatch({
        type: 'REJECTED_REGISTRATIONS',
        payload: getRejectedRegistrations(),
      });
      break;

    case 'CONFIRM_REGISTRATION':
      store.dispatch({
        type: 'CONFIRM_REGISTRATION',
        payload: confirmRegistration(action.registrationId),
      });
      break;

    case 'REJECT_REGISTRATION':
      store.dispatch({
        type: 'REJECT_REGISTRATION',
        payload: rejectRegistration(action.registrationId, action.description),
      });
      break;

    case 'CREATE_TASK':
      store.dispatch({
        type: 'CREATE_TASK',
        payload: createTask(action.task),
      });
      break;

    case 'GET_TASK':
      store.dispatch({
        type: 'GET_TASK',
        payload: getTask(action.taskId),
      });
      break;

    case 'DELETE_TASK':
      store
        .dispatch({
          type: 'DELETE_TASK',
          payload: deleteTask(action.taskId),
        })
        .then(() => {
          store.dispatch({
            type: 'DO_REDIRECT',
            redirectUrl: '/tasks/' + action.userId,
          });
        });

      break;

    case 'GET_TASK_COMMENTS':
      store.dispatch({
        type: 'GET_TASK_COMMENTS',
        payload: getTaskComments(action.taskId),
      });
      break;

    case 'GET_LATEST_COMMENTS':
      store.dispatch({
        type: 'GET_LATEST_COMMENTS',
        payload: getLatestComments(action.taskId, action.commentId),
      });
      break;

    case 'GET_TASK_DOCUMENTS':
      store.dispatch({
        type: 'GET_TASK_DOCUMENTS',
        payload: getDocuments(action.taskId),
      });
      break;

    case 'GET_LATEST_DOCUMENTS':
      store.dispatch({
        type: 'GET_LATEST_DOCUMENTS',
        payload: getLatestDocuments(action.taskId, action.documentId),
      });
      break;

    case 'GET_TASK_STATUSES':
      store.dispatch({
        type: 'GET_TASK_STATUSES',
        payload: getTaskStatuses(),
      });
      break;

    case 'GET_TASK_GUESTS':
      store.dispatch({
        type: 'GET_TASK_GUESTS',
        payload: getGuestsForTask(action.taskId),
      });
      break;

    case 'GET_ASSISTANTS_FOR_CLIENT':
      store.dispatch({
        type: 'GET_ASSISTANTS_FOR_CLIENT',
        payload: getAssistantsForClient(
          action.clientId,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'GET_TASK_AVAILABLE_USERS_FOR_AC_COMPANY':
      store.dispatch({
        type: 'GET_TASK_AVAILABLE_USERS_FOR_AC_COMPANY',
        payload: getTaskAvailableUsersForCompany(
          action.adminClientCompanyId,
          action.clientId
        ),
      });
      break;

    case 'GET_TASKS':
      store.dispatch({
        type: 'GET_TASKS',
        payload: getTasks(action.userId),
      });
      break;

    case 'GET_GUEST_AVAILABLE_TASKS':
      store.dispatch({
        type: 'GET_GUEST_AVAILABLE_TASKS',
        payload: getGuestAvailableTasks(action.guestId),
      });
      break;

    case 'ADD_GUEST_TO_TASK':
      store.dispatch({
        type: 'ADD_GUEST_TO_TASK',
        payload: addNewGuestToTask(
          action.firstName,
          action.lastName,
          action.email,
          action.taskId,
          action.guestUserId,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'SAVE_AVATAR':
      store.dispatch({
        type: 'SAVE_AVATAR',
        payload: saveAvatar(action.image),
      });
      break;

    case 'POST_TASK_STATUS':
      store.dispatch({
        type: 'POST_TASK_STATUS',
        payload: postTaskStatus({
          TaskID: action.taskId,
          StatusID: action.statusId,
        }),
      });
      break;

    case 'POST_TASK_ASSIGNEE':
      store.dispatch({
        type: 'POST_TASK_ASSIGNEE',
        payload: postTaskAssignees({
          TaskID: action.taskId,
          AssigneeIds: action.assigneeIds,
        }),
      });
      break;

    case 'POST_SAVE_TASK':
      store.dispatch({
        type: 'POST_SAVE_TASK',
        payload: saveTask({
          UserID: action.userId,
          TaskID: action.taskId,
          Title: action.title,
          Description: action.description,
          Assignees: action.assigneeIds,
          ClientID: action.clientId,
        }),
      });
      break;

    case 'POST_COMMENT':
      store.dispatch({
        type: 'POST_COMMENT',
        payload: postTaskComment({
          TaskID: action.taskId,
          Text: action.commentText,
        }),
      });
      break;

    case 'DELETE_COMMENT':
      store.dispatch({
        type: 'DELETE_COMMENT',
        payload: deleteTaskComment(action.commentId),
      });
      break;

    case 'POST_DOCUMENT':
      store.dispatch({
        type: 'POST_DOCUMENT',
        payload: postDocument({
          Data: action.data,
          FileType: action.fileType,
          TaskID: action.taskId,
          FileName: action.fileName,
        }),
      });
      break;

    case 'DELETE_DOCUMENT':
      store.dispatch({
        type: 'DELETE_DOCUMENT',
        payload: deleteDocument(action.documentId),
      });
      break;

    case 'GET_GLOBAL_SETTINGS':
      store.dispatch({
        type: 'GET_GLOBAL_SETTINGS',
        payload: getGlobalSettings(),
      });
      break;

    case 'SAVE_GLOBAL_SETTING':
      store.dispatch({
        type: 'SAVE_GLOBAL_SETTING',
        payload: saveGlobalSetting(action.data),
      });
      break;

    case 'GET_USERS':
      const {
        selectedAdminClientOwnerId,
        adminClientOwnerUsers,
        loggedUser: loggedUserUsersList,
      } = store.getState().user;

      if (
        loggedUserUsersList.isAdmin &&
        action.roleId !== 1 &&
        action.adminClientCompanyId == null
      ) {
        if (selectedAdminClientOwnerId !== -1) {
          action.adminClientCompanyId = adminClientOwnerUsers.find(
            (user) => user.userId === selectedAdminClientOwnerId
          ).adminClientCompanyId;
        }
      }

      store.dispatch({
        type: 'GET_USERS',
        payload: getUsers(action.roleId, action.adminClientCompanyId),
      });
      break;

    case 'GET_ADMIN_OWNER_CLIENTS':
      store.dispatch({
        type: 'GET_ADMIN_OWNER_CLIENTS',
        payload: getAdminClientOwnerClients(action.adminClientOwnerId),
      });
      break;

    case 'GET_USER_SETTINGS':
      store.dispatch({
        type: 'GET_USER_SETTINGS',
        payload: getUserSettings(action.userId),
      });
      break;

    case 'SAVE_USER_SETTINGS':
      if (action.newObject) {
        store.dispatch({
          type: 'SAVE_USER_SETTINGS',
          payload: insertUserSettings(action.data),
        });
      } else {
        store.dispatch({
          type: 'SAVE_USER_SETTINGS',
          payload: updateUserSettings(action.data),
        });
      }
      break;

    case 'GET_NOTIFICATION_FREQUENCIES':
      store.dispatch({
        type: 'GET_NOTIFICATION_FREQUENCIES',
        payload: getNotificationFrequencies(),
      });
      break;

    case 'LOG_USER_ACTIVITY':
      store.dispatch({
        type: 'LOG_USER_ACTIVITY',
        payload: logUserActivity(action.userActivityTypeId),
      });
      break;

    case 'GET_CALENDAR_ENTRY_TYPES':
      store.dispatch({
        type: 'GET_CALENDAR_ENTRY_TYPES',
        payload: getCalendarEntryTypes(),
      });
      break;

    case 'GET_CALENDAR_ENTRY_REMINDER_TIME':
      store.dispatch({
        type: 'GET_CALENDAR_ENTRY_REMINDER_TIME',
        payload: getCalendarEntryReminderTime(),
      });
      break;

    case 'GET_CALENDAR_ENTRY_RECURRENCES':
      store.dispatch({
        type: 'GET_CALENDAR_ENTRY_RECURRENCES',
        payload: getCalendarEntryRecurrences(),
      });
      break;

    case 'GET_CALENDAR_ENTRIES':
      store.dispatch({
        type: 'GET_CALENDAR_ENTRIES',
        payload: getCalendarEntries(
          action.userId,
          action.date,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'SAVE_CALENDAR_ENTRY':
      store.dispatch({
        type: 'SAVE_CALENDAR_ENTRY',
        payload: saveCalendarEntry(action.data),
      });
      break;

    case 'DELETE_CALENDAR_ENTRY':
      store.dispatch({
        type: 'DELETE_CALENDAR_ENTRY',
        payload: deleteCalendarEntry(action.id),
      });
      break;

    case 'GET_CALENDAR_MONTHLY_AVAILABILITY':
      store.dispatch({
        type: 'GET_CALENDAR_MONTHLY_AVAILABILITY',
        payload: getCalendarMonthlyAvailability(
          action.userId,
          action.year,
          action.month,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'GET_ADMIN_CLIENT_COMPANY_WITH_OWNER':
      store.dispatch({
        type: 'GET_ADMIN_CLIENT_COMPANY_WITH_OWNER',
        payload: getAdminClientCompanyWithOwner(action.adminClientCompanyId),
      });
      break;

    case 'GET_CONTACTS':
      store.dispatch({
        type: 'GET_CONTACTS',
        payload: getContacts(action.userId),
      });
      break;

    case 'GET_AC_COMPANY_CONTACTS':
      store.dispatch({
        type: 'GET_AC_COMPANY_CONTACTS',
        payload: getAdminClientCompanyContacts(action.adminClientCompanyId),
      });
      break;

    case 'GET_CONTACT_DETAIL_TYPES':
      store.dispatch({
        type: 'GET_CONTACT_DETAIL_TYPES',
        payload: getContactDetailTypes(),
      });
      break;

    case 'SAVE_CONTACT':
      store.dispatch({
        type: 'SAVE_CONTACT',
        payload: saveContact(action.payload),
      });

      break;

    case 'SAVE_CONTACT_FULFILLED':
    case 'DELETE_CONTACT_FULFILLED':
    case 'DELETE_CONTACTS_FULFILLED':
      const { userId = null, adminClientCompanyId = null } = action.payload;
      store.dispatch(
        userId != null
          ? {
              type: 'GET_CONTACTS',
              payload: getContacts(userId),
            }
          : {
              type: 'GET_AC_COMPANY_CONTACTS',
              payload: getAdminClientCompanyContacts(adminClientCompanyId),
            }
      );
      break;

    case 'DELETE_CONTACT':
      store.dispatch({
        type: 'DELETE_CONTACT',
        payload: deleteContact(
          action.payload,
          action.userId,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'IMPORT_CONTACTS':
      store.dispatch({
        type: 'IMPORT_CONTACTS',
        payload: importContacts(action.payload, action.userId),
      });
      break;

    case 'DELETE_CONTACTS':
      store.dispatch({
        type: 'DELETE_CONTACTS',
        payload: deleteContacts(
          action.payload,
          action.userId,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'CHECK_USERNAME':
      store.dispatch({
        type: 'CHECK_USERNAME',
        payload: checkUserName(action.userId, action.userName),
      });
      break;

    case 'GET_USER_GUESTS':
      store.dispatch({
        type: 'GET_USER_GUESTS',
        payload: getGuestsForUser(action.userId),
      });
      break;

    case 'GET_CAL_USERS':
      store.dispatch({
        type: 'GET_CAL_USERS',
        payload: getCalendarUsers(action.adminClientCompanyId),
      });
      break;

    case 'GET_USER_BASE':
      store.dispatch({
        type: 'GET_USER_BASE',
        payload: getUserBase(action.userId),
      });
      break;

    case 'GET_USER_ADMIN_CLIENT_COMPANY':
      store.dispatch({
        type: 'GET_USER_ADMIN_CLIENT_COMPANY',
        payload: getAdminClientCompanyWithOwner(action.adminClientCompanyId),
      });
      break;

    case 'GET_PHONEBOOK_USERS':
      store.dispatch({
        type: 'GET_PHONEBOOK_USERS',
        payload: getPhonebookUsers(action.adminClientCompanyId),
      });
      break;

    case 'GET_ADMIN_CLIENT_OWNER_USERS':
      store.dispatch({
        type: 'GET_ADMIN_CLIENT_OWNER_USERS',
        payload: getAdminClientOwnersUsers(),
      });
      break;

    case 'GET_PUSH_ON_SETTINGS':
      store.dispatch({
        type: 'GET_PUSH_ON_SETTINGS',
        payload: getPushOnSettings(action.userId),
      });
      break;

    case 'SAVE_PUSH_ON_SETTINGS_FOR_CLIENT':
      store
        .dispatch({
          type: 'SAVE_PUSH_ON_SETTINGS_FOR_CLIENT',
          payload: savePushOnSettingsForClient(action.data),
        })
        .then(() => {
          store.dispatch({
            type: 'GET_NOTIFICATION_DATA',
            payload: getNotificationData(),
          });
        })
        .then(() => {
          store.dispatch({
            type: 'GET_PUSH_ON_SETTINGS',
            payload: getPushOnSettings(action.data.userId),
          });
        });
      break;

    case 'SUBSCRIBE_TO_NOTIFICATIONS':
      store
        .dispatch({
          type: 'SUBSCRIBE_TO_NOTIFICATIONS',
          payload: subscribeToTaskNotifications(action.data),
        })
        .then(() => {
          store.dispatch({
            type: 'GET_TASK_NOTIFICATION_DATA',
            taskId: action.data.taskId,
          });
        });
      break;

    case 'UNSUBSCRIBE_FROM_NOTIFICATIONS':
      store
        .dispatch({
          type: 'UNSUBSCRIBE_FROM_NOTIFICATIONS',
          payload: unsubscribeFromTaskNotifications(action.data),
        })
        .then(() => {
          store.dispatch({
            type: 'REMOVE_TASK_NOTIFICATION_DATA',
            taskId: action.data.taskId,
          });
        });
      break;

    case 'GET_PUSH_ON_SETTINGS_ADMIN_CLIENT_OWNERS':
      store.dispatch({
        type: 'GET_PUSH_ON_SETTINGS_ADMIN_CLIENT_OWNERS',
        payload: getAdminClientOwnersUsers(),
      });
      break;

    case 'TASKS_SUBSCRIBE_TASK_TO_NOTIFICATIONS':
      store
        .dispatch({
          type: 'TASKS_SUBSCRIBE_TASK_TO_NOTIFICATIONS',
          payload: subscribeToTaskNotifications(action.data),
        })
        .then(() => {
          store.dispatch({
            type: 'GET_TASK_NOTIFICATION_DATA',
            taskId: action.data.taskId,
          });
        });
      break;

    case 'TASKS_UNSUBSCRIBE_TASK_FROM_NOTIFICATIONS':
      store
        .dispatch({
          type: 'TASKS_UNSUBSCRIBE_TASK_FROM_NOTIFICATIONS',
          payload: unsubscribeFromTaskNotifications(action.data),
        })
        .then(() => {
          store.dispatch({
            type: 'REMOVE_TASK_NOTIFICATION_DATA',
            taskId: action.data.taskId,
          });
        });
      break;

    case 'GET_NOTIFICATION_DATA':
      store.dispatch({
        type: 'GET_NOTIFICATION_DATA',
        payload: getNotificationData(),
      });
      break;

    case 'GET_ADMIN_NOTIFICATION_DATA':
      store.dispatch({
        type: 'GET_ADMIN_NOTIFICATION_DATA',
        payload: getAdminNotificationData(),
      });
      break;

    case 'GET_TASK_DOCUMENT_NOTIFICATION_DATA':
      store.dispatch({
        type: 'GET_TASK_DOCUMENT_NOTIFICATION_DATA',
        payload: getTaskDocumentNotificationData(action.taskId),
      });
      break;

    case 'GET_TASK_COMMENT_NOTIFICATION_DATA':
      store.dispatch({
        type: 'GET_TASK_COMMENT_NOTIFICATION_DATA',
        payload: getTaskCommentNotificationData(action.taskId),
      });
      break;

    case 'GET_TASK_NOTIFICATION_DATA':
      store.dispatch({
        type: 'GET_TASK_NOTIFICATION_DATA',
        payload: getTaskNotificationData(action.taskId),
      });
      break;

    case 'GET_CALENDAR_NOTIFICATION_DATA':
      store.dispatch({
        type: 'GET_CALENDAR_NOTIFICATION_DATA',
        payload: getCalendarNotificationData(),
      });
      break;

    case 'SET_TASK_COMMENTS_SEEN':
      store.dispatch({
        type: 'SET_TASK_COMMENTS_SEEN',
        payload: setTaskCommentsSeen(action.data),
      });
      break;

    case 'SET_CALENDAR_ENTRY_SEEN':
      store.dispatch({
        type: 'SET_CALENDAR_ENTRY_SEEN',
        payload: setCalendarEntrySeen(action.data).then(() => {
          store.dispatch({
            type: 'UPDATE_CALENDAR_ENTRY_SEEN',
            calendarEntryId: action.data.CalendarEntryId,
          });
        }),
      });
      break;
    //CHAT
    case 'CHAT_GET_ADMIN_CLIENT_OWNERS_USERS':
      store.dispatch({
        type: 'CHAT_GET_ADMIN_CLIENT_OWNERS_USERS',
        payload: getAdminClientOwnersUsers(),
      });
      break;

    case 'CHAT_SET_ADMIN_CLIENT_OWNER_ID':
      next(action);
      store.dispatch({
        type: 'GET_CHAT_ROOMS',
        payload: getChatMessages(
          action.userId,
          store.getState().user.loggedUser
        ),
      });
      break;

    case 'GET_CHAT_ROOMS':
      store.dispatch({
        type: 'GET_CHAT_ROOMS',
        payload: getChatMessages(action.userId, action.loggedUser),
      });
      break;

    case 'GET_CHAT_ROOM_MESSAGES':
      store.dispatch({
        type: 'GET_CHAT_ROOM_MESSAGES',
        payload: getChatRoomMessages(
          action.chatRoomId,
          action.lastId,
          action.messagesCount
        ),
      });
      break;

    case 'GET_OVERSEEN_CHAT_GROUPS':
      const loggedUser = store.getState().user.loggedUser;
      const overseenUserId = loggedUser.isAdmin
        ? store.getState().chat.selectedAdminClientOwnerId
        : loggedUser.userId;

      if (overseenUserId !== CHAT_ADMIN_CLIENT_OWNER_NOT_SELECTED) {
        store.dispatch({
          type: 'GET_OVERSEEN_CHAT_GROUPS',
          payload: getOverseenChatGroups(overseenUserId),
        });
      }
      break;

    case 'GET_OVERSEEN_CHAT_ROOM_MESSAGES':
      store.dispatch({
        type: 'GET_OVERSEEN_CHAT_ROOM_MESSAGES',
        payload: getOverseenChatRoomMessages(action.roomId).then((result) => {
          setTimeout(() => store.dispatch(setActiveChatRoom(action.roomId)));
          return result;
        }),
        meta: {
          roomId: action.roomId,
        },
      });
      break;

    case 'GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES':
      store.dispatch({
        type: 'GET_OVERSEEN_ADDITIONAL_CHAT_ROOM_MESSAGES',
        payload: getOverseenAdditinalChatRoomMessages(
          action.roomId,
          action.lastMessageId
        ),
        meta: {
          roomId: action.roomId,
          lastMessageId: action.lastMessageId,
        },
      });

      break;

    //COWORKERS-REDUCER
    case 'COWORKERS_GET_COMPANY_OWNERS':
      store.dispatch({
        type: 'COWORKERS_GET_COMPANY_OWNERS',
        payload: getAdminClientOwnersUsers(),
      });
      break;

    case 'GET_CLIENTS':
      store.dispatch({
        type: 'GET_CLIENTS',
        payload: getClients(),
      });
      break;

    case 'GET_CLIENTS_FOR_ADMIN_CLIENT_COMPANY':
      store.dispatch({
        type: 'GET_CLIENTS_FOR_ADMIN_CLIENT_COMPANY',
        payload: getClientsForAdminClient(action.adminClientCompanyId),
      });
      break;

    case 'GET_COWORKERS_FOR_CLIENT':
      store.dispatch({
        type: 'GET_COWORKERS_FOR_CLIENT',
        payload: getAssistantsForClient(
          action.clientId,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'GET_AVAILABLE_COWORKERS':
      store.dispatch({
        type: 'GET_AVAILABLE_COWORKERS',
        payload: getAvailableAssistants(
          action.clientId,
          action.adminClientCompanyId
        ),
      });
      break;

    case 'ASSIGN_COWORKER_TO_CLIENT':
      store.dispatch({
        type: 'ASSIGN_COWORKER_TO_CLIENT',
        payload: assignAssistantToClient(
          action.clientId,
          action.assistantId
        ).then((assignResult) => {
          store.dispatch({
            type: 'GET_AVAILABLE_COWORKERS',
            clientId: action.clientId,
            adminClientCompanyId: action.adminClientCompanyId,
          });
          return assignResult;
        }),
      });
      break;

    case 'UNASSIGN_COWORKER_FROM_CLIENT':
      store.dispatch({
        type: 'UNASSIGN_COWORKER_FROM_CLIENT',
        payload: unassignAssistantFromClient(
          action.clientId,
          action.assistantId
        ).then((unassignResult) => {
          store.dispatch({
            type: 'GET_AVAILABLE_COWORKERS',
            clientId: action.clientId,
            adminClientCompanyId: action.adminClientCompanyId,
          });
          return unassignResult;
        }),
      });
      break;
    //END COWORKERS-REDUCER
    case 'GET_CREATE_USERS_LIMIT': {
      store.dispatch({
        type: 'GET_CREATE_USERS_LIMIT',
        payload: getCreateUsersLimit(),
      });
      break;
    }

    case UPLOAD_FILE: {
      store.dispatch({
        type: UPLOAD_FILE,
        payload: uploadFile(
          action.payload.selectedFile,
          action.payload.roomId,
          action.payload.type
        ),
      });
      break;
    }

    case 'GET_ADMIN_USERS_LIMIT': {
      store.dispatch({
        type: 'GET_ADMIN_USERS_LIMIT',
        payload: getAdminUsersLimit(action.userId),
      });
      break;
    }
    case REMOVE_SERVICE: {
      store.dispatch({
        type: REMOVE_SERVICE,
        payload: removeService(action.payload.subscriptionItemId),
      });
      break;
    }
    case ADD_NEW_SUBSCRIPTION_ITEM: {
      store.dispatch({
        type: ADD_NEW_SUBSCRIPTION_ITEM,
        payload: addNewSubscriptionItem(action.payload.priceId),
      });
      break;
    }

    case UPGRADE_USER_PACKAGE: {
      store.dispatch({
        type: UPGRADE_USER_PACKAGE,
        payload: upgradeUserPackage(action.payload.priceId),
      });
      break;
    }

    case DOWNGRADE_USER_PACKAGE: {
      store.dispatch({
        type: DOWNGRADE_USER_PACKAGE,
        payload: downgradeUserPackage(action.payload.priceId),
      });
      break;
    }

    case CANCEL_ACCOUNT: {
      store.dispatch({
        type: CANCEL_ACCOUNT,
        payload: cancelAccount(),
      });
      break;
    }

    case REACTIVATE_ACCOUNT: {
      store.dispatch({
        type: REACTIVATE_ACCOUNT,
        payload: reactivateAccount(),
      });
      break;
    }

    case MARK_INVOICE_AS_PAID: {
      store.dispatch({
        type: MARK_INVOICE_AS_PAID,
        payload: markInvoiceAsPaid(action.payload.invoiceId),
      });
      break;
    }

    case 'GET_USER_CHATROOMS': {
      store.dispatch({
        type: 'GET_USER_CHATROOMS',
        payload: getUserChatRooms(action.payload),
      });
    }

    default:
      next(action);
  }
};

export default api_calls;
