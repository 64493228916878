import CalendarReducer from './calendar-reducer';
import ChatReducer from './chat-reducer';
import NewTaskReducer from './../pages/authorized/new-task/new-task-reducer';
import NotificationReducer from './notification-reducer';
import PhonebookReducer from './phonebook-reducer';
import PushOnReducer from './push-on-reducer';
import RedirectReducer from './redirect-reducer';
import RegistrationsReducer from './registrations-reducer';
import SecretariesReducer from './secretaries-reducer';
import SettingsReducer from './settings-reducer';
import TaskReducer from './../pages/authorized/task/task-reducer';
import TasksReducer from './tasks-reducer';
import UserLimit from './user-limit';
import UserReducer from './user-reducer';
import { combineReducers } from 'redux';
import coworkersReducer from './coworkers-reducer';
import { notistackDispatcherReducer } from '../reducers/notistack-dispatcher-reducer';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage'; // Now using localStorage

const config = {
  key: 'root', // key is required
  storage, // Now using localStorage
};

const Reducers = persistReducer(
  config,
  combineReducers({
    user: UserReducer,
    registrations: RegistrationsReducer,
    secretaries: SecretariesReducer,
    tasks: TasksReducer,
    notifications: NotificationReducer,
    task: TaskReducer,
    chat: ChatReducer,
    settings: SettingsReducer,
    newTask: NewTaskReducer,
    calendar: CalendarReducer,
    phonebook: PhonebookReducer,
    redirecter: RedirectReducer,
    pushOn: PushOnReducer,
    notifStackDispatcher: notistackDispatcherReducer,
    coworkers: coworkersReducer,
    userLimit: UserLimit,
  })
);

export default Reducers;
