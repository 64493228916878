const initState = {
  loading: false,
  task: {},
  statuses: [],
  assistants: [],
  comments: [],
  documents: [],
  files: [],
  guests: [],
  existingGuests: [],
  latestCommentId: 0,
};

const TaskReducer = function (state = initState, action) {
  switch (action.type) {
    case 'CLEAR_TASK': {
      return {
        ...state,
        task: {},
        loading: false,
      };
    }
    case 'CLEAR_ASSISTANTS': {
      return {
        ...state,
        assistants: [],
        loading: false,
      };
    }
    case 'GET_TASK_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_TASK_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_TASK_FULFILLED': {
      return {
        ...state,
        loading: false,
        task: action.payload,
      };
    }
    case 'GET_TASK_COMMENTS_FULFILLED': {
      let latestCommentId = Math.max.apply(
        Math,
        action.payload.map(function (comment) {
          return comment.commentId;
        })
      );
      return {
        ...state,
        comments: action.payload,
        latestCommentId: latestCommentId,
      };
    }
    case 'GET_LATEST_COMMENTS_FULFILLED': {
      let comments = state.comments
        .concat(action.payload)
        .filter((obj, pos, arr) => {
          return (
            arr
              .map((mapObj) => mapObj['commentId'])
              .indexOf(obj['commentId']) === pos
          );
        })
        .sort((a, b) => {
          return b.commentId - a.commentId;
        });

      comments = [...new Set(comments.map((comment) => comment.commentId))];
      let latestCommentId = Math.max.apply(
        Math,
        action.payload.map(function (comment) {
          return comment.commentId;
        })
      );

      return {
        ...state,
        comments: comments,
        latestCommentId: latestCommentId,
      };
    }
    case 'GET_TASK_DOCUMENTS_FULFILLED': {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case 'GET_LATEST_DOCUMENTS_FULFILLED': {
      let documents = state.documents.concat(action.payload).sort((a, b) => {
        return a.documentId - b.documentId;
      });
      return {
        ...state,
        documents: documents,
      };
    }
    case 'GET_TASK_STATUSES_FULFILLED': {
      return {
        ...state,
        statuses: action.payload,
      };
    }
    case 'GET_TASK_GUESTS_FULFILLED': {
      return {
        ...state,
        guests: action.payload,
      };
    }
    case 'GET_ASSISTANTS_FOR_CLIENT_FULFILLED': {
      return {
        ...state,
        assistants: action.payload,
      };
    }

    case 'GET_TASK_AVAILABLE_USERS_FOR_AC_COMPANY_FULFILLED': {
      return {
        ...state,
        assistants: action.payload,
      };
    }

    case 'POST_TASK_STATUS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'POST_TASK_STATUS_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'POST_TASK_STATUS_FULFILLED': {
      let task = state.task;
      task.status = action.payload;

      return {
        ...state,
        task: { ...task },
        loading: false,
      };
    }
    case 'POST_TASK_ASSIGNEE_FULFILLED': {
      let task = state.task;
      task.assignee = action.payload;

      return {
        ...state,
        task: { ...task },
      };
    }
    case 'POST_SAVE_TASK_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'POST_SAVE_TASK_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'POST_SAVE_TASK_FULFILLED': {
      let task = state.task;
      task.title = action.payload.title;
      task.description = action.payload.description;

      return {
        ...state,
        task: { ...task },
        loading: false,
      };
    }
    case 'POST_COMMENT_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'POST_COMMENT_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'POST_COMMENT_FULFILLED': {
      let comments = state.comments.concat(action.payload).sort((a, b) => {
        return b.commentId - a.commentId;
      });
      return {
        ...state,
        comments: comments,
        loading: false,
      };
    }
    case 'DELETE_COMMENT_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'DELETE_COMMENT_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'DELETE_COMMENT_FULFILLED': {
      let comments = state.comments.filter(
        (comment) => comment.commentId !== action.payload
      );
      return {
        ...state,
        comments: comments,
        loading: false,
      };
    }
    case 'POST_DOCUMENT_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'POST_DOCUMENT_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'POST_DOCUMENT_FULFILLED': {
      let document = action.payload;
      // set seen to true when new doc is uploaded
      document.seen = true;

      let documents = state.documents.concat(document).sort((a, b) => {
        return a.documentId - b.documentId;
      });
      return {
        ...state,
        documents: documents,
        loading: false,
      };
    }
    case 'DELETE_DOCUMENT_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'DELETE_DOCUMENT_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'DELETE_DOCUMENT_FULFILLED': {
      let documents = state.documents.filter(
        (document) => document.documentId !== action.payload
      );
      return {
        ...state,
        documents: documents,
        loading: false,
      };
    }
    case 'GET_EXISTING_GUESTS': {
      return {
        ...state,
        existingGuests: action.guests,
      };
    }
    case 'ADD_GUEST': {
      return {
        ...state,
        guests: action.guests,
      };
    }
    case 'DELETE_GUEST': {
      return {
        ...state,
        guests: action.guests,
      };
    }

    case 'DELETE_TASK_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'DELETE_TASK_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'DELETE_TASK_FULFILLED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'SUBSCRIBE_TO_NOTIFICATIONS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'SUBSCRIBE_TO_NOTIFICATIONS_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'SUBSCRIBE_TO_NOTIFICATIONS_FULFILLED': {
      let task = state.task;
      task.subscribedToNotifications = !task.subscribedToNotifications;

      return {
        ...state,
        task: { ...task },
        loading: false,
      };
    }

    case 'UNSUBSCRIBE_FROM_NOTIFICATIONS_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'UNSUBSCRIBE_FROM_NOTIFICATIONS_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'UNSUBSCRIBE_FROM_NOTIFICATIONS_FULFILLED': {
      let task = state.task;
      task.subscribedToNotifications = !task.subscribedToNotifications;

      return {
        ...state,
        task: { ...task },
        loading: false,
      };
    }

    case 'SET_TASK_COMMENTS_SEEN_PENDING': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'SET_TASK_COMMENTS_SEEN_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'SET_TASK_COMMENTS_SEEN_FULFILLED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'MARK_TASK_COMMENT_SEEN': {
      let comments = state.comments;
      const commentIndex = comments
        .map(function (c) {
          return c.commentId;
        })
        .indexOf(action.commentId);
      const comment = comments[commentIndex];
      comment.seen = true;
      comments[commentIndex] = comment;

      return {
        ...state,
        loading: false,
        comments,
      };
    }

    default:
      return state;
  }
};

export default TaskReducer;
