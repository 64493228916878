import { addDays, addMonths } from 'date-fns';

import moment from 'moment';

export const getDefaultTaskFilter = () => {
  //quick fix for time-zone differences introduced bag (added one day)
  const currentDate = addDays(new Date(), 1);
  // const previousMonth = addMonths(new Date(), -3);
  const previousMonth = null;
  return {
    titleFilter: '',
    statuses: ['Open', 'In Progress', 'Stopped'],
    clients: [],
    assignees: [],
    creationDateFrom: previousMonth,
    creationDateTo: currentDate,
    adminClientCompanyOwners: [],
  };
};
