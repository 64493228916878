import { REGEX_USERS_FILTERING } from '../../common/appConstants';
import _ from 'lodash';
import { getUsers } from '../../actions/user-actions';

export function loadUsers(dispatch, adminClientCompanyId) {
  let roleId = parseRole();
  getUsers(dispatch, roleId, adminClientCompanyId);
}

export function parseRole() {
  const url = window.location.href.toLowerCase();
  if (url.includes('admin-clients')) return 5;
  if (url.includes('guests')) return 4;
  if (url.includes('clients')) return 3;
  if (url.includes('createuser')) return 3;
  if (url.includes('inviteuser')) return 3;
  if (url.includes('calendar')) return 3;
  if (url.includes('phonebook')) return 3;
  if (url.includes('assistants')) return 2;
  if (url.includes('administrators')) return 1;
  return -1;
}

export function parseUsersListType() {
  const url = window.location.href.toLowerCase();
  if (url.includes('admin-clients')) return 'AdminClient';
  if (url.includes('guests')) return 'Guest';
  if (url.includes('clients')) return 'Client';
  if (url.includes('calendar')) return 'Calendar';
  if (url.includes('phonebook')) return 'Phonebook';
  if (url.includes('assistants')) return 'Assistant';
  if (url.includes('administrators')) return 'Admin';
  return -1;
}

/** @typedef { { userId: number; role: string; fullName: string username: string;}} UserType*/
/** @typedef { { clientsIds: [number]; guestsIds: [number]}} ClientsGuestsBag */
/** @typedef { { [index: number] : UserType & ClientsGuestsBag }} ClientsMapType */
/** @typedef { { [index: number] : UserType & ClientsGuestsBag }} GuestsMapType */
/**@type { ( users : Array<UserType> => [object, typeof users]} users */
export function extractClientMapAndGuestsList(users) {
  /**@type { UserType } */
  let currentClient = null;
  /** @type { ClientsMapType } */
  const clientsMap = {};

  /** @type { GuestsMapType } */
  const guestsMap = {};

  users.forEach((user) => {
    const { role } = user;

    if (role === 'Client') {
      const actualClient = { ...user };
      currentClient = actualClient;
      clientsMap[user.userId] = actualClient;
      actualClient.guestsIds = [];
      actualClient.clientsIds = [];
      actualClient.clientsNames = [];
    } else if (role === 'Guest') {
      currentClient.guestsIds.push(user.userId);

      if (!(user.userId in guestsMap)) {
        const actualGuest = { ...user };
        actualGuest.clientsIds = [];
        actualGuest.guestsIds = [];
        actualGuest.clientsNames = [];
        guestsMap[user.userId] = actualGuest;
      }

      guestsMap[user.userId].clientsIds.push(currentClient.userId);
      guestsMap[user.userId].clientsNames.push(currentClient.fullName);
    }
  });

  return [
    clientsMap,
    Object.values(guestsMap)
      .sort((a, b) => b.active - a.active)
      .map((guest) => {
        return {
          ...guest,
          clients: guest.clientsNames.join(','),
        };
      }),
  ];
}

export function mapUsersToFullNamed(users) {
  return users.map((u) => ({
    ...u,
    fullName: `${u.firstName} ${u.lastName}`,
  }));
}

const USERS_GROUPING_ROLES_LOOKUP = {
  Admin: 'Admin',
  AdminClient: 'Admin-client',
  Assistant: 'Coworker',
  Client: 'Client',
};

const ROLES_AVAILABLE_ROLES = {
  Admin: _.omit(USERS_GROUPING_ROLES_LOOKUP, ['Admin']),
  AdminClient: _.omit(USERS_GROUPING_ROLES_LOOKUP, ['Admin']),
  Assistant: _.omit(USERS_GROUPING_ROLES_LOOKUP, ['AdminClient']),
  Client: [],
  Guest: [],
};

export function getDefaultUsersFilter(loggedUserRole) {
  return {
    searchUsername: true,
    searchFullname: true,
    searchEmail: true,
    selectedRoles: Object.values(ROLES_AVAILABLE_ROLES[loggedUserRole]),
  };
}

export function getAvailableRoles(loggedUserRole) {
  return Object.values(ROLES_AVAILABLE_ROLES[loggedUserRole]);
}

export function toLowerCasedUser(user) {
  return {
    ...user,
    lcUsername: user.username.toLowerCase(),
    lcFullName: `${user.firstName} ${user.lastName}`.toLowerCase(),
    lcFirstName: user.firstName.toLowerCase(),
    lcLastName: user.lastName.toLowerCase(),
    lcEmail: user.email.toLowerCase(),
    actualRole:
      user.role === 'Assistant'
        ? 'Coworker'
        : user.role === 'AdminClient'
        ? 'Admin-client'
        : user.role,
  };
}

export function toActualRoledFullNamedUser(user) {
  return {
    ...user,
    fullName: `${user.firstName} ${user.lastName}`,
    actualRole:
      user.role === 'Assistant'
        ? 'Coworker'
        : user.role === 'AdminClient'
        ? 'Admin-client'
        : user.role,
  };
}

export const matchesFilterFactory = (filter, searchText) => {
  const { searchUsername, searchFullname, searchEmail, selectedRoles } = filter;
  const realSearchText = searchText.trim();

  return (lcUser) =>
    selectedRoles.includes(lcUser.actualRole) &&
    ((searchUsername && lcUser.lcUsername.startsWith(realSearchText)) ||
      (searchFullname &&
        (lcUser.lcFullName.startsWith(realSearchText) ||
          lcUser.lcFirstName.startsWith(realSearchText) ||
          lcUser.lcLastName.startsWith(realSearchText))) ||
      (searchEmail && lcUser.lcEmail.startsWith(realSearchText)) ||
      (!searchUsername && !searchFullname && !searchEmail));
};

export const matchesRegexFilterFactory = (filter, searchText) => {
  const { searchUsername, searchFullname, searchEmail, selectedRoles } = filter;
  const searchRegex = new RegExp(searchText.trim(), 'i');

  return (user) =>
    selectedRoles.includes(user.actualRole) &&
    ((searchUsername && searchRegex.test(user.username)) ||
      (searchFullname && searchRegex.test(user.fullName)) ||
      (searchEmail && searchRegex.test(user.email)) ||
      (!searchUsername && !searchFullname && !searchEmail));
};

const MATCHES_FILTER_STRATEGY = REGEX_USERS_FILTERING
  ? matchesRegexFilterFactory
  : matchesFilterFactory;

export function filterUsers(filter, searchText, users) {
  const matchesFilter = MATCHES_FILTER_STRATEGY(filter, searchText);

  return users.filter((lcu) => matchesFilter(lcu));
}

export async function asyncFilterUsers(filter, searchText, users) {
  const matchesFilter = MATCHES_FILTER_STRATEGY(filter, searchText);
  const result = users.filter((user) => matchesFilter(user));
  return result;
}
