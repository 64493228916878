import _ from 'lodash';

export const ADMIN_ZERO_USERNAME = 'Sekulamarko88';
export const LOGOUT_AFTER_UNAUTHORIZED_MILLIS = 750;
export const REGEX_USERS_FILTERING = true;
export const MOBILE_SEARCH_INPUT_DEBOUNCE_TIME = 750;
export const MOBILE_SEARCH_IN_APP_BAR_ROUTES = [
  '/admin-clients',
  '/clients',
  '/assistants',
  '/guests',
  '/administrators',
  '/tasks/:id',
  '/calendar-users',
  '/phonebook-users',
];
export const SHOW_MOBILE_FILTER_IN_APP_BAR_ROUTES = _.without(
  MOBILE_SEARCH_IN_APP_BAR_ROUTES,
  '/tasks/:id'
);

export const INTIAL_OVERSEEN_MESSAGE_COUNT_FETCH = 101;
