import { Button, Paper, TableBody, TableCell, TextField } from '@mui/material';
import { Table, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { Label } from '../../Label';
import React from 'react';
import { Row } from '../../../utilities/constants';

const InvoiceSummary = (props) => {
  const { invoice } = props;

  const getPrice = (amount) => {
    return (amount / 100).toFixed(2);
  };

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className="checkout_table_head">
            <TableRow>
              <TableCell>Invoice no.</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{invoice?.number}</TableCell>
              <TableCell>${getPrice(invoice?.ammountRemaining)}</TableCell>
            </TableRow>
            <TableRow className="">
              <TableCell colSpan={4}>
                <div className="flex flex-row w-full justify-between items-center p-5 box-border bg-lightGray">
                  <Label text="Coupon" />
                  <TextField
                    placeholder="Coupon"
                    size="small"
                    margin="normal"
                    disabled
                  />
                  <Button variant="contained" color="primary" disabled>
                    Apply
                  </Button>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" className="total_row">
                Summary
              </TableCell>
              <TableCell className="total_row">
                ${getPrice(invoice?.ammountRemaining)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default InvoiceSummary;
