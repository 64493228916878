const initState = {
  loading: false,

  snackbarOpen: false,
  snackbarMessage: '',
  snackbarDuration: 5000,

  admin: null,
  task: null,
  calendar: null,
  pastDueSubscriptionForAdminClientOwner: null,
};

const NotificationReducer = function (state = initState, action) {
  switch (action.type) {
    case 'SET_SNACKBAR_NOTIFICATION': {
      return {
        ...state,
        snackbarMessage: action.snackbarMessage,
        snackbarOpen: true,
      };
    }
    case 'CLEAR_SNACKBAR_NOTIFICATION': {
      return { ...state, snackbarMessage: '', snackbarOpen: false };
    }
    case 'UPDATE_NOTIFICATION_COUNT': {
      return {
        ...state,
        admin: action.data.Admin,
        task: action.data.Task,
        calendar: action.data.Calendar,
      };
    }
    case 'GET_NOTIFICATION_DATA_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_NOTIFICATION_DATA_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_NOTIFICATION_DATA_FULFILLED': {
      return {
        ...state,
        loading: false,
        admin: action.payload.Admin,
        task: action.payload.Task,
        calendar: action.payload.Calendar,
        pastDueSubscriptionForAdminClientOwner:
          action.payload.PastDueSubscriptionForAdminClientOwner,
      };
    }
    case 'GET_ADMIN_NOTIFICATION_DATA_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_ADMIN_NOTIFICATION_DATA_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_ADMIN_NOTIFICATION_DATA_FULFILLED': {
      return {
        ...state,
        loading: false,
        admin: action.payload,
      };
    }
    case 'GET_TASK_DOCUMENT_NOTIFICATION_DATA_PENDING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_TASK_DOCUMENT_NOTIFICATION_DATA_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_TASK_DOCUMENT_NOTIFICATION_DATA_FULFILLED': {
      let task = state.task;
      let indexToUpdate;
      if (task && action.payload) {
        indexToUpdate = task
          .map(function (t) {
            return t.TaskId;
          })
          .indexOf(action.payload.TaskId);
      }
      if (indexToUpdate > -1 && task[indexToUpdate]) {
        task[indexToUpdate].Document = action.payload.Document;
      } else if (action.payload) {
        task.push({
          TaskId: action.payload.TaskId,
          Document: action.payload.Document,
          Comment: 0,
        });
      }
      return {
        ...state,
        loading: false,
        task: [...task],
      };
    }
    case 'GET_TASK_COMMENT_NOTIFICATION_DATA_PENDING': {
      return {
        ...state,
        // it was requested to remove loading indicator when comment notification data is fetched
      };
    }
    case 'GET_TASK_COMMENT_NOTIFICATION_DATA_REJECTED': {
      return {
        ...state,
      };
    }
    case 'GET_TASK_COMMENT_NOTIFICATION_DATA_FULFILLED': {
      let task = state.task;
      let indexToUpdate;

      if (task && action.payload) {
        indexToUpdate = task
          .map(function (t) {
            return t.TaskId;
          })
          .indexOf(action.payload.TaskId);
      }

      if (indexToUpdate > -1 && task[indexToUpdate]) {
        task[indexToUpdate].Comment = action.payload.Comment;
      } else if (action.payload) {
        task.push({
          TaskId: action.payload.TaskId,
          Document: 0,
          Comment: action.payload.Comment,
        });
      }
      return {
        ...state,
        task: [...task],
      };
    }

    case 'GET_TASK_NOTIFICATION_DATA_PENDING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_TASK_NOTIFICATION_DATA_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'GET_TASK_NOTIFICATION_DATA_FULFILLED': {
      let task = state.task;
      let indexToUpdate;

      if (task && action.payload) {
        indexToUpdate = task
          .map(function (t) {
            return t.TaskId;
          })
          .indexOf(action.payload[0].TaskId);
      }
      if (indexToUpdate > -1 && task[indexToUpdate]) {
        task[indexToUpdate].Comment = action.payload[0].Comment;
        task[indexToUpdate].Document = action.payload[0].Document;
      } else if (action.payload) {
        task.push({
          TaskId: action.payload[0].TaskId,
          Document: action.payload[0].Document,
          Comment: action.payload[0].Comment,
        });
      }
      return {
        ...state,
        loading: false,
        task: [...task],
      };
    }

    case 'REMOVE_TASK_NOTIFICATION_DATA': {
      let task = state.task;
      let indexToUpdate;
      if (task && action.taskId) {
        indexToUpdate = task
          .map(function (t) {
            return t.TaskId;
          })
          .indexOf(action.taskId);
      }

      if (indexToUpdate > -1 && task[indexToUpdate]) {
        task[indexToUpdate].Comment = 0;
        task[indexToUpdate].Document = 0;
      }
      return {
        ...state,
        loading: false,
        task: [...task],
      };
    }

    case 'GET_CALENDAR_NOTIFICATION_DATA_PENDING': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_CALENDAR_NOTIFICATION_DATA_REJECTED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_CALENDAR_NOTIFICATION_DATA_FULFILLED': {
      return {
        ...state,
        loading: false,
        calendar: action.payload,
      };
    }
    default:
      return state;
  }
};

export default NotificationReducer;
