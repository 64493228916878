export class ApiError extends Error {
  constructor(errorData) {
    super();
    this.errorData = errorData;
  }

  getStatusCode() {
    return this.errorData?.Result?.StatusCode || this.errorData.StatusCode;
  }

  getMessage() {
    return this.errorData?.Result?.Message || this.errorData.Message;
  }
}

export class LogActivityError extends ApiError {
  constructor(errorData) {
    super(errorData);
  }
}
