import React from 'react';
import vcLogo from '../assets/vcLogo.png';

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: 'none',
      }}
    >
      <a href="https://www.virtualconnectionsllc.com/">
        <img
          className="block md:max-h-[40px] max-h-[35px]"
          src={vcLogo}
          alt="vcLogo.png"
        />
      </a>
    </div>
  );
};

export default Footer;
