const initState = {
  CurentUserCount: null,
  AllowedUserCount: null,
};

const UserLimit = function (state = initState, action) {
  switch (action.type) {
    case 'GET_CREATE_USERS_LIMIT_FULFILLED':
    case 'GET_ADMIN_USERS_LIMIT_FULFILLED':
      return {
        ...state,
        CurentUserCount: action.payload.CurentUserCount,
        AllowedUserCount: action.payload.AllowedUserCount,
      };
    case 'GET_CREATE_USERS_LIMIT_REJECTED':
    case 'GET_ADMIN_USERS_LIMIT_REJECTED':
      return {
        ...state,
        CurentUserCount: 0,
        AllowedUserCount: 0,
      };

    default:
      return state;
  }
};

export default UserLimit;
